import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import Header from "./generic/Header";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import EastIcon from "@mui/icons-material/East";
import MenuItem from "@mui/material/MenuItem";
import {
  Box,
  Card,
  CardContent,
  Container,
  Skeleton,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import Footer from "./generic/Footer";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { apiList, invokeApi } from "../apis/apiServices";
import { config } from "../config/config";
import { useCookies } from "react-cookie";
import { topCities, topAdmission, PriceFormatter } from "../common/common";
import BannerSection from "./home-components/BannerSection";
import Service from "./home-components/Service";
import PopularLocality from "./home-components/PopularLocality";
import { Bounce, toast } from "react-toastify";
import LeadModal from "./end-user/LeadModal";

const emptyPropertyImage = "/media/images/gridimgae.jpeg";

function Home() {
  const [apiFetching, setApiFetching] = useState(true);
  const [cookies, setCookies] = useCookies();
  const navigate = useNavigate();
  const [propertiesList, setPropertiesList] = useState([]);
  const sliderRef1 = useRef(null);
  const sliderRef2 = useRef(null);
  const [slidesToShow1, setSlidesToShow1] = useState(4);
  const [slidesToShow2, setSlidesToShow2] = useState(8);
  const [leadModal, setLeadModal] = useState(false);
  const [propertyId, setPropertyId] = useState(null);
  const [leadSource, setLeadSource] = useState("");
  const handeChangeModal = () => setLeadModal((prevState) => !prevState);

  const settings1 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow1,
    slidesToScroll: 1,
    prevArrow: null,
    nextArrow: null,
  };

  const settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow2,
    slidesToScroll: 1,
    prevArrow: null,
    nextArrow: null,
  };

  // below the useEffect for the api calling 2 api propertyType and propertiesList
  useEffect(() => {
    const propertiesList = async () => {
      let params = {};
      const response = await invokeApi(
        config.apiDomains + apiList.AllPropertyList,
        params,
        cookies
      );
      if (response?.status >= 200 && response?.status < 300) {
        if (response?.data.responseCode === "200") {
          setPropertiesList(response.data.propertyDtl);
        } else {
          alert("An error occurred while fetching data!");
        }
      } else {
        alert("An error occurred while fetching data!!");
      }
    };
    if (apiFetching) {
      propertiesList();
      setApiFetching(false);
    }
  }, [cookies, apiFetching]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 900) {
        setSlidesToShow1(4);
        setSlidesToShow2(8);
      } else if (window.innerWidth >= 600) {
        setSlidesToShow1(2);
        setSlidesToShow2(6);
      } else if (window.innerWidth >= 400) {
        setSlidesToShow1(1);
        setSlidesToShow2(3);
      } else if (window.innerWidth >= 200) {
        setSlidesToShow1(1);
        setSlidesToShow2(3);
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Header />
      <BannerSection />
      {/* below the section is for Hot Properties in India start */}
      <Box
        sx={{
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            color: "var(--Text-Color-Grey-Bold, #333)",
            textAlign: "center",
            fontFamily: "Mulish, sans-serif",
            fontSize: { md: "26px", xs: "19px" },
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
            mb: { md: "64px", xs: "10px" },
            mt: "80px",
          }}
        >
          Latest Properties in {cookies[config.preferencesCookie]?.country}
        </Box>
      </Box>

      {/* original data showing  */}
      <Box sx={{ width: "90%", m: "auto", mt: 3 }}>
        <Container maxWidth={"xl"} sx={{ mb: "22px", position: "relative" }}>
          {/* Custom "Previous" button */}
          <Box
            className="custom-arrow custom-prev-arrow"
            onClick={() => {
              if (sliderRef1.current) {
                sliderRef1.current.slickPrev(); // Go to the previous slide
              }
            }}
            sx={{
              position: "absolute",
              top: "50%",
              ml: -3,
              display: "flex",
              p: 1,
              alignContent: "center",
              transform: "translateY(-50%)",
              backgroundColor: "white",
              borderRadius: "50%", // Set the borderRadius to create a circle
              maxWidth: { md: "150px", xs: "60px" }, // Adjust the width and height to make it circular
              color: "#8E8F8F",
              boxShadow:
                "0px 2.6658482551574707px 5.331696510314941px rgba(0, 0, 0, 0.15)",
              zIndex: 2,
              cursor: "pointer",
            }}
          >
            <ArrowBackIosNewIcon
              sx={{
                width: { md: "17px", xs: "10px" },
                height: { md: "16px", xs: "10px" },
              }}
            />
          </Box>
          {propertiesList.length <= 0 ? (
            <Slider ref={sliderRef1} {...settings1}>
              {[...Array(4)].map((_, index) => (
                <div key={index}>
                  <Card
                    key={index}
                    sx={{
                      margin: "0 5px",
                      minHeight: "290px",
                      zIndex: 1,
                    }}
                  >
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={150}
                    />
                    <CardContent
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        sx={{
                          textAlign: "left",
                          pl: { md: "12px", xs: "5px" },
                          mt: "10px",
                          mb: "4px",
                        }}
                      >
                        <Box sx={{ pt: 0.5 }}>
                          <Skeleton />
                          <Skeleton width="60%" />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-around",
                            gap: "10px",
                          }}
                        >
                          <Skeleton width={"100%"} height={60} />
                          <Skeleton width={"100%"} height={60} />
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </div>
              ))}
            </Slider>
          ) : (
            <Slider ref={sliderRef1} {...settings1}>
              {propertiesList?.slice(0, 8).map((property, index) => (
                <div key={index}>
                  <Card
                    sx={{
                      margin: "0 5px", // Apply margin for spacing
                      minHeight: "290px",
                      position: "relative",
                      zIndex: 1,
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "#e74c3c",
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        borderRadius: "5px",
                        color: "#fff",
                        fontWeight: "bold",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingRight: "5px",
                        paddingLeft: "5px",
                        fontSize: { xs: "12px" },
                      }}
                    >
                      {property.listingType}
                    </Box>
                    <CardContent
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        padding: "0px",
                      }}
                    >
                      <Box
                        component="img"
                        src={
                          property.imageData?.find(
                            (item) => item.imageType === "featured"
                          )?.imagePath || emptyPropertyImage
                        }
                        sx={{
                          maxHeight: { md: "160px", xs: "150px" },
                          objectFit: "cover",
                        }}
                      />
                      <Box
                        sx={{
                          textAlign: "left",
                          pl: { md: "12px", xs: "5px" },
                          mt: "10px",
                          mb: "4px",
                        }}
                      >
                        <Typography
                          variant="h6"
                          component={"h1"}
                          sx={{
                            color: "var(--Text-Color-Grey-Bold, #333)",
                            fontSize: { md: "18px", xs: "17px" },
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "normal",
                            textDecoration: "none",
                            cursor: "pointer",
                            fontFamily: "Mulish, sans-serif",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                          onClick={() =>
                            navigate(`/property-details/${property.propertyId}`)
                          }
                        >
                          {property.propertyName}
                        </Typography>

                        <Box
                          sx={{
                            color: "var(--Text-Color-Grey-Light, #555656)",
                            fontFamily: "Mulish, sans-serif",
                            fontSize: { md: "15px", xs: "12px" },
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            mt: 0.8,
                          }}
                        >
                          {property.city}, {property.state}
                        </Box>
                        <Box
                          sx={{
                            color: "var(--Text-Color-Grey-Bold, #333)",
                            fontFamily: "Mulish, sans-serif",
                            fontSize: { md: "16px", xs: "18px" },
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "normal",
                            mt: 0.8,
                          }}
                        >
                          {PriceFormatter(property.rent)}
                          {property.positionStatus
                            ? " | " + property.positionStatus
                            : null}
                        </Box>
                        {/* below the box containe the detail and enquiry now buttons  */}
                        <Box
                          sx={{
                            mt: 3,
                            display: "flex",
                            flexDirection: "row",
                            gap: { md: "16px", xs: "10px" },
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              fontFamily: "Mulish, sans-serif",
                              width: { md: "100%", xs: "100%" },
                              padding: { md: "8px 0px", xs: "8px 8px" },
                              gap: "4px",
                              fontSize: { xs: "15px", md: "16px" },
                              background: "var(--BG-Color-3, #EAEAEA)",
                              borderRadius: "5px",
                              cursor: "pointer",
                              textAlign: "center",
                            }}
                            onClick={() => {
                              handeChangeModal();
                              setPropertyId(property.propertyId);
                              setLeadSource(property.propertyType);
                            }}
                          >
                            Enquiry Now
                          </Box>
                          <Box
                            sx={{
                              fontFamily: "Mulish, sans-serif",
                              width: "100%",
                              padding: "10px 0px",
                              gap: "4px",
                              color: "white",
                              background: "var(--BG-Color-5, #DB282F)",
                              "&:hover": {
                                background: "var(--BG-Color-5, #F8F8F8)",
                                color: "#DB282F",
                              },
                              borderRadius: "5px",
                              cursor: "pointer",
                              fontSize: { xs: "15px", md: "16px" },
                              transition: "0.5s",
                              textAlign: "center",
                            }}
                            onClick={() =>
                              navigate(
                                `/property-details/${property.propertyId}`
                              )
                            }
                          >
                            Details
                          </Box>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </div>
              ))}
            </Slider>
          )}

          {/* Custom "Next" button */}
          <Box
            className="custom-arrow custom-next-arrow"
            onClick={() => {
              if (sliderRef1.current) {
                sliderRef1.current.slickNext(); // Go to the next slide
              }
            }}
            sx={{
              position: "absolute",
              top: "50%",
              display: "flex",
              alignContent: "center",
              p: 1,
              right: { md: -3, xs: "-7px" },
              transform: "translateY(-50%)",
              backgroundColor: "white",
              borderRadius: "50%", // Set the borderRadius to create a circle
              maxWidth: { md: "150px", xs: "60px" }, // Adjust the width and height to make it circular
              color: "#8E8F8F",
              cursor: "pointer",
              boxShadow:
                "0px 2.6658482551574707px 5.331696510314941px rgba(0, 0, 0, 0.15)",
              zIndex: 2,
            }}
          >
            <ArrowForwardIosIcon
              sx={{
                width: { md: "17px", xs: "9px" },
                height: { md: "17px", xs: "9px" },
              }}
            />
          </Box>
        </Container>
      </Box>
      {/* below the section is Find Your Dream Properties In Your Preferred City start */}
      <Box
        component="section"
        sx={{
          color: "var(--BG-Color-5, #DB282F)",
          fontFamily: "Mulish, sans-serif",
          fontSize: { xs: "14px", sm: "16px", md: "18px", lg: "20px" },
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "normal",
          display: "flex",
          justifyContent: "center",
          alignItems: "center", // Ensure the text and icon are vertically aligned
          margin: "auto",
          mt: 2,
          cursor: "pointer",
          padding: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, // Optional: add padding for better touch target on smaller devices
          textAlign: "center", // Center the text
        }}
        onClick={() => navigate("/property-list")}
      >
        View All Properties
        <EastIcon sx={{ ml: 1 }} />
      </Box>
      {/* above the section is Find Your Dream Properties In Your Preferred City end */}
      <Box
        sx={{
          backgroundColor: "white",
          py: 3,
          mt: { md: 10, xs: 5 },
        }}
      >
        <Box
          sx={{
            textAlign: "center",
            fontFamily: "Mulish, sans-serif",
            fontSize: { md: "26px", xs: "16px" },
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "normal",
            mb: { md: "26px", xs: "19px" },
          }}
        >
          Find Your Dream Properties In{" "}
          {cookies[config.preferencesCookie]?.city
            ? cookies[config.preferencesCookie]?.city
            : "Your Preferred City"}
        </Box>
        <Box
          sx={{
            backgroundColor: "#F5F5F5",
            py: { md: 4, xs: 2 },
            width: "100%",
          }}
        >
          <Container maxWidth={"md"} sx={{ position: "relative" }}>
            {/* Custom "Previous" button */}
            <Box
              className="custom-arrow custom-prev-arrow"
              onClick={() => {
                if (sliderRef2.current) {
                  sliderRef2.current.slickPrev(); // Go to the previous slide
                }
              }}
              sx={{
                position: "absolute",
                top: "50%",
                ml: { md: -4, xs: -3 },
                display: "flex",
                alignContent: "center",
                transform: "translateY(-50%)",
                p: 1,
                backgroundColor: "white",
                borderRadius: "50%", // Set the borderRadius to create a circle
                maxWidth: { md: "150px", xs: "60px" }, // Adjust the width and height to make it circular
                color: "#8E8F8F",
                boxShadow:
                  "0px 2.6658482551574707px 5.331696510314941px rgba(0, 0, 0, 0.15)",
              }}
            >
              <ArrowBackIosNewIcon
                sx={{
                  width: { md: "17px", xs: "10px" },
                  height: { md: "16px", xs: "10px" },
                }}
              />
            </Box>

            <Slider ref={sliderRef2} {...settings2}>
              {/* {topAdmission.map((property) => ( */}
              {topCities?.india.map((item) => (
                //  <div key={property.id}>
                <div key={item.name}>
                  <Card
                    sx={{
                      margin: "0 5px",
                      boxShadow: "0px 2.4px 4.8px 0px rgba(0, 0, 0, 0.15)",
                      borderRadius: "10px",
                      border: "1px solid var(--Text-Color-Grey-Light, #555656)",
                      background: "#FFF",
                    }}
                  >
                    <CardContent
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        "&:hover": {
                          backgroundColor: "#eeeeee",
                        },
                      }}
                      onClick={() => {
                        setCookies(
                          config.preferencesCookie,
                          JSON.stringify({
                            ...cookies[config.preferencesCookie],
                            city: item.name,
                          }),
                          {
                            path: "/",
                            maxAge: 3000000,
                            sameSite: "strict",
                          }
                        );
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          m: "auto",
                          width: { md: "50px", xs: "30px" },
                          height: { md: "50px", xs: "30px" },
                        }}
                      >
                        <img src={item.svg} alt={item.name} />
                      </Box>

                      <Box
                        sx={{
                          color: "#555656",
                          fontSize: { md: "16px", xs: "8px" },
                          fontWeight: { md: 400, xs: 500 },
                          lineHeight: "normal",
                          fontFamily: "Mulish, sans-serif",
                          mt: { md: "5px", xs: "0px" },
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          variant="caption"
                          sx={{ fontSize: { sm: "0.75rem", xs: "12px" } }}
                        >
                          {item.name}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </div>
              ))}
            </Slider>

            {/* Custom "Next" button */}
            <Box
              className="custom-arrow custom-next-arrow"
              onClick={() => {
                if (sliderRef2.current) {
                  sliderRef2.current.slickNext(); // Go to the next slide
                }
              }}
              sx={{
                position: "absolute",
                top: "50%",
                display: "flex",
                alignContent: "center",
                right: { md: -5, xs: "-7px" },
                transform: "translateY(-50%)",
                p: 1,
                backgroundColor: "white",
                borderRadius: "50%", // Set the borderRadius to create a circle
                maxWidth: { md: "150px", xs: "60px" }, // Adjust the width and height to make it circular
                color: "#8E8F8F",
                boxShadow:
                  "0px 2.6658482551574707px 5.331696510314941px rgba(0, 0, 0, 0.15)",
              }}
            >
              <ArrowForwardIosIcon
                sx={{
                  width: { md: "17px", xs: "9px" },
                  height: { md: "17px", xs: "9px" },
                }}
              />
            </Box>
          </Container>
        </Box>
      </Box>
      {/* <PopularLocality /> */}
      <Service />
      <Footer />
      <LeadModal
        initialValue={leadModal}
        handleModal={handeChangeModal}
        leadSource={leadSource}
        propertyId={propertyId}
      />
    </>
  );
}

export default Home;

import {
  Autocomplete,
  GoogleMap,
  LoadScript,
  Marker,
} from "@react-google-maps/api";
import {
  Box,
  FormControl,
  TextField,
  Button,
  Grid,
  Autocomplete as MuiAutocomplete,
  Stack,
  CircularProgress,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { apiList, invokeApi } from "../../../../../apis/apiServices";
import { config } from "../../../../../config/config";
import { useDispatch, useSelector } from "react-redux";
import { getLocation } from "../../../../../global/redux/action";

const libraries = ["places"];

const LocationDetails = ({
  formData,
  updateFormData,
  next,
  back,
  detailsId,
}) => {
  const [cookies] = useCookies();
  const dispatch = useDispatch();
  const { locationData, locationError, isLocationFetching } = useSelector(
    (state) => state.location
  );

  useEffect(() => {
    if (!locationData?.countries && !isLocationFetching && !locationError) {
      dispatch(
        getLocation({ id: cookies[config.cookieName]?.loginUserId, cookies })
      );
    }
  }, [dispatch, cookies, locationError, locationData, isLocationFetching]);

  const zoneMappingData = locationData?.countries;
  // error and helper text state
  // Start
  const [projectorBuildingNameError, setProjectorBuildingNameError] =
    useState(false);
  const [projectorBuildingNameHelperText, setProjectorBuildingNameHelperText] =
    useState("");
  // End
  const [selectedCountry, setSelectedCountry] = useState(
    formData.selectedCountry
  );
  const [selectedState, setSelectedState] = useState(formData.selectedState);
  const [selectedDistrict, setSelectedDistrict] = useState(
    formData.selectedDistrict
  );
  const [subLocality, setSubLocality] = useState(formData.subLocality);
  const [selectedTaluk, setSelectedTaluk] = useState(formData.selectedTaluk);
  const [locality, setLocality] = useState(formData.locality);
  const [pinCode, setPinCode] = useState(formData.pinCode);
  const [propertyAdress, setPropertyAdress] = useState(formData.propertyAdress);
  const [landMark, setLandMark] = useState(formData.landMark);
  const [buildingName, setBuildingName] = useState(formData.buildingName);
  const [userPackageId, setuserPackageId] = useState(null);

  const [markerPosition, setMarkerPosition] = useState({
    lat: 12.9038631,
    lng: 77.5855457,
  });
  const [autocomplete, setAutocomplete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [runLocation, setrunLocation] = useState(formData.runLocation);
  const [verifyPackageByLocation, setverifyPackageByLocation] = useState(
    formData.verifyPackageByLocation
  );

  const [longtitude, setLongtitude] = useState("");
  const [latitude, setLatitude] = useState("");

  const mapRef = useRef(null);

  const send = () => updateFormData(data);

  const data = {
    selectedCountry,
    selectedState,
    selectedDistrict,
    selectedTaluk,
    locality,
    pinCode,
    propertyAdress,
    landMark,
    buildingName,
    subLocality,
    userPackageId,
    runLocation,
    verifyPackageByLocation,
  };

  useEffect(() => {
    const getCurrentLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLongtitude(position.coords.longitude);
            setLatitude(position.coords.latitude);
          },
          // failure call back
          (error) => {
            setLongtitude(config.defaultMapLocation.longitude);
            setLatitude(config.defaultMapLocation.latitude);
            console.log(error);
          }
        );
      } else {
        setLongtitude(config.defaultMapLocation.longitude);
        setLatitude(config.defaultMapLocation.latitude);
      }
    };

    getCurrentLocation();
  }, [cookies]);

  const verifyPackageByCity = async () => {
    setLoading(true);
    let params = {
      district: selectedDistrict,
      listingType: formData.propertyListingType,
    };
    try {
      const response = await invokeApi(
        config.apiDomains + apiList.verifyUserPackageByCity,
        params,
        cookies
      );

      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseMessage === "Successful") {
          setverifyPackageByLocation(true);
          setrunLocation(false);
          setLoading(false);
          setuserPackageId(response.data.userPackageId);
          if (!buildingName) {
            setProjectorBuildingNameError(true);
            setProjectorBuildingNameHelperText(
              "Please Enter Project or Building Name"
            );
          } else if (detailsId) {
            updateFormData(data);
            next();
          } else {
            send();
            next();
          }
        } else {
          window.alert("No packages are found for this user ID");
          setLoading(false);
        }
      } else {
        window.alert(response.data.responseMessage);
        setLoading(false);
      }
    } catch (error) {
      console.error("An error occurred while verifying packages:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!detailsId) {
      setverifyPackageByLocation(false);
      send();
    }
  }, [selectedDistrict]);

  const onMarkerDragEnd = (coords) => {
    setrunLocation(true);
    setMarkerPosition({
      lat: coords.latLng.lat(),
      lng: coords.latLng.lng(),
    });
  };

  const showPosition = (position) => {
    if (position.coords.latitude && position.coords.longitude && runLocation) {
      setMarkerPosition({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    }
  };

  const mapCenterMoved = () => {
    if (runLocation) {
      setMarkerPosition({
        lat: mapRef.current.getCenter().lat(),
        lng: mapRef.current.getCenter().lng(),
      });
    }
  };

  const onAutoCompleteLoad = (ac) => {
    setAutocomplete(ac);
  };

  const onPlaceChanged = () => {
    if (
      runLocation &&
      autocomplete !== null &&
      !!autocomplete.getPlace().geometry?.location
    ) {
      setMarkerPosition({
        lat: autocomplete.getPlace().geometry.location.lat(),
        lng: autocomplete.getPlace().geometry.location.lng(),
      });
    }
  };

  useEffect(() => {
    let url =
      "https://maps.googleapis.com/maps/api/geocode/json?address=" +
      markerPosition.lat +
      "," +
      markerPosition.lng +
      "&key=" +
      config.googleMapsApiKey;

    function updateState(type, state, setter, longName) {
      if (type) {
        setter(longName);
      }
    }

    if (markerPosition.lat && markerPosition.lng && runLocation) {
      fetch(url, {
        method: "get",
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.results.length > 0) {
            const data = res.results[0].address_components;
            for (let i = 0; i < data.length; i++) {
              const object = data[i];
              const types = object.types;

              (!selectedCountry || markerPosition) &&
                updateState(
                  types.includes("country"),
                  selectedCountry,
                  setSelectedCountry,
                  object.long_name
                );
              (!selectedState || markerPosition) &&
                updateState(
                  types.includes("administrative_area_level_1"),
                  selectedState,
                  setSelectedState,
                  object.long_name
                );
              (!selectedDistrict || markerPosition) &&
                updateState(
                  types.includes("administrative_area_level_3"),
                  selectedDistrict,
                  setSelectedDistrict,
                  object.long_name
                );
              !pinCode &&
                updateState(
                  types.includes("postal_code"),
                  pinCode,
                  setPinCode,
                  object.long_name
                );
              !locality &&
                updateState(
                  types.includes("sublocality") ||
                    types.includes("locality") ||
                    types.includes("sublocality_level_1"),
                  locality,
                  setLocality,
                  object.long_name
                );
              !propertyAdress &&
                updateState(
                  types.includes("route"),
                  propertyAdress,
                  setPropertyAdress,
                  object.long_name
                );
              !landMark &&
                updateState(
                  types.includes("landmark"),
                  landMark,
                  setLandMark,
                  object.long_name
                );
            }
          }
        });
    }
  }, [markerPosition]);

  return (
    <>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "100%" },
        }}
        noValidate
        autoComplete="off"
      >
        <Box sx={{ width: "100%", height: "400px", marginBottom: 2 }}>
          <LoadScript
            googleMapsApiKey={config.googleMapsApiKey}
            libraries={libraries}
          >
            <GoogleMap
              ref={mapRef}
              onLoad={(map) => {
                mapRef.current = map;
                if (navigator.geolocation) {
                  navigator.geolocation.getCurrentPosition(showPosition);
                }
              }}
              mapContainerStyle={{ height: "100%" }}
              center={markerPosition}
              zoom={17}
              onDragEnd={mapCenterMoved}
            >
              <Marker
                position={markerPosition}
                draggable
                onDragEnd={onMarkerDragEnd}
              />
              <Autocomplete
                onLoad={onAutoCompleteLoad}
                onPlaceChanged={onPlaceChanged}
              >
                <input
                  type="text"
                  id="gmap-autocomplete"
                  placeholder="Search for your location"
                  style={{
                    boxSizing: "border-box",
                    border: "1px solid transparent",
                    width: "240px",
                    height: "32px",
                    padding: "0 12px",
                    borderRadius: "3px",
                    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
                    fontSize: "14px",
                    outline: "none",
                    textOverflow: "ellipses",
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                  onKeyDown={(ev) => {
                    ev.key === "Enter" && ev.preventDefault();
                  }}
                />
              </Autocomplete>
            </GoogleMap>
          </LoadScript>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <MuiAutocomplete
              id="outlined-select-country"
              options={zoneMappingData?.map((item) => item.countryName)}
              value={selectedCountry}
              freeSolo
              disabled={detailsId}
              onChange={(event, newValue) => {
                setSelectedCountry(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Please select your country"
                  variant="outlined"
                  fullWidth
                  onChange={(event) => {
                    setSelectedCountry(event.target.value);
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <MuiAutocomplete
              id="outlined-select-state"
              options={
                zoneMappingData
                  ?.find((item) => item.countryName === selectedCountry)
                  ?.states?.map((state) => state.stateName) || []
              }
              value={selectedState}
              freeSolo
              disabled={!selectedCountry || detailsId}
              onChange={(event, newValue) => {
                setSelectedState(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Please select your state"
                  variant="outlined"
                  fullWidth
                  onChange={(event) => {
                    setSelectedState(event.target.value);
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <MuiAutocomplete
              id="outlined-select-district"
              options={
                zoneMappingData
                  .find((item) => item.countryName === selectedCountry)
                  ?.states?.find((state) => state.stateName === selectedState)
                  ?.districts?.map((district) => district.districtName) || []
              }
              value={selectedDistrict}
              freeSolo
              disabled={!selectedState || detailsId}
              onChange={(event, newValue) => {
                setSelectedDistrict(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Please select your city/district"
                  variant="outlined"
                  fullWidth
                  onChange={(event) => {
                    setSelectedDistrict(event.target.value);
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              label="Locality"
              fullWidth
              value={locality}
              onChange={(event) => {
                setLocality(event.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              label="Pincode"
              fullWidth
              value={pinCode}
              onChange={(event) => {
                setPinCode(event.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              label="Sub-locality"
              fullWidth
              value={propertyAdress}
              onChange={(event) => {
                setPropertyAdress(event.target.value);
                setSubLocality(event.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              label="Land Mark"
              fullWidth
              value={landMark}
              onChange={(event) => {
                setLandMark(event.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              label="Project or Building Name"
              error={projectorBuildingNameError}
              helperText={projectorBuildingNameHelperText}
              fullWidth
              value={buildingName}
              onChange={(event) => {
                setBuildingName(event.target.value);
                setProjectorBuildingNameError(false);
                setProjectorBuildingNameHelperText("");
              }}
            />
          </Grid>
        </Grid>

        {loading && (
          <Stack sx={{ alignItems: "center", justifyContent: "center", mt: 4 }}>
            <CircularProgress sx={{ color: "black" }} />
          </Stack>
        )}
      </Box>
      {!detailsId && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            pt: 2,
          }}
        >
          <Button
            color="inherit"
            onClick={() => {
              back();
              send();
            }}
          >
            Back
          </Button>
          <Button
            color="inherit"
            onClick={() => {
              verifyPackageByCity();
            }}
          >
            Next
          </Button>
        </Box>
      )}
      {detailsId && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            pt: 2,
          }}
        >
          <Button
            color="inherit"
            onClick={() => {
              back();
            }}
          >
            Back
          </Button>
          <Box>
            <Button
              color="inherit"
              onClick={() => {
                // send();
                verifyPackageByCity();
              }}
            >
              update
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default LocationDetails;

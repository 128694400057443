import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Typography,
  Grid,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Skeleton,
} from "@mui/material";
import { useCookies } from "react-cookie";
import { apiList, invokeApi } from "../../../apis/apiServices";
import { config } from "../../../config/config";
import OutboundIcon from "@mui/icons-material/Outbound";
import { Facebook, LinkedIn, Twitter, WhatsApp } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import PropertyManagerTabs from "../post-properties/PropertyManagerTabs";
import { monthNames } from "../../../common/common";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import { Bounce, toast } from "react-toastify";
import { getLocation, getUser } from "../../../global/redux/action";
const PropertyEmptyImage = "/media/images/empty-property-image.jpg";

const StatusList = [
  {
    status: "Active",
    endPoint: apiList.activeProperties,
  },
  {
    status: "Pending",
    endPoint: apiList.pendingProperties,
  },
  {
    status: "Rejected",
    endPoint: apiList.rejectedProperties,
  },
  {
    status: "Completed",
    endPoint: apiList.closedProperties,
  },
];

function MyPropertyLists() {
  const navigate = useNavigate();
  const [cookies] = useCookies();
  const dispatch = useDispatch();
  const { userData, userError, isFetching } = useSelector(
    (state) => state.user
  );
  const { locationData, locationError, isLocationFetching } = useSelector(
    (state) => state.location
  );

  useEffect(() => {
    if (userError) {
      alert(
        "Something went wrong while fetching user details. Please try again later!"
      );
    }
    if (locationError) {
      alert(
        "Something went wrong while fetching Location details. Please try again later!"
      );
    }
  }, [userError, locationError]);

  useEffect(() => {
    // Check if the necessary conditions are met before dispatching
    if (
      cookies[config.cookieName]?.loginUserId &&
      !userData &&
      !isFetching &&
      !userError
    ) {
      dispatch(
        getUser({ id: cookies[config.cookieName]?.loginUserId, cookies })
      );
    }
    if (!locationData?.countries && !isLocationFetching && !locationError) {
      dispatch(
        getLocation({ id: cookies[config.cookieName]?.loginUserId, cookies })
      );
    }
  }, [
    dispatch,
    cookies,
    userData,
    isFetching,
    userError,
    locationError,
    locationData,
    isLocationFetching,
  ]);

  const [myListingDatas, setmyListingDatas] = useState([]);
  const [fetching, setFetching] = useState(true);

  const [status, setStatus] = useState("Active");
  const [endPoint, setEndPoint] = useState(apiList.activeProperties);

  function handleChangeStatus(e) {
    let valueSelected = e.target.value;
    setEndPoint(
      StatusList.find((item) => item.status === valueSelected).endPoint
    );
    setStatus(valueSelected);
    setFetching(true);
  }

  //My Listings
  useEffect(() => {
    const myListings = async () => {
      let params = {};
      const response = await invokeApi(
        config.apiDomains + endPoint + cookies[config.cookieName]?.loginUserId,
        params,
        cookies
      );
      if (response?.status >= 200 && response?.status < 300) {
        if (response.data.responseCode === "200") {
          setmyListingDatas(response.data.propertyDtl);
          setFetching(false);
        } else {
          alert("An error occurred while fetching data!");
        }
      } else {
        alert("An error occurred while fetching data!!");
      }
    };
    if (fetching) {
      myListings();
    }
  }, [cookies, fetching]);

  async function handleDeleteProperty(propertyId) {
    let params = {};
    const response = await invokeApi(
      config.apiDomains + apiList.deleteProperties + propertyId,
      params,
      cookies
    );
    if (response.status >= 200 && response.status < 300) {
      if (response.data.responseCode === "200") {
        toast.success("Property Deleted Successfully", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setFetching(true);
      } else {
        alert("An error occurred while fetching data!");
      }
    } else if (response.data.responseCode === "401") {
      navigate("/logout");
    } else {
      alert("An error occurred while fetching data!!");
    }
  }

  async function handleCompletedProperty(propertyId) {
    let params = {};
    const response = await invokeApi(
      config.apiDomains + apiList.completedProperty + propertyId,
      params,
      cookies
    );
    if (response.status >= 200 && response.status < 300) {
      if (response.data.responseCode === "200") {
        toast.success("Property Completed Successfully", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setFetching(true);
      } else {
        alert("An error occurred while fetching data!");
      }
    } else if (response.data.responseCode === "401") {
      navigate("/logout");
    } else {
      alert("An error occurred while fetching data!!");
    }
  }

  async function handleApproveProperty(id) {
    // Define the parameters for the API call
    let params = {};

    // Make the API call using invokeApi function, passing the necessary parameters and cookies
    const response = await invokeApi(
      config.apiDomains + apiList.approveProperty + id,
      params,
      cookies
    );
    if (response.status >= 200 && response.status < 300) {
      if (response.data.responseCode === "200") {
        toast.success("Approved Successfully", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setFetching(true);
      } else {
        alert("An error occurred while fetching data!");
      }
    } else {
      alert("An error occurred while fetching data!!");
    }
  }

  async function handleRejectProperty(id) {
    // Define the parameters for the API call
    let params = {};

    // Make the API call using invokeApi function, passing the necessary parameters and cookies
    const response = await invokeApi(
      config.apiDomains + apiList.rejectProperty + id,
      params,
      cookies
    );
    if (response.status >= 200 && response.status < 300) {
      if (response.data.responseCode === "200") {
        toast.success("Rejected Successfully", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setFetching(true);
      } else {
        alert("An error occurred while fetching data!");
      }
    } else {
      alert("An error occurred while fetching data!!");
    }
  }

  return (
    <PropertyManagerTabs tabActive={1}>
      <Grid container spacing={2} sx={{ mt: 4 }}>
        <Grid item xs={12} md={8}>
          {!fetching ? (
            <>
              {myListingDatas.map((listings, index) => (
                <Grid
                  container
                  key={index}
                  sx={{
                    flexWrap: "wrap",
                    boxShadow: "0 0 3px black",
                    mb: 2,
                  }}
                >
                  <>
                    <Grid item xs={5}>
                      <img
                        style={{
                          width: "100%",
                          height: 220,
                          objectFit: "contain",
                        }}
                        src={
                          listings.imageData <= 0
                            ? PropertyEmptyImage
                            : listings.imageData.find(
                                (item) => item.imageType === "featured"
                              ).imagePath || PropertyEmptyImage
                        }
                        alt={listings.propertyName}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="h5">
                        <b>{listings.propertyName} </b>
                      </Typography>
                      <Typography variant="h6">
                        {listings.propertyType} For {listings.listingType} in{" "}
                        {listings.propertyAddress}
                      </Typography>
                      <Typography sx={{ color: "#666" }}>
                        {listings.propertyAddress}, {listings.city}
                      </Typography>
                      <Typography>
                        <b style={{ color: "#5f449b", fontSize: 30 }}>
                          {new Intl.NumberFormat("en-IN", {
                            style: "currency",
                            currency: "INR",
                            maximumSignificantDigits: 3,
                          }).format(listings.rent)}
                        </b>
                      </Typography>
                      <Grid
                        container
                        spacing={6}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        <Grid item>
                          <Typography>
                            <b style={{ fontSize: 20 }}>
                              {listings.builtupPlotArea} {listings.areaUnit}
                            </b>
                          </Typography>
                          <Typography>Built-up Area </Typography>
                        </Grid>
                        <Grid item>
                          <Typography>
                            <b style={{ fontSize: 15 }}>
                              {listings.furnishingType}
                            </b>
                          </Typography>
                          <Typography>Furnishing Status </Typography>
                        </Grid>
                      </Grid>
                      <Typography>
                        <span style={{ color: "#666" }}>Posted on: </span>{" "}
                        {new Date(listings.postDate).getDate() +
                          " " +
                          monthNames[new Date(listings.postDate).getMonth()] +
                          " " +
                          new Date(listings.postDate).getFullYear()}
                      </Typography>
                    </Grid>
                    {status === "Active" ? (
                      <Grid
                        item
                        xs={1}
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-haspopup="true"
                          onClick={() =>
                            navigate(`/property-edit/${listings.propertyId}`)
                          }
                          sx={{ marginBottom: 2, mt: 1 }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-haspopup="true"
                          onClick={() =>
                            navigate(`/view-property/${listings.propertyId}`)
                          }
                          sx={{ marginBottom: 2 }}
                        >
                          <RemoveRedEyeIcon />
                        </IconButton>
                        {userData?.user.roles.includes("Admin") && (
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-haspopup="true"
                            onClick={() =>
                              handleDeleteProperty(listings.propertyId)
                            }
                            sx={{ marginBottom: 2 }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                        {userData?.user.roles.includes("Admin") && (
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-haspopup="true"
                            onClick={() =>
                              handleCompletedProperty(listings.propertyId)
                            }
                            style={{ color: "green" }}
                          >
                            <CheckIcon />
                          </IconButton>
                        )}
                      </Grid>
                    ) : status === "Pending" ? (
                      <Grid
                        item
                        sx={{
                          mt: 1,
                          display: "flex",
                          marginLeft: "auto",
                          flexDirection: "column",
                        }}
                      >
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-haspopup="true"
                          onClick={() =>
                            navigate(`/property-edit/${listings.propertyId}`)
                          }
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-haspopup="true"
                          onClick={() =>
                            navigate(`/view-property/${listings.propertyId}`)
                          }
                        >
                          <RemoveRedEyeIcon />
                        </IconButton>
                      </Grid>
                    ) : status === "Rejected" ? (
                      <Grid
                        item
                        sx={{ mt: 1, display: "flex", marginLeft: "auto" }}
                      >
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-haspopup="true"
                          onClick={() =>
                            navigate(`/property-edit/${listings.propertyId}`)
                          }
                        >
                          <EditIcon />
                        </IconButton>
                      </Grid>
                    ) : null}

                    <Grid
                      container
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item xs={6}>
                        <Typography>
                          <span>
                            <OutboundIcon
                              sx={{ color: "#00be5e", mb: -0.8, ml: 2, mr: 1 }}
                            />
                          </span>
                          <b style={{ color: "#00be5e", fontSize: 16, ml: 3 }}>
                            Published on{" "}
                            {new Date(listings.postDate).getDate() +
                              " " +
                              monthNames[
                                new Date(listings.postDate).getMonth()
                              ] +
                              " " +
                              new Date(listings.postDate).getFullYear()}
                          </b>
                        </Typography>
                        <Typography sx={{ color: "#666", ml: 2, mb: 1 }}>
                          Expiring on: 04 Apr 2024{" "}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          mt: 2,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        {status === "Active" ? (
                          <Typography>
                            Share on
                            <Facebook sx={{ mb: -0.8, ml: 4 }} />
                            <LinkedIn sx={{ mb: -0.8, ml: 1.5 }} />
                            <Twitter sx={{ mb: -0.8, ml: 1.5 }} />
                            <WhatsApp sx={{ mb: -0.8, ml: 1.5, mr: 2 }} />
                          </Typography>
                        ) : status === "Pending" ? (
                          userData?.user.roles.includes("Admin") && (
                            <>
                              <Button
                                variant="contained"
                                color="success"
                                onClick={handleApproveProperty.bind(
                                  this,
                                  listings?.propertyId
                                )}
                              >
                                Approved
                              </Button>
                              <Button
                                variant="contained"
                                color="error"
                                onClick={handleRejectProperty.bind(
                                  this,
                                  listings?.propertyId
                                )}
                                sx={{ m: 1 }}
                              >
                                Rejected
                              </Button>
                            </>
                          )
                        ) : status === "Rejected" ? (
                          <>
                            <Typography>
                              Share on
                              <Facebook sx={{ mb: -0.8, ml: 4 }} />
                              <LinkedIn sx={{ mb: -0.8, ml: 1.5 }} />
                              <Twitter sx={{ mb: -0.8, ml: 1.5 }} />
                              <WhatsApp sx={{ mb: -0.8, ml: 1.5, mr: 2 }} />
                            </Typography>
                            <Button
                              variant="contained"
                              color="success"
                              onClick={handleApproveProperty.bind(
                                this,
                                listings?.propertyId
                              )}
                              sx={{ m: 1 }}
                            >
                              Approved
                            </Button>
                          </>
                        ) : status === "Completed" ? (
                          <>
                            <Typography>
                              Share on
                              <Facebook sx={{ mb: -0.8, ml: 4 }} />
                              <LinkedIn sx={{ mb: -0.8, ml: 1.5 }} />
                              <Twitter sx={{ mb: -0.8, ml: 1.5 }} />
                              <WhatsApp sx={{ mb: -0.8, ml: 1.5, mr: 2 }} />
                            </Typography>
                            <Button
                              variant="contained"
                              color="success"
                              onClick={handleApproveProperty.bind(
                                this,
                                listings?.propertyId
                              )}
                              sx={{ m: 1 }}
                            >
                              Re-launch
                            </Button>
                          </>
                        ) : null}
                      </Grid>
                    </Grid>
                  </>
                </Grid>
              ))}
            </>
          ) : (
            <>
              <Box sx={{ width: "100%", p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={200}
                    />
                  </Grid>
                  <Grid item xs={7}>
                    <Skeleton width="100%" />
                    <Skeleton width="80%" />
                    <Skeleton width="100%" />
                    <Skeleton width="100%" />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "10px",
                      }}
                    >
                      <Skeleton width="100%" height={50} />
                      <Skeleton width="100%" height={50} />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ width: "100%", p: 1, mt: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={200}
                    />
                  </Grid>
                  <Grid item xs={7}>
                    <Skeleton width="100%" />
                    <Skeleton width="80%" />
                    <Skeleton width="100%" />
                    <Skeleton width="100%" />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "10px",
                      }}
                    >
                      <Skeleton width="100%" height={50} />
                      <Skeleton width="100%" height={50} />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Properties By Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={status}
              label="Properties By Status"
              onChange={handleChangeStatus}
            >
              {StatusList.map((item) => (
                <MenuItem value={item.status}>{item.status}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </PropertyManagerTabs>
  );
}

export default MyPropertyLists;

import React, { useEffect, useState } from "react";
import { apiList, invokeApi } from "../../apis/apiServices";
import { config } from "../../config/config";
import { useCookies } from "react-cookie";
import {
  Autocomplete,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  FormControl,
  InputLabel,
  InputAdornment,
  Select,
  MenuItem,
  TextField,
  InputBase,
  Typography,
  OutlinedInput,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import { Bounce, toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import ScheduleIcon from "@mui/icons-material/Schedule";
import mobileNoValidation, { countryCodeList } from "../../common/common";
import { useDispatch, useSelector } from "react-redux";
import { getLocation, getUser } from "../../global/redux/action";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const LeadModal = ({
  initialValue,
  handleModal,
  leadSource,
  propertyId,
  productUrl,
}) => {
  const [cookies] = useCookies();
  const dispatch = useDispatch();
  const { userData, userError, isFetching } = useSelector(
    (state) => state.user
  );
  useEffect(() => {
    if (userError) {
      alert(
        "Something went wrong while fetching user details. Please try again later!"
      );
    }
  }, [userError]);

  useEffect(() => {
    // Check if the necessary conditions are met before dispatching
    if (
      cookies[config.cookieName]?.loginUserId &&
      !userData &&
      !isFetching &&
      !userError
    ) {
      dispatch(
        getUser({ id: cookies[config.cookieName]?.loginUserId, cookies })
      );
    }
  }, [cookies, userData, isFetching, userError]);

  const [leadLoading, setleadLoading] = useState(false);
  const [email, setEmail] = useState(userData?.user?.email || "");
  const [customerName, setcustomerName] = useState(
    userData?.user?.fullName || ""
  );
  const [mobileNumber, setMobileNumber] = useState(
    userData?.user?.mobileNumber || ""
  );
  const [mobileError, setMobileError] = useState(false);
  const [mobileHelperText, setMobileHelperText] = useState("");
  let mobileValidate = mobileNoValidation(mobileNumber);

  const [scheduleDateTime, setScheduleDateTime] = useState(null);
  const [countryCode, setCountryCode] = useState(
    userData?.user?.countryCode || "91"
  );

  //   addlead
  const addLead = async () => {
    setleadLoading(true);
    let validate = true;
    if (!mobileValidate) {
      setMobileError(true);
      setMobileHelperText("Please Enter Valid Mobile Number");
      setleadLoading(false);
      validate = false;
    }
    if (validate) {
      let params = {
        email,
        customerName,
        mobileNumber,
        propertyId: propertyId,
        leadSource: leadSource,
        leadType: "Own",
        scheduleDateTime,
        countryCode: countryCode,
        productUrl,
        // productUrl:
        //   "http://" + window.location.host + "/property-details/" + propertyId,
      };
      try {
        const response = await invokeApi(
          config.apiDomains + apiList.addLead,
          params,
          cookies
        );

        if (response) {
          if (
            response.status === "200" ||
            response.data.responseMessage === "Successful"
          ) {
            toast.success(
              "Thank you for showing interest we will contact you soon",
              {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
              }
            );
            setleadLoading(false);
            handleModal();
            setcustomerName("");
            setMobileNumber("");
            setEmail("");
          } else {
            toast.error("Sorry Something went wrong", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            });
            setleadLoading(false);
            setcustomerName("");
            setMobileNumber("");
            setEmail("");
          }
        } else {
          alert("Something Went Wrong");
          setleadLoading(false);
        }
      } catch (error) {
        toast.error("An error occurred while fetching location!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setleadLoading(false);
      }
    }
  };
  return (
    <>
      <BootstrapDialog
        open={initialValue}
        onClose={handleModal}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Contact Details
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom>
                Full Name
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                placeholder="Enter Your Name"
                margin="dense"
                type="text"
                value={customerName || ""}
                onChange={(event) => setcustomerName(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">Mobile Number</Typography>
              <Grid container alignItems="center">
                <Grid item xs={3}>
                  <FormControl fullWidth variant="outlined">
                    {/* <InputLabel id="demo-customized-select-label">
                      Country Code
                    </InputLabel> */}
                    <Select
                      labelId="demo-customized-select-label"
                      id="demo-customized-select"
                      value={countryCode || ""}
                      onChange={(event) => setCountryCode(event.target.value)}
                      // input={<OutlinedInput label="Country Code" />}
                    >
                      {countryCodeList?.map((item, index) => (
                        <MenuItem key={index} value={item.code}>
                          {"+" + item.code}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    // label="Mobile Number"
                    placeholder="Enter your mobile number"
                    inputProps={{ maxlength: 10 }}
                    error={mobileError}
                    helperText={mobileHelperText}
                    value={mobileNumber || ""}
                    onChange={(event) => setMobileNumber(event.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PhoneIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom>
                Email
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                type="email"
                margin="dense"
                placeholder="Enter Your Email"
                value={email || ""}
                onChange={(event) => setEmail(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom>
                Schedule Date & Time
              </Typography>
              <TextField
                id="scheduleDateTime"
                type="datetime-local"
                placeholder="Schedule Date & Time"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={scheduleDateTime || ""}
                onChange={(e) => setScheduleDateTime(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ScheduleIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ textAlign: "center" }}>
          <LoadingButton
            size="large"
            onClick={addLead}
            endIcon={<SendIcon />}
            loading={leadLoading}
            loadingPosition="end"
            variant="contained"
            sx={{
              backgroundColor: "#f44336", // Red color
              color: "#FFF", // White text color
              "&:hover": {
                backgroundColor: "#d32f2f", // Darker red on hover
              },
              "&:disabled": {
                backgroundColor: "#ef9a9a", // Light red for disabled state
                color: "#616161", // Dark gray text color for disabled state
              },
            }}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default LeadModal;

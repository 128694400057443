import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Footer from "../generic/Footer";
import Header from "../generic/Header";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { Helmet } from "react-helmet-async";

export default function ContactUs() {
  const [name, setname] = useState();
  const [email, setemail] = useState();
  const [subject, setsubject] = useState();
  const [phone, setphone] = useState();
  const [message, setmessage] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>
          Contact Us | Get My Property - Buy, Sell, and Rent Properties
        </title>
        <meta
          name="description"
          content="Contact Get My Property for inquiries on buying, selling, or renting properties. Our team is dedicated to helping you find your dream property. Get in touch with us today!"
        />
      </Helmet>
      <Header />
      <Box sx={{ p: { md: 5, xs: 2 } }}>
        <Box sx={{ px: { md: 20, xs: 5 }, py: 3 }}>
          <Typography
            variant="h1"
            sx={{
              fontSize: "34px",
              fontWeight: 600,
              fontFamily: "Jost, sans-serif",
              textAlign: "center",
            }}
          >
            Contact Us
          </Typography>
          <Typography
            sx={{
              color: "#95a5a6",
              fontFamily: "Jost, sans-serif",
              lineHeight: "25px",
              fontSize: "19px",
              textAlign: "center",
            }}
          >
            Thank you for considering Property Broker for your real estate
            needs. We are here to assist you with any inquiries or requests you
            may have. Please don't hesitate to get in touch with us.
          </Typography>
        </Box>
        <Paper sx={{ display: "flex" }} elevation={3}>
          <Grid container>
            <Grid
              item
              md={7}
              xs={12}
              sx={{ borderRight: { md: "3px solid #dfe6e9", xs: "none" } }}
            >
              <Box sx={{ p: 3 }}>
                <Typography
                  sx={{
                    fontSize: "22px",
                    fontWeight: 500,
                    fontFamily: "Jost, sans-serif",
                  }}
                >
                  Send us a Message
                </Typography>
                <Grid container spacing={2} sx={{ py: 2 }}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      id="outlined-basic-1"
                      label="Name"
                      variant="outlined"
                      value={name}
                      onChange={(e) => setname(e.target.value)}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      id="outlined-basic-2"
                      label="Email address"
                      variant="outlined"
                      value={email}
                      onChange={(e) => setemail(e.target.value)}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      id="outlined-basic-3"
                      label="Subject"
                      variant="outlined"
                      value={subject}
                      onChange={(e) => setsubject(e.target.value)}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      id="outlined-basic-4"
                      label="Phone "
                      variant="outlined"
                      value={phone}
                      onChange={(e) => setphone(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextareaAutosize
                      id="outlined-basic-5"
                      minRows={3}
                      placeholder="Write message"
                      style={{
                        width: "100%",
                        fontSize: "18px",
                        color: "black",
                        padding: "15px",
                      }}
                      value={message}
                      onChange={(e) => setmessage(e.target.value)}
                    />
                  </Grid>
                  <Grid
                    item
                    md={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={!phone || !name}
                      sx={{
                        width: "149px",
                        backgroundColor: "#312e2e",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#554d4d",
                        },
                        mb: 2,
                      }}
                      // onClick={handleLogin}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item md={5} xs={12}>
              <Box sx={{ p: 3 }}>
                <Typography
                  sx={{
                    fontSize: "22px",
                    fontWeight: 500,
                    fontFamily: "Jost, sans-serif",
                  }}
                >
                  Contact Info
                </Typography>
                <Box sx={{ my: 1, py: 2 }}>
                  <Box sx={{ display: "flex", my: 2 }}>
                    <LocationOnIcon />
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "19px",
                          fontWeight: 400,
                          fontFamily: "Jost, sans-serif",
                        }}
                      >
                        Address
                      </Typography>
                      <Typography>
                        {" "}
                        #158, Unit no 13, 3rd Floor opp. to Hulkal Brigade
                        Centre, Lavelle Road, Ashok Nagar, Bengaluru - 560001
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", my: 2 }}>
                    <LocalPhoneIcon />
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "19px",
                          fontWeight: "400",
                          fontFamily: "Jost, sans-serif",
                        }}
                      >
                        Phone Number
                      </Typography>
                      <Typography>+91 7259732934</Typography>
                      <Typography>+91 080 48541495</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Footer />
    </>
  );
}

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { PriceFormatter } from "../../../../common/common";

const PricingDetails = ({ propertyData }) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            {propertyData.pricingDetails?.rent !== "" &&
              propertyData.pricingDetails?.rent !== null && (
                <TableRow>
                  <TableCell>
                    {propertyData.listingType === "Sale"
                      ? "Sale Amount"
                      : "Rent Amount"}
                  </TableCell>
                  <TableCell>
                    {PriceFormatter(propertyData.pricingDetails?.rent)}{" "}
                    {propertyData.pricingDetails?.rentType}
                  </TableCell>
                </TableRow>
              )}
          </TableHead>
          <TableBody>
            {propertyData.pricingDetails?.maintananceCost !== "" &&
              propertyData.pricingDetails?.maintananceCost !== null && (
                <TableRow>
                  <TableCell>Maintanence Amount</TableCell>
                  <TableCell>
                    {PriceFormatter(
                      propertyData.pricingDetails?.maintananceCost
                    )}{" "}
                    {propertyData.pricingDetails?.maintananceCostType}
                  </TableCell>
                </TableRow>
              )}
            {propertyData.pricingDetails?.securityDeposit !== "" &&
              propertyData.pricingDetails?.securityDeposit !== null && (
                <TableRow>
                  <TableCell>Security Deposit</TableCell>
                  <TableCell>
                    {PriceFormatter(
                      propertyData.pricingDetails?.securityDeposit
                    )}
                  </TableCell>
                </TableRow>
              )}
            {propertyData.pricingDetails?.depositAmount !== "" &&
              propertyData.pricingDetails?.depositAmount !== null && (
                <TableRow>
                  <TableCell>Deposit Amount</TableCell>
                  <TableCell>
                    {PriceFormatter(propertyData.pricingDetails?.depositAmount)}
                  </TableCell>
                </TableRow>
              )}
            {propertyData.pricingDetails?.bookingAmount !== "" &&
              propertyData.pricingDetails?.bookingAmount !== null && (
                <TableRow>
                  <TableCell>Booking Amount</TableCell>
                  <TableCell>
                    {PriceFormatter(propertyData.pricingDetails?.bookingAmount)}
                  </TableCell>
                </TableRow>
              )}
            {propertyData.pricingDetails?.lockInPeriod !== "" &&
              propertyData.pricingDetails?.lockInPeriod !== null && (
                <TableRow>
                  <TableCell>Min Lock In Period</TableCell>
                  <TableCell>
                    {propertyData.pricingDetails?.lockInPeriod}{" "}
                    {propertyData.pricingDetails?.lockInPeriodType}
                  </TableCell>
                </TableRow>
              )}
            {propertyData.pricingDetails?.rentIncrement !== "" &&
              propertyData.pricingDetails?.rentIncrement !== null && (
                <TableRow>
                  <TableCell>Rent Increment Every 11 Months</TableCell>
                  <TableCell>
                    {propertyData.pricingDetails?.rentIncrement + " %"}
                  </TableCell>
                </TableRow>
              )}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: isMobile
                  ? "repeat(2, 1fr)"
                  : "repeat(4, 1fr)",
                gridColumnGap: "10px", // Column gap of 10 pixels
                gridRowGap: "10px", // Row gap of 10 pixels
                mt: 3,
              }}
            >
              {propertyData.pricingDetails?.selectPriceInclude.map(
                (item, index) => (
                  <Box
                    key={index}
                    sx={{
                      py: "10px",
                      px: "15px",
                      border: "1px solid #000",
                      borderRadius: "35px",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                  >
                    {item}
                  </Box>
                )
              )}
            </Box>
            {/* Add more rows as needed */}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default PricingDetails;

import React, { useEffect, useMemo, useState } from "react";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  CssBaseline,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Drawer,
  MenuItem,
  Popover,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  Collapse,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import MinimizeIcon from "@mui/icons-material/Minimize";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MenuIcon from "@mui/icons-material/Menu";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { config } from "../../config/config";
import { Logout, PersonAdd } from "@mui/icons-material";
import {
  topCities,
  MenuServices,
  indiaOtherCities,
  propertyTypes,
} from "../../common/common";
import { useDispatch, useSelector } from "react-redux";
import { getLocation, getUser } from "../../global/redux/action";
import { Bounce, toast } from "react-toastify";

const Header = () => {
  const navigate = useNavigate();
  const [cookies, setCookies] = useCookies();

  const dispatch = useDispatch();
  const { userData, userError, isFetching } = useSelector(
    (state) => state.user
  );
  const { locationData, locationError, isLocationFetching } = useSelector(
    (state) => state.location
  );

  useEffect(() => {
    if (userError) {
      alert(
        "Something went wrong while fetching user details. Please try again later!"
      );
    }
    if (locationError) {
      alert(
        "Something went wrong while fetching Location details. Please try again later!"
      );
    }
  }, [userError, locationError]);

  useEffect(() => {
    // Check if the necessary conditions are met before dispatching
    if (
      cookies[config.cookieName]?.loginUserId &&
      !userData &&
      !isFetching &&
      !userError
    ) {
      dispatch(
        getUser({ id: cookies[config.cookieName]?.loginUserId, cookies })
      );
    }
    if (!locationData?.countries && !isLocationFetching && !locationError) {
      dispatch(
        getLocation({ id: cookies[config.cookieName]?.loginUserId, cookies })
      );
    }
  }, [
    dispatch,
    cookies,
    userData,
    isFetching,
    userError,
    locationError,
    locationData,
    isLocationFetching,
  ]);

  const isMobile = useMediaQuery("(max-width:600px)");
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [addressDrawer, setAddressDrawer] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [anchorElCity, setAnchorElCity] = useState(null);
  const [anchorElServices, setAnchorElServices] = useState(null);
  const [mobileServiceDrawer, setMobileServiceDrawer] = useState(false);
  const [mobileforBuyersMenu, setMobileforBuyersMenu] = useState(false);
  const [mobileforTenantsMenu, setMobileforTenantsMenu] = useState(false);
  const [mobileforOwnersMenu, setMobileforOwnersMenu] = useState(false);

  const [filteredTopCities, setFilteredTopCities] = useState([]);
  const [filteredOtherCities, setFilteredOtherCities] = useState([]);

  const handleOpenServiceDrawer = () => {
    setMobileServiceDrawer((open) => !open);
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  useEffect(() => {
    // Filter top cities
    const filteredTop = topCities.india.filter((city) =>
      city.name.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredTopCities(filteredTop);

    // Filter other cities based on the selected country
    const selectedCountry = cookies[config.preferencesCookie]?.country;
    let otherCitiesFilter = [];
    if (selectedCountry === "India") {
      otherCitiesFilter = indiaOtherCities;
    } else if (selectedCountry === "UAE") {
      otherCitiesFilter = topCities.uae;
    } else if (selectedCountry === "Canada") {
      otherCitiesFilter = topCities.canada;
    } else if (selectedCountry === "Australia") {
      otherCitiesFilter = topCities.australia;
    }

    const filteredOther = otherCitiesFilter.filter((cityName) =>
      cityName.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredOtherCities(filteredOther);
  }, [searchText, topCities, indiaOtherCities, cookies]);

  const handleOpenMenu = (event, menu) => {
    switch (menu) {
      case "City":
        setAnchorElCity(event.currentTarget);
        break;
      case "Services":
        setAnchorElServices(event.currentTarget);
        break;
      default:
        break;
    }
  };

  const handleCloseMenu = (menu) => {
    switch (menu) {
      case "City":
        setAnchorElCity(null);
        break;
      case "Services":
        setAnchorElServices(null);
        break;
      default:
        break;
    }
  };

  const handleDrawer = () => {
    setDrawerOpen((editing) => !editing);
  };
  const handleAddressDrawer = () => {
    setAddressDrawer((editing) => !editing);
  };

  // open menu
  const [anchorElProfileMenu, setAnchorElProfileMenu] = useState(null);
  const openProfileMenu = Boolean(anchorElProfileMenu);

  const handleOpenProfileMenu = (event) => {
    setAnchorElProfileMenu(event.currentTarget);
  };

  const handleCloseProfileMenu = () => {
    setAnchorElProfileMenu(null);
  };

  const setCity = (value) => {
    setCookies(
      config.preferencesCookie,
      JSON.stringify({
        ...cookies[config.preferencesCookie],
        city: value,
      }),
      { path: "/", maxAge: 3000000, sameSite: "strict" }
    );
  };

  const handleCountry = (value) => {
    try {
      setCookies(
        config.preferencesCookie,
        JSON.stringify({
          ...cookies[config.preferencesCookie],
          country: value,
        }),
        { path: "/", maxAge: 3000000, sameSite: "strict" }
      );
    } catch (error) {
      toast.error("Error setting cookie!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  useEffect(() => {
    if (!cookies[config.preferencesCookie]?.propertyType) {
      setCookies(
        config.preferencesCookie,
        JSON.stringify({
          ...cookies[config.preferencesCookie],
          propertyType: propertyTypes[0].buildingType,
          buildingType: null,
          subPropertyType: null,
          minPrice: null,
          maxPrice: null,
          minSize: null,
          maxSize: null,
          country: "India",
          postedOn: null,
          amenity: null,
          furnishType: null,
          facing: null,
          bhk: null,
          searchText: null,
        }),
        { path: "/", maxAge: 3000000, sameSite: "strict" }
      );
    }
  }, [cookies]);

  return (
    <>
      <CssBaseline />
      <AppBar sx={{ background: "#000", position: "sticky", top: 0, left: 0 }}>
        <Toolbar>
          <Grid container alignItems="center">
            {isMobile ? (
              // Render components for mobile layout
              <>
                <Grid
                  item
                  xs={3}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  {/* Render menu icon for mobile */}
                  <IconButton
                    color="inherit"
                    edge="start"
                    aria-label="menu"
                    onClick={handleDrawer}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Typography
                    variant="h1"
                    sx={{
                      color: "#fff",
                      fontFamily: "Jost, sans-serif",
                      fontSize: "15px",
                      fontWeight: 550,
                      textTransform: "capitalize",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    Property
                    <br /> Broker
                  </Typography>
                </Grid>
                <Grid item xs={3} sx={{ textAlign: "center" }}>
                  {/* Render city menu for mobile */}
                  <Button
                    color="inherit"
                    endIcon={<KeyboardArrowDownIcon />}
                    onClick={handleAddressDrawer}
                    sx={{ fontSize: "13px" }}
                  >
                    City
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    textAlign: "right",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      fontSize: "10px",
                      color: "#fff",
                      textWrap: "nowrap",
                      borderColor: "#FF0000",
                    }}
                    onClick={() => {
                      cookies[config.cookieName]?.token &&
                      cookies[config.cookieName]?.loginUserId
                        ? navigate("/post-property")
                        : navigate("/login");
                    }}
                  >
                    Sell or Rent Property
                  </Button>
                </Grid>
              </>
            ) : (
              <>
                <Grid item md={3}>
                  <Typography
                    variant="h1"
                    sx={{
                      color: "#fff",
                      fontFamily: "Jost, sans-serif",
                      fontSize: { md: "26px", xs: "17px" },
                      fontWeight: 550,
                      textTransform: "capitalize",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    Property Broker
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Stack direction="row" spacing={2}>
                    <Button
                      color="inherit"
                      // endIcon={<KeyboardArrowDownIcon />}
                      onClick={(event) => handleOpenMenu(event, "City")}
                    >
                      City
                    </Button>
                    <Popover
                      open={Boolean(anchorElCity)}
                      anchorEl={anchorElCity}
                      onClose={() => handleCloseMenu("City")}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      sx={{ maxHeight: "calc(100vh - 200px)" }}
                    >
                      <List>
                        <>
                          <ListItem
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                border: "1px solid #b2bec3",
                                borderRadius: "5px",
                                px: "15px",
                                mr: "10px",
                                py: "5px",
                                backgroundColor:
                                  cookies[config.preferencesCookie]?.country ===
                                  "India"
                                    ? "#b2bec3"
                                    : "#fff",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                handleCountry("India");
                                setSearchText("");
                              }}
                            >
                              <img
                                src="/media/svg/india.svg"
                                alt="india"
                                height="30px"
                              />
                              <Typography sx={{ fontSize: "13px" }}>
                                India
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                border: "1px solid #b2bec3",
                                borderRadius: "5px",
                                px: "15px",
                                backgroundColor:
                                  cookies[config.preferencesCookie]?.country ===
                                  "UAE"
                                    ? "#b2bec3"
                                    : "#fff",
                                py: "5px",
                                cursor: "pointer",
                                mr: "10px",
                              }}
                              onClick={() => {
                                handleCountry("UAE");
                                setSearchText("");
                              }}
                            >
                              <img
                                src="/media/svg/united-arab-emirates.svg"
                                alt="india"
                                height="30px"
                              />
                              <Typography sx={{ fontSize: "13px" }}>
                                UAE
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                border: "1px solid #b2bec3",
                                borderRadius: "5px",
                                px: "15px",
                                backgroundColor:
                                  cookies[config.preferencesCookie]?.country ===
                                  "Canada"
                                    ? "#b2bec3"
                                    : "#fff",
                                cursor: "pointer",
                                py: "5px",
                                mr: "10px",
                              }}
                              onClick={() => {
                                handleCountry("Canada");
                                setSearchText("");
                              }}
                            >
                              <img
                                src="/media/svg/canada.svg"
                                alt="india"
                                height="30px"
                              />
                              <Typography sx={{ fontSize: "13px" }}>
                                Canada
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                                border: "1px solid #b2bec3",
                                borderRadius: "5px",
                                px: "15px",
                                backgroundColor:
                                  cookies[config.preferencesCookie]?.country ===
                                  "Australia"
                                    ? "#b2bec3"
                                    : "#fff",
                                py: "5px",
                              }}
                              onClick={() => {
                                handleCountry("Australia");
                                setSearchText("");
                              }}
                            >
                              <img
                                src="/media/svg/australia.svg"
                                alt="india"
                                height="30px"
                              />
                              <Typography sx={{ fontSize: "13px" }}>
                                Australia
                              </Typography>
                            </Box>
                          </ListItem>
                          <ListItem>
                            <TextField
                              variant="outlined"
                              placeholder="Search"
                              size="small"
                              fullWidth
                              value={searchText || ""}
                              onChange={handleSearchChange}
                              sx={{ backgroundColor: "#EFEFEF", width: "100%" }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </ListItem>
                          <ListItem
                            sx={{
                              cursor: "default",
                              fontWeight: "bold",
                            }}
                          >
                            <ListItemText
                              primary="Top Cities"
                              sx={{
                                fontSize: "15px",
                                textDecoration: "underline",
                              }}
                            />
                          </ListItem>
                          {cookies[config.preferencesCookie]?.country ===
                            "India" && (
                            <>
                              <Box
                                sx={{
                                  display: "grid",
                                  gridTemplateColumns: "1fr 1fr 1fr",
                                  px: "15px",
                                  columnGap: "10px",
                                }}
                              >
                                {searchText === ""
                                  ? // If searchText is empty, show all top cities
                                    topCities.india.map((city) => (
                                      <Box
                                        key={city.name}
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          border: "1px solid gray",
                                          borderRadius: "5px",
                                          my: "10px",
                                          p: "8px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          setCity(city.name);
                                          handleCloseMenu("City");
                                        }}
                                      >
                                        <img
                                          src={city.svg}
                                          height="30px"
                                          alt="cities"
                                        />
                                        <Typography
                                          variant="caption"
                                          sx={{
                                            "&:hover": { color: "blue" },
                                          }}
                                        >
                                          {city.name}
                                        </Typography>
                                      </Box>
                                    ))
                                  : filteredTopCities?.map((city) => (
                                      <Box
                                        key={city.name}
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          border: "1px solid gray",
                                          borderRadius: "5px",
                                          my: "10px",
                                          p: "8px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          setCity(city.name);
                                          handleCloseMenu("City");
                                        }}
                                      >
                                        <img
                                          src={city.svg}
                                          height="30px"
                                          alt="cities"
                                        />
                                        <Typography
                                          variant="caption"
                                          sx={{
                                            "&:hover": { color: "blue" },
                                          }}
                                        >
                                          {city.name}
                                        </Typography>
                                      </Box>
                                    ))}
                              </Box>
                              {searchText === ""
                                ? // If searchText is empty, show all other cities
                                  indiaOtherCities.map((item) => (
                                    <ListItem
                                      key={item}
                                      button
                                      onClick={() => setCity(item)}
                                    >
                                      <ListItemText primary={item} />
                                    </ListItem>
                                  ))
                                : filteredOtherCities.map((item) => (
                                    <ListItem
                                      key={item}
                                      button
                                      onClick={() => setCity(item)}
                                    >
                                      <ListItemText primary={item} />
                                    </ListItem>
                                  ))}
                            </>
                          )}

                          {cookies[config.preferencesCookie]?.country ===
                            "UAE" &&
                            filteredOtherCities.map((item) => (
                              <ListItem
                                key={item}
                                button
                                onClick={() => setCity(item)}
                              >
                                <ListItemText primary={item} />
                              </ListItem>
                            ))}
                          {cookies[config.preferencesCookie]?.country ===
                            "Canada" &&
                            filteredOtherCities.map((item) => (
                              <ListItem
                                key={item}
                                button
                                onClick={() => setCity(item)}
                              >
                                <ListItemText primary={item} />
                              </ListItem>
                            ))}
                          {cookies[config.preferencesCookie]?.country ===
                            "Australia" &&
                            filteredOtherCities.map((item) => (
                              <ListItem
                                key={item}
                                button
                                onClick={() => setCity(item)}
                              >
                                <ListItemText primary={item} />
                              </ListItem>
                            ))}
                        </>
                      </List>
                    </Popover>
                    <Button
                      color="inherit"
                      onClick={() => {
                        setCookies(
                          config.preferencesCookie,
                          JSON.stringify({
                            ...cookies[config.preferencesCookie],
                            propertyType: "Buy",
                          }),
                          { path: "/", maxAge: 3000000, sameSite: "strict" }
                        );
                        navigate("/property-list");
                      }}
                    >
                      Buy
                    </Button>
                    <Button
                      color="inherit"
                      onClick={() => {
                        setCookies(
                          config.preferencesCookie,
                          JSON.stringify({
                            ...cookies[config.preferencesCookie],
                            propertyType: "Rent",
                          }),
                          { path: "/", maxAge: 3000000, sameSite: "strict" }
                        );
                        navigate("/property-list");
                      }}
                    >
                      Rent
                    </Button>
                    <Button
                      color="inherit"
                      // endIcon={<KeyboardArrowDownIcon />}
                      onClick={(event) => handleOpenMenu(event, "Services")}
                    >
                      Services
                    </Button>
                    <Popover
                      open={Boolean(anchorElServices)}
                      anchorEl={anchorElServices}
                      onClose={() => handleCloseMenu("Services")}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <Grid container>
                        {/* First Column */}
                        <Grid
                          item
                          md={4}
                          sx={{
                            backgroundColor: "#ced6e0",
                            padding: "30px 40px",
                          }}
                          className="border"
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: "bold" }}
                          >
                            For Buyers
                          </Typography>
                          <Box sx={{ mt: 1 }}>
                            {MenuServices.forBuyers.map((item, index) => (
                              <MenuItem
                                onClick={() => {
                                  handleCloseMenu("Services");
                                  navigate(item.navigate);
                                }}
                                key={index}
                              >
                                <ListItemIcon style={{ minWidth: "20px" }}>
                                  <FiberManualRecordIcon
                                    style={{ fontSize: 12 }}
                                  />{" "}
                                  {/* Dot icon */}
                                </ListItemIcon>
                                <Typography
                                  variant="inherit"
                                  sx={{ fontSize: "14px" }}
                                >
                                  {item.name}
                                </Typography>
                              </MenuItem>
                            ))}
                          </Box>
                          {/* Add more submenu items as needed */}
                        </Grid>

                        {/* Second Column */}
                        <Grid
                          item
                          md={4}
                          sx={{ padding: "30px 40px" }}
                          className="border"
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: "bold" }}
                          >
                            For Tenants
                          </Typography>
                          {MenuServices.forTenants.map((item, index) => (
                            <MenuItem
                              onClick={() => {
                                handleCloseMenu("Services");
                                navigate(item.navigate);
                              }}
                              key={index}
                            >
                              <ListItemIcon style={{ minWidth: "20px" }}>
                                <FiberManualRecordIcon
                                  style={{ fontSize: 12 }}
                                />{" "}
                                {/* Dot icon */}
                              </ListItemIcon>
                              <Typography
                                variant="inherit"
                                sx={{ fontSize: "14px" }}
                              >
                                {item.name}
                              </Typography>
                            </MenuItem>
                          ))}
                          {/* Add more submenu items as needed */}
                        </Grid>

                        {/* Third Column */}
                        <Grid
                          item
                          md={4}
                          sx={{
                            backgroundColor: "#ced6e0",
                            padding: "30px 40px",
                          }}
                          className="border"
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: "bold" }}
                          >
                            For Owners
                          </Typography>
                          {MenuServices.forOwners.map((item, index) => (
                            <MenuItem
                              onClick={() => {
                                handleCloseMenu("Services");
                                navigate(item.navigate);
                              }}
                              key={index}
                            >
                              <ListItemIcon style={{ minWidth: "20px" }}>
                                <FiberManualRecordIcon
                                  style={{ fontSize: 12 }}
                                />{" "}
                                {/* Dot icon */}
                              </ListItemIcon>
                              <Typography
                                variant="inherit"
                                sx={{ fontSize: "14px" }}
                              >
                                {item.name}
                              </Typography>
                            </MenuItem>
                          ))}
                          {/* Add more submenu items as needed */}
                        </Grid>
                      </Grid>
                    </Popover>
                  </Stack>
                </Grid>
                <Grid item md={3} sx={{ display: "flex", gap: "10px" }}>
                  <Button
                    variant="contained"
                    sx={{
                      textWrap: "nowrap",
                      backgroundColor: "red",
                      border: "1px solid white",
                      overflow: "hidden",
                      fontSize: "12px",
                      "&:hover": { backgroundColor: "red" },
                    }}
                    onClick={() => {
                      if (
                        cookies[config.cookieName]?.loginUserId &&
                        cookies[config.cookieName]?.token
                      ) {
                        navigate("/post-property");
                      } else {
                        navigate("/login");
                      }
                    }}
                  >
                    Post Property Now
                  </Button>
                  {cookies[config.cookieName]?.token &&
                  cookies[config.cookieName]?.loginUserId ? (
                    <Box>
                      <IconButton
                        onClick={handleOpenProfileMenu}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={
                          anchorElProfileMenu ? "account-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={anchorElProfileMenu ? "true" : undefined}
                      >
                        <Avatar
                          sx={{
                            width: 32,
                            height: 32,
                            textTransform: "uppercase",
                          }}
                        >
                          {userData?.user.fullName[0]}
                        </Avatar>
                      </IconButton>
                      <Menu
                        anchorEl={anchorElProfileMenu}
                        id="account-menu"
                        open={openProfileMenu}
                        onClose={handleCloseProfileMenu}
                        onClick={handleCloseProfileMenu}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            overflow: "visible",
                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                            mt: 1.5,
                            "& .MuiAvatar-root": {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                            },
                            "&:before": {
                              content: '""',
                              display: "block",
                              position: "absolute",
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              bgcolor: "background.paper",
                              transform: "translateY(-50%) rotate(45deg)",
                              zIndex: 0,
                            },
                          },
                        }}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            navigate("/post-property");
                            handleCloseProfileMenu();
                          }}
                        >
                          <ListItemIcon>
                            <PersonAdd fontSize="small" />
                          </ListItemIcon>
                          DashBoard
                        </MenuItem>
                        <Divider />
                        <MenuItem
                          onClick={() => {
                            handleCloseProfileMenu();
                            navigate("/logout");
                          }}
                        >
                          <ListItemIcon>
                            <Logout fontSize="small" />
                          </ListItemIcon>
                          Logout
                        </MenuItem>
                      </Menu>
                    </Box>
                  ) : (
                    <ButtonGroup
                      variant="outlined"
                      aria-label="outlined button group"
                      sx={{
                        flexDirection: { sm: "row", xs: "column" }, // Set to column under 600px
                      }}
                    >
                      <Button
                        variant="outlined"
                        sx={{
                          color: "white",
                          borderColor: "#fff",
                          background: "red",
                          "&:hover": {
                            background: "var(--BG-Color-5, #F8F8F8)",
                            color: "#DB282F",
                          },
                        }}
                        size="small"
                        onClick={() => navigate("/login")}
                      >
                        Login
                      </Button>
                      <Button
                        variant="outlined"
                        sx={{
                          color: "white",
                          borderColor: "#fff",
                          background: "red",
                          "&:hover": {
                            background: "var(--BG-Color-5, #F8F8F8)",
                            color: "#DB282F",
                          },
                        }}
                        onClick={() => navigate("/register")}
                        size="small"
                      >
                        SignUp
                      </Button>
                    </ButtonGroup>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
      {/* Menu Drawer */}
      <Drawer
        open={isDrawerOpen}
        onClose={handleDrawer}
        PaperProps={{
          sx: { width: "100%" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: 2,
          }}
        >
          <DialogTitle sx={{ fontWeight: "bold", fontSize: "15px" }}>
            Property Broker
          </DialogTitle>
          <IconButton onClick={handleDrawer}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <List>
          <ListItem>
            <ListItemButton
              onClick={() => {
                handleDrawer();
                navigate("/");
              }}
            >
              <img
                src="/media/svg/home-icon-silhouette-svgrepo-com.svg"
                alt="Buy"
                height="30px"
                style={{ marginRight: "8px" }}
              />
              <Typography>Home</Typography>
              <IconButton size="small" sx={{ marginLeft: "auto" }}>
                <KeyboardArrowRightIcon />
              </IconButton>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              onClick={() => {
                setCookies(
                  config.preferencesCookie,
                  JSON.stringify({
                    ...cookies[config.preferencesCookie],
                    propertyType: "Buy",
                  }),
                  { path: "/", maxAge: 3000000, sameSite: "strict" }
                );
                navigate("/property-list");
                handleDrawer();
              }}
            >
              <img
                src="/media/svg/home-icon-silhouette-svgrepo-com.svg"
                alt="Buy"
                height="30px"
                style={{ marginRight: "8px" }}
              />
              <Typography>Buy</Typography>
              <IconButton size="small" sx={{ marginLeft: "auto" }}>
                <KeyboardArrowRightIcon />
              </IconButton>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              onClick={() => {
                setCookies(
                  config.preferencesCookie,
                  JSON.stringify({
                    ...cookies[config.preferencesCookie],
                    propertyType: "Rent",
                  }),
                  { path: "/", maxAge: 3000000, sameSite: "strict" }
                );
                navigate("/property-list");
                handleDrawer();
              }}
            >
              <img
                src="/media/svg/home-icon-silhouette-svgrepo-com.svg"
                alt="Rent"
                height="30px"
                style={{ marginRight: "8px" }}
              />
              <Typography>Rent</Typography>
              <IconButton size="small" sx={{ marginLeft: "auto" }}>
                <KeyboardArrowRightIcon />
              </IconButton>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={handleOpenServiceDrawer}>
              <img
                src="/media/svg/home-icon-silhouette-svgrepo-com.svg"
                alt="Services"
                height="30px"
                style={{ marginRight: "8px" }}
              />
              <Typography>Services</Typography>
              <IconButton size="small" sx={{ marginLeft: "auto" }}>
                <KeyboardArrowRightIcon />
              </IconButton>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              onClick={() => {
                handleDrawer();
                navigate("/about-us");
              }}
            >
              <img
                src="/media/svg/home-icon-silhouette-svgrepo-com.svg"
                alt="Buy"
                height="30px"
                style={{ marginRight: "8px" }}
              />
              <Typography>About Us</Typography>
              <IconButton size="small" sx={{ marginLeft: "auto" }}>
                <KeyboardArrowRightIcon />
              </IconButton>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              onClick={() => {
                handleDrawer();
                navigate("/contact-us");
              }}
            >
              <img
                src="/media/svg/home-icon-silhouette-svgrepo-com.svg"
                alt="Buy"
                height="30px"
                style={{ marginRight: "8px" }}
              />
              <Typography>Contact Us</Typography>
              <IconButton size="small" sx={{ marginLeft: "auto" }}>
                <KeyboardArrowRightIcon />
              </IconButton>
            </ListItemButton>
          </ListItem>
        </List>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            p: 1.5,
            pb: 2,
            borderTop: "1px solid",
            borderColor: "divider",
            alignItems: "center",
          }}
        >
          {!cookies[config.cookieName]?.token &&
          !cookies[config.cookieName]?.loginUserId ? (
            <>
              <Typography sx={{ fontSize: "12px" }}>
                Login to Your Account
              </Typography>
              <Button
                variant="outlined"
                sx={{ borderColor: "#FF0000", color: "#FF0000" }}
                onClick={() => navigate("/login")}
              >
                Login
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="outlined"
                sx={{ borderColor: "#FF0000", color: "#FF0000" }}
                onClick={() => navigate("/post-property")}
              >
                Dashboard
              </Button>
            </>
          )}
        </Box>
      </Drawer>

      {/* Address Drawer */}
      <Drawer
        open={addressDrawer}
        onClose={handleAddressDrawer}
        anchor="right"
        PaperProps={{
          sx: { width: "100%" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: 2,
          }}
        >
          <IconButton onClick={handleAddressDrawer}>
            <ArrowBackIcon />
          </IconButton>
        </Box>
        <Divider />
        <List>
          <>
            <ListItem
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid #b2bec3",
                  borderRadius: "5px",
                  px: "15px",
                  mx: "5px",
                  py: "5px",
                  backgroundColor:
                    cookies[config.preferencesCookie]?.country === "India"
                      ? "#b2bec3"
                      : "#fff",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleCountry("India");
                }}
              >
                <img src="/media/svg/india.svg" alt="india" height="30px" />
                <Typography sx={{ fontSize: "13px" }}>India</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid #b2bec3",
                  borderRadius: "5px",
                  px: "15px",
                  mx: "5px",
                  backgroundColor:
                    cookies[config.preferencesCookie]?.country === "UAE"
                      ? "#b2bec3"
                      : "#fff",
                  py: "5px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleCountry("UAE");
                }}
              >
                <img
                  src="/media/svg/united-arab-emirates.svg"
                  alt="india"
                  height="30px"
                />
                <Typography sx={{ fontSize: "13px" }}>UAE</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid #b2bec3",
                  borderRadius: "5px",
                  px: "15px",
                  backgroundColor:
                    cookies[config.preferencesCookie]?.country === "Canada"
                      ? "#b2bec3"
                      : "#fff",
                  cursor: "pointer",
                  py: "5px",
                  mx: "5px",
                }}
                onClick={() => {
                  handleCountry("Canada");
                }}
              >
                <img src="/media/svg/canada.svg" alt="india" height="30px" />
                <Typography sx={{ fontSize: "13px" }}>Canada</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  border: "1px solid #b2bec3",
                  borderRadius: "5px",
                  px: "15px",
                  mx: "5px",
                  backgroundColor:
                    cookies[config.preferencesCookie]?.country === "Australia"
                      ? "#b2bec3"
                      : "#fff",
                  py: "5px",
                }}
                onClick={() => {
                  handleCountry("Australia");
                }}
              >
                <img src="/media/svg/australia.svg" alt="india" height="30px" />
                <Typography sx={{ fontSize: "13px" }}>Australia</Typography>
              </Box>
            </ListItem>
            <ListItem>
              <TextField
                variant="outlined"
                placeholder="Search"
                size="small"
                fullWidth
                value={searchText || ""}
                onChange={handleSearchChange}
                sx={{ backgroundColor: "#EFEFEF", width: "100%" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </ListItem>
            <ListItem
              sx={{
                cursor: "default",
                fontWeight: "bold",
              }}
            >
              <ListItemText
                primary="Top Cities"
                sx={{
                  fontSize: "15px",
                  textDecoration: "underline",
                }}
              />
            </ListItem>
            {cookies[config.preferencesCookie]?.country === "India" && (
              <>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    px: "15px",
                    columnGap: "10px",
                  }}
                >
                  {searchText === ""
                    ? // If searchText is empty, show all top cities
                      topCities.india.map((city) => (
                        <Box
                          key={city.name}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "1px solid gray",
                            borderRadius: "5px",
                            my: "10px",
                            p: "8px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setCity(city.name);
                            handleCloseMenu("City");
                            setAddressDrawer(false);
                          }}
                        >
                          <img src={city.svg} height="30px" alt="cities" />
                          <Typography
                            variant="caption"
                            sx={{
                              "&:hover": { color: "blue" },
                            }}
                          >
                            {city.name}
                          </Typography>
                        </Box>
                      ))
                    : filteredTopCities?.map((city) => (
                        <Box
                          key={city.name}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "1px solid gray",
                            borderRadius: "5px",
                            my: "10px",
                            p: "8px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setCity(city.name);
                            handleCloseMenu("City");
                            setAddressDrawer(false);
                          }}
                        >
                          <img src={city.svg} height="30px" alt="cities" />
                          <Typography
                            variant="caption"
                            sx={{
                              "&:hover": { color: "blue" },
                            }}
                          >
                            {city.name}
                          </Typography>
                        </Box>
                      ))}
                </Box>
                {searchText === ""
                  ? // If searchText is empty, show all other cities
                    indiaOtherCities.map((item) => (
                      <ListItem key={item} button onClick={() => setCity(item)}>
                        <ListItemText primary={item} />
                      </ListItem>
                    ))
                  : filteredOtherCities.map((item) => (
                      <ListItem key={item} button onClick={() => setCity(item)}>
                        <ListItemText primary={item} />
                      </ListItem>
                    ))}
              </>
            )}

            {cookies[config.preferencesCookie]?.country === "UAE" &&
              filteredOtherCities.map((item) => (
                <ListItem key={item} button onClick={() => setCity(item)}>
                  <ListItemText primary={item} />
                </ListItem>
              ))}
            {cookies[config.preferencesCookie]?.country === "Canada" &&
              filteredOtherCities.map((item) => (
                <ListItem key={item} button onClick={() => setCity(item)}>
                  <ListItemText primary={item} />
                </ListItem>
              ))}
            {cookies[config.preferencesCookie]?.country === "Australia" &&
              filteredOtherCities.map((item) => (
                <ListItem key={item} button onClick={() => setCity(item)}>
                  <ListItemText primary={item} />
                </ListItem>
              ))}
          </>
        </List>
      </Drawer>

      {/* Services List Drawer */}
      <Drawer
        open={mobileServiceDrawer}
        onClose={handleOpenServiceDrawer}
        anchor="right"
        PaperProps={{
          sx: { width: "100%" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            px: 2,
          }}
        >
          <IconButton onClick={handleOpenServiceDrawer}>
            <ArrowBackIcon />
          </IconButton>
          <Typography>Services</Typography>
        </Box>
        <Divider />
        <List component="nav">
          <ListItem
            button
            onClick={() => setMobileforBuyersMenu(!mobileforBuyersMenu)}
          >
            <ListItemText primary="For Buyers" />
            {mobileforBuyersMenu ? <MinimizeIcon /> : <AddIcon />}
          </ListItem>
          <Collapse in={mobileforBuyersMenu} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {MenuServices.forBuyers?.map((item, index) => (
                <ListItem
                  button
                  style={{ paddingLeft: "16px" }}
                  key={index}
                  onClick={() => {
                    handleCloseMenu("Services");
                    navigate(item.navigate);
                  }}
                >
                  <ListItemText primary={item.name} />
                </ListItem>
              ))}
              {/* Add more sub-menu items as needed */}
            </List>
          </Collapse>
          <ListItem
            button
            onClick={() => setMobileforTenantsMenu(!mobileforTenantsMenu)}
          >
            <ListItemText primary="For Tenants" />
            {mobileforTenantsMenu ? <MinimizeIcon /> : <AddIcon />}
          </ListItem>
          <Collapse in={mobileforTenantsMenu} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {MenuServices.forTenants?.map((item, index) => (
                <ListItem
                  button
                  style={{ paddingLeft: "16px" }}
                  key={index}
                  onClick={() => {
                    handleCloseMenu("Services");
                    navigate(item.navigate);
                  }}
                >
                  <ListItemText primary={item.name} />
                </ListItem>
              ))}
              {/* Add more sub-menu items as needed */}
            </List>
          </Collapse>
          <ListItem
            button
            onClick={() => setMobileforOwnersMenu(!mobileforOwnersMenu)}
          >
            <ListItemText primary="For Owners" />
            {mobileforOwnersMenu ? <MinimizeIcon /> : <AddIcon />}
          </ListItem>
          <Collapse in={mobileforOwnersMenu} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {MenuServices.forOwners?.map((item, index) => (
                <ListItem
                  button
                  style={{ paddingLeft: "16px" }}
                  key={index}
                  onClick={() => {
                    handleCloseMenu("Services");
                    navigate(item.navigate);
                  }}
                >
                  <ListItemText primary={item.name} />
                </ListItem>
              ))}
              {/* Add more sub-menu items as needed */}
            </List>
          </Collapse>
        </List>
      </Drawer>
    </>
  );
};

export default Header;

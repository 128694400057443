import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { subPropertyOptions } from "../../../../../common/common";

const SubPropertyType = ({
  next,
  back,
  formData,
  updateFormData,
  detailsId,
}) => {
  const [subProperty, setsubPropertyType] = useState(formData.subProperty);

  const data = { subProperty };
  const send = () => updateFormData(data);

  return (
    <div>
      <Typography sx={{ mb: 1, fontWeight: "bold" }}>
        Sub-Properties Types
      </Typography>
      <Box
        className="mydict"
        sx={{
          display: "flex",
          flexDirection: "row",
          flexGrow: 1,
          flexWrap: "wrap",
          mb: 2,
        }}
      >
        {subPropertyOptions.map((item) => (
          <Box mb={2}>
            <label
              className="mydict1 border1"
              onChange={(event) => {
                setsubPropertyType(event.target.value);
              }}
              key={item}
            >
              <input
                type="radio"
                name="radio8"
                value={item}
                checked={subProperty === item}
                readOnly
              />
              <span className="border1">{item}</span>
            </label>
          </Box>
        ))}
      </Box>

      {!detailsId && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            pt: 2,
          }}
        >
          <Button
            sx={{ fontSize: "15px", fontWeight: 500 }}
            color="inherit"
            onClick={() => {
              back();
              send();
            }}
          >
            Back
          </Button>
          <Button
            sx={{ fontSize: "15px", fontWeight: 500 }}
            color="inherit"
            onClick={() => {
              next();
              send();
            }}
          >
            Next
          </Button>
        </Box>
      )}
      {detailsId && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            pt: 2,
          }}
        >
          <Button
            color="inherit"
            onClick={() => {
              back();
            }}
          >
            Back
          </Button>
          <Box>
            <Button
              color="inherit"
              onClick={() => {
                send();
                next();
              }}
            >
              update
            </Button>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default SubPropertyType;

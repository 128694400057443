import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PackagesList from "./components/packages/PackagesList";
import MyPackages from "./components/packages/MyPackages";
import PackageBuy from "./components/packages/PackageBuy";
import UsersList from "./components/user/UsersList";
import PackageAdd from "./components/packages/PackageAdd";
import PackageUpdate from "./components/packages/PackageUpdate";
import UserAdd from "./components/user/UserAdd";
import UserUpdate from "./components/user/UserUpdate";
import RentalPropertyView from "./components/properties/propertiesList/RentalPropertyView";
import Home from "./components/Home";
import Login from "./components/login/Login";
import ServiceCharges from "./components/ServiceCharges";
import EditProperty from "./components/properties/propertiesList/edit-property/EditProperty";
import PropertyList from "./components/end-user/PropertyList";
import MyPropertyLists from "./components/properties/propertiesList/MyPropertyLists";
import PropertyListingType from "./components/properties/post-properties/PropertyListingType";
import Logout from "./components/login/Logout";
import DashboardAnalytics from "./components/generic/dashboard/DashboardAnalytics";
import { CookiesProvider } from "react-cookie";
import AboutUs from "./components/static-page/AboutUs";
import ContactUs from "./components/static-page/ContactUs";
import NonRefundPolicy from "./components/static-page/NonRefundPolicy";
import PrivacyAndPolicy from "./components/static-page/PrivacyAndPoicy";
import TermsAndConditions from "./components/static-page/TermsAndConditions";
import LeadsList from "./components/leads/leadsList";
import { Provider } from "react-redux";
import store from "./global/redux/store";
import ProtectedRoute from "./components/functional-component/ProtectedRoute";
import Register from "./components/login/Register";
import OverViewDetails from "./components/end-user/PropertyDetails/OverViewDetails";
import ViewProperty from "./components/properties/propertiesList/ViewProperty";
import CreateLead from "./components/leads/CreateLead";
import HomeLoan from "./components/home-components/services/HomeLoan";
import CustomizedFurniture from "./components/home-components/services/CustomizedFurniture";
import EscrowServices from "./components/home-components/services/EscrowServices";
import HomeInterior from "./components/home-components/services/HomeInterior";
import ITServices from "./components/home-components/services/ITServices";
import LegalServices from "./components/home-components/services/LegalServices";
import Litigation from "./components/home-components/services/Litigation";
import PackersMovers from "./components/home-components/services/PackersMovers";
import PaintingService from "./components/home-components/services/PaintingService";
import PropertyManagement from "./components/home-components/services/PropertyManagement";
import RentReceiptGenerator from "./components/home-components/services/RentReceiptGenerator";
import RentAgreement from "./components/home-components/services/RentAgreement";
import RentalFurniture from "./components/home-components/services/RentalFurniture";
import SellorRentProperty from "./components/home-components/services/SellorRentProperty";
import SiteInspection from "./components/home-components/services/SiteInspection";
import SiteRegistration from "./components/home-components/services/SiteRegistration";
import SolarRooftop from "./components/home-components/services/SolarRooftop";
import TitleSearch from "./components/home-components/services/TitleSearch";
import Valuvation from "./components/home-components/services/Valuvation";
import VastuConsult from "./components/home-components/services/VastuConsult";
import ResetPassword from "./components/login/reset-password/ResetPassword";
import AdminPendingPackages from "./components/packages/AdminPendingPackages";

const App = () => {
  return (
    <CookiesProvider>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/register" element={<Register />} />

            {/* below the route for public users  */}
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/service-charges" element={<ServiceCharges />} />
            <Route path="/non-refund-policy" element={<NonRefundPolicy />} />
            <Route path="/terms-conditions" element={<TermsAndConditions />} />
            <Route path="/privacy-policy" element={<PrivacyAndPolicy />} />

            {/* below the route for registered users  */}
            <Route
              path="/post-property"
              element={
                <ProtectedRoute>
                  <PropertyListingType />
                </ProtectedRoute>
              }
            />
            <Route path="/my-property-lists" element={<MyPropertyLists />} />
            <Route
              path="/property-edit/:id"
              element={
                <ProtectedRoute>
                  <EditProperty />
                </ProtectedRoute>
              }
            />
            <Route
              path="/view-property/:propertyId"
              element={
                <ProtectedRoute>
                  <ViewProperty />
                </ProtectedRoute>
              }
            />
            <Route
              path="/user-add"
              element={
                <ProtectedRoute>
                  <UserAdd />
                </ProtectedRoute>
              }
            />
            <Route
              path="/users-list"
              element={
                <ProtectedRoute>
                  <UsersList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/add-package"
              element={
                <ProtectedRoute>
                  <PackageAdd />
                </ProtectedRoute>
              }
            />
            <Route path="/packages-list" element={<PackagesList />} />
            <Route
              path="/user-update/:id"
              element={
                <ProtectedRoute>
                  <UserUpdate />
                </ProtectedRoute>
              }
            />
            <Route
              path="/buy-package"
              element={
                <ProtectedRoute>
                  <PackageBuy />
                </ProtectedRoute>
              }
            />
            <Route
              path="/my-packages"
              element={
                <ProtectedRoute>
                  <MyPackages />
                </ProtectedRoute>
              }
            />
            <Route
              path="/update-package/:id"
              element={
                <ProtectedRoute>
                  <PackageUpdate />
                </ProtectedRoute>
              }
            />

            <Route
              path="/leads-list"
              element={
                <ProtectedRoute>
                  <LeadsList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/pending-packages-list"
              element={
                <ProtectedRoute>
                  <AdminPendingPackages />
                </ProtectedRoute>
              }
            />
            <Route
              path="/create-lead"
              element={
                <ProtectedRoute>
                  <CreateLead />
                </ProtectedRoute>
              }
            />

            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <DashboardAnalytics />
                </ProtectedRoute>
              }
            />
            <Route
              path="/rental-property-view/:id"
              element={<RentalPropertyView />}
            />

            {/* below the two route for property list and property details  */}
            <Route path="/property-list" element={<PropertyList />} />

            {/* below the route is dummy route */}
            {/* <Route path="/property-details/:id" element={<Figma />} /> */}
            <Route path="/property-details/:id" element={<OverViewDetails />} />

            {/*Code for the Services Offers */}

            <Route
              path="/customized-furniture"
              element={<CustomizedFurniture />}
            />
            <Route path="/escrow-services" element={<EscrowServices />} />
            <Route path="/home-interior" element={<HomeInterior />} />
            <Route path="/home-loan" element={<HomeLoan />} />
            <Route path="/it-services" element={<ITServices />} />
            <Route path="/legal-services" element={<LegalServices />} />
            <Route path="/litigation" element={<Litigation />} />
            <Route path="/packers-movers" element={<PackersMovers />} />
            <Route path="/painting-service" element={<PaintingService />} />
            <Route
              path="/property-management"
              element={<PropertyManagement />}
            />
            <Route
              path="/rent-receipt-generator"
              element={<RentReceiptGenerator />}
            />
            <Route path="/rent-agreement" element={<RentAgreement />} />
            <Route path="/rental-furniture" element={<RentalFurniture />} />
            <Route
              path="/sell-or-rent-property"
              element={<SellorRentProperty />}
            />
            <Route path="/site-inspection" element={<SiteInspection />} />
            <Route path="/site-registration" element={<SiteRegistration />} />
            <Route path="/solar-rooftop" element={<SolarRooftop />} />
            <Route path="/title-search" element={<TitleSearch />} />
            <Route path="/valuvation" element={<Valuvation />} />
            <Route path="/vastu-consult" element={<VastuConsult />} />
            <Route path="/reset-password" element={<ResetPassword />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </CookiesProvider>
  );
};

export default App;

import { Box } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { tools } from "../../common/common";
import LeadModal from "./../end-user/LeadModal";

const Service = () => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [leadSource, setLeadSource] = useState("");
  const hanldeChangeModal = () => setModal((editing) => !editing);
  return (
    <>
      <Box
        sx={{
          color: "var(--Text-Color-Grey-Bold, #333)",
          textAlign: "center",
          fontFamily: "Mulish, sans-serif",
          fontSize: "26px",
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "normal",
          mb: "20px",
          mt: { md: "70px", xs: "30px" },
        }}
      >
        Services
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          pb: 5,
          pt: { md: 5, xs: 2 },
          width: { md: "89%", xs: "100%" },
          m: "auto",
        }}
      >
        {tools.map((items, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
              cursor: "pointer",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
              flexShrink: 0,
              width: { md: "201px", xs: "101px" },
              height: { md: "156px", xs: "70px" },
              padding: "20px",
              borderRadius: "10px",
              background: "#FFF",
              boxShadow: "0px 3px 10px -2px rgba(17, 24, 39, 0.10)",
              m: 1,
              transition: "transform 0.2s ease-in-out", // Smooth hover effect
              "&:hover": {
                transform: "scale(1.10)", // Scale up on hover
              },
            }}
            onClick={() => {
              navigate(items.navigate);
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                maxHeight: { md: "100%", xs: "50%" },
                maxWidth: { md: "100%", xs: "50%" },
              }}
            >
              <img src={items.icon} alt={items.name} height="30px" />
            </Box>
            <Box
              sx={{
                width: "60px",
                height: "2px",
                flexShrink: 0,
                background: "var(--neutral-100, #E0E3EB)",
              }}
            ></Box>
            <Box
              sx={{
                color: "var(--Text-Color-Grey-Light, #555656)",
                textAlign: "center",
                fontFamily: "Mulish, sans-serif",
                fontSize: { md: "16px", xs: "10px" },
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                mt: { md: 0, xs: -1.5 },
                pb: { xs: "10px" },
              }}
            >
              {items.name}
            </Box>
          </Box>
        ))}
      </Box>
      {/* <LeadModal
        initialValue={modal}
        handleModal={hanldeChangeModal}
        leadSource={leadSource}
        propertyId=""
      /> */}
    </>
  );
};

export default Service;

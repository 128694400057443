import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { apiList, invokeFormDataApi } from "../../../../../apis/apiServices";
import { config } from "../../../../../config/config";
import { useCookies } from "react-cookie";
import "./App.css";
function ImageUploader({ next, back, formData, updateFormData, detailsId }) {
  const [cookies] = useCookies();
  const [featuredImageUrl, setfeaturedImageUrl] = useState(
    formData.featuredImageUrl
  );
  const [galleryImageUrl, setgalleryImageUrl] = useState(
    formData.galleryImageUrl
  );
  const [allImages, setAllImages] = useState(formData.allImages);

  const [loading, setloading] = useState(false);

  const [videoUrl, setVideoUrl] = useState(formData.videoUrl);
  const [videoLink, setvideoLink] = useState(formData.videoLink);
  const [videoPreview, setVideoPreview] = useState(formData.videoPreview);
  const [uploadedVideo, setuploadedVideo] = useState(formData.uploadedVideo);
  const [showUploadButton, setshowUploadButton] = useState(
    formData.showUploadButton
  );
  const [successMessage, setsuccessMessage] = useState(false);
  const [showVideoUploadAlertcolor, setshowVideoUploadAlertcolor] =
    useState("black");
  const [videoDisplay, setvideoDisplay] = useState();

  const data6 = {
    allImages,
    featuredImageUrl,
    galleryImageUrl,
    videoUrl,
    videoLink,
    videoPreview,
    showUploadButton,
  };
  const imageComressor = async (imageFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const image = new Image();
        image.src = event.target.result;
        image.onload = () => {
          const canvas = document.createElement("canvas");
          const MAX_SIZE = 250 * 250;
          let width = image.width;
          let height = image.height;

          while (width * height > MAX_SIZE) {
            width *= 0.9;
            height *= 0.9;
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(image, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              const compressedFile = new File([blob], imageFile.name, {
                type: "image/jpeg",
                lastModified: Date.now(),
              });
              resolve(compressedFile);
            },
            "image/jpeg",
            0.8
          );
        };
      };
      reader.readAsDataURL(imageFile);
    });
  };

  const uploadImageGetPath = async (file, type) => {
    setloading(true);
    try {
      let propertyImages = new FormData();
      propertyImages.append("file", file);
      propertyImages.append("path", "property_gallery");

      let response = await invokeFormDataApi(
        config.apiDomains + apiList.uploadFile,
        propertyImages,
        cookies
      );

      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          if (type === "Single Image") {
            setfeaturedImageUrl([
              {
                id: formData.featuredImageId,
                imagePath: response.data.url,
                imageType: "featured",
                status: "Active",
              },
            ]);
            setloading(false);
          } else if (type === "Multiple Images") {
            setgalleryImageUrl((prevImages) => [
              ...prevImages,
              {
                imagePath: response.data.url,
                imageType: "gallery",
              },
            ]);
            setloading(false);
          }
        } else {
          return { success: false };
        }
      } else {
        return { success: false };
      }
    } catch (error) {
      console.error("Error while uploading college logo:", error);
      return { success: false };
    }
    setloading(false);
  };

  useEffect(() => {
    const combinedImages = featuredImageUrl.concat(galleryImageUrl);
    setAllImages(combinedImages);
  }, [featuredImageUrl, galleryImageUrl]);

  const handleFeaturedImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      uploadImageGetPath(file, "Single Image");
    }
  };

  const handleGalleryImages = async (e) => {
    const files = Array.from(e.target.files);

    // Iterate through each file
    for (const file of files) {
      const compressedFile = await imageComressor(file);
      await uploadImageGetPath(compressedFile, "Multiple Images");
    }
  };

  const handleDeleteImage = (index) => {
    const updatedSelectedImages = [...galleryImageUrl];

    if (index >= 0 && index <= updatedSelectedImages.length) {
      const previousImage = updatedSelectedImages[index - 1];
      if (previousImage && "status" in previousImage) {
        previousImage.status = "Deleted";
      } else if (
        featuredImageUrl.length === 0 &&
        !updatedSelectedImages.every((image) => "status" in image)
      ) {
        updatedSelectedImages.splice(index, 1);
      } else if (
        featuredImageUrl.length !== 0 &&
        !updatedSelectedImages.every((image) => "status" in image)
      ) {
        updatedSelectedImages.splice(index - 1, 1);
      }

      setgalleryImageUrl(updatedSelectedImages);
    }
  };

  const checkFileSize = (file) => {
    const maxSize = 25 * 1024 * 1024; // 25MB
    return file.size <= maxSize;
  };

  const handleVideoUpload = async (e) => {
    const file = e.target.files[0];

    if (file && checkFileSize(file)) {
      // Create local preview URL for display
      setVideoPreview(file);
      setshowUploadButton(true);
      setsuccessMessage(false);
      setVideoUrl(null);
    } else {
      // Clear the input value when file size check fails
      e.target.value = null;
      alert("Video file size exceeds the maximum limit of 25MB.");
    }
  };

  const videoUpload = async () => {
    setshowVideoUploadAlertcolor("black");
    if (videoPreview) {
      setloading(true);

      try {
        let videoFormData = new FormData();
        videoFormData.append("file", videoPreview);
        videoFormData.append("path", "videos");

        let response = await invokeFormDataApi(
          config.apiDomains + apiList.uploadVideo,
          videoFormData,
          cookies
        );

        if (response.status >= 200 && response.status < 300) {
          if (response.data.responseCode === "200") {
            setVideoUrl(response.data.url);
            setshowUploadButton(false);
            setsuccessMessage(true);
          } else {
            console.error("Error uploading video:", response.data.message);
          }
        } else {
          console.error("Error uploading video. HTTP Status:", response.status);
        }
      } catch (error) {
        console.error("Error uploading video:", error);
      } finally {
        setloading(false);
      }
    }
  };

  useEffect(() => {
    if (uploadedVideo && videoPreview)
      setvideoDisplay(URL.createObjectURL(videoPreview));
    else if (uploadedVideo && !videoPreview) setvideoDisplay(uploadedVideo);
    else setvideoDisplay(videoPreview ? URL.createObjectURL(videoPreview) : "");
  }, [videoPreview, uploadedVideo]);

  const send = () => {
    updateFormData(data6);
  };
  return (
    <>
      <Box>
        {/* <Typography variant="h5" sx={{ marginBottom: 2, mt: 5, ml: { xs: 1 } }}>
          <b>
            <span>Image Gallery</span>
          </b>
        </Typography> */}
        <Typography sx={{ fontSize: "18px", fontWeight: 600, mb: 2, ml: 1 }}>
          Featured Image
        </Typography>
        <Box sx={{ ml: 1 }}>
          <input type="file" accept="image/*" onChange={handleFeaturedImage} />
          {featuredImageUrl[0] && (
            <img
              style={{ width: 300, height: 300 }}
              src={featuredImageUrl[0].imagePath}
              alt="Cropped Image"
            />
          )}
        </Box>
        <Typography
          sx={{ fontSize: "18px", fontWeight: 600, mt: 2, mb: 2, ml: 1 }}
        >
          {" "}
          Gallery Images
        </Typography>
        <Box sx={{ ml: 1 }}>
          <input
            type="file"
            accept="image/*"
            multiple
            onChange={handleGalleryImages}
          />
        </Box>

        <Box
          className="cropped-images"
          sx={{ display: "flex", flexWrap: "wrap" }}
        >
          {allImages.map((image, index) => {
            if (
              image.imageType === "gallery" &&
              (image.status === "Active" || !image.status)
            ) {
              return (
                <Box
                  key={index}
                  className="image-container"
                  sx={{
                    position: "relative",
                    margin: "10px",
                    boxShadow: "0 0 3px black",
                  }}
                >
                  <DeleteIcon
                    onClick={() => handleDeleteImage(index)}
                    sx={{ position: "absolute", top: 0, right: 0 }}
                  />

                  <img
                    style={{ width: 200, height: 200 }}
                    src={image.imagePath}
                    alt={`Cropped Image ${index + 1}`}
                  />
                </Box>
              );
            }
            return null;
          })}
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography sx={{ fontSize: "18px", fontWeight: 600, ml: 1,mb:2  }}>
            Upload Video
          </Typography>
          <Grid container sx={{ mt: 1, ml: 2 }}>
            {(videoPreview || uploadedVideo) && (
              <Box
                className="image-container"
                sx={{
                  position: "relative",
                  margin: "10px",
                  boxShadow: "0 0 3px black",
                }}
              >
                <DeleteIcon
                  onClick={() => {
                    setVideoPreview(null);
                    setshowUploadButton(false);
                    setvideoDisplay(uploadedVideo);
                  }}
                  sx={{ position: "absolute", top: 0, right: 0 }}
                />
                <video
                  style={{ width: "350px", height: "auto" }}
                  controls
                  src={videoDisplay}
                  alt={`Uploaded Video `}
                />
              </Box>
            )}
          </Grid>

          {showUploadButton && (
            <Button
              variant="outlined"
              onClick={videoUpload}
              sx={{
                color: showVideoUploadAlertcolor,
                borderColor: showVideoUploadAlertcolor,
                mt: 1,
                ml: 2,
              }}
            >
              {detailsId ? "Update Video" : "Upload Video"}
            </Button>
          )}
          {successMessage && (
            <Typography sx={{ ml: 2 }}>Video Uploaded Successfuly</Typography>
          )}
          <Box sx={{ ml: 1 }}>
            <input
              type="file"
              accept="video/*"
              multiple={false} // Set to false to allow only a single file selection
              onChange={handleVideoUpload}
            />
          </Box>

          <TextField
            label="Youtube Link"
            id="demo-simple-select"
            sx={{ mt: 2.5, ml: 1 }}
            onChange={(event) => {
              setvideoLink(event.target.value);
            }}
            size="small"
            value={videoLink}
          />
        </Box>

        {!detailsId && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              pt: 2,
            }}
          >
            <Button
              color="inherit"
              onClick={() => {
                back();
                send();
              }}
            >
              Back
            </Button>
            <Button
              color="inherit"
              onClick={() => {
                if (videoPreview && !videoUrl) {
                  window.alert("Please Upload the Video");
                  setshowVideoUploadAlertcolor("red");
                } else {
                  next();
                  send();
                }
              }}
            >
              Next
            </Button>
          </Box>
        )}
        {detailsId && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              pt: 2,
            }}
          >
            <Button
              color="inherit"
              onClick={() => {
                back();
              }}
            >
              Back
            </Button>
            <Box>
              <Button
                color="inherit"
                onClick={() => {
                  send();
                  next();
                }}
              >
                update
              </Button>
              {/* <Button
                color="inherit"
                onClick={() => {
                  if (videoPreview && !videoUrl) {
                    window.alert("Please Upload the Video");
                    setshowVideoUploadAlertcolor("red");
                  } else {
                    next();
                  }
                }}
              >
                Next
              </Button> */}
            </Box>
          </Box>
        )}
      </Box>
      {loading && (
        <Box className="backdrop">
          <Box>
            <Typography variant="h6">Uploading...</Typography>
            <CircularProgress sx={{ color: "black", ml: 3.5, mt: 1 }} />
          </Box>
        </Box>
      )}
    </>
  );
}

export default ImageUploader;

export const floorOptions = Array.from({ length: 203 }, (_, index) => {
  if (index === 0) {
    return { value: index, label: "Basement 1" };
  } else if (index === 1) {
    return { value: index, label: "Basement 2" };
  } else if (index === 2) {
    return { value: index, label: "Ground Floor" };
  } else {
    // Remove leading zeros for labels greater than 2
    const label = String(index - 2);
    return { value: index, label: label === "0" ? "00" : label };
  }
});
export const floorOptionsres = Array.from({ length: 10 }, (_, index) => {
  if (index === 0) {
    return { value: index, label: "Ground Floor" };
  } else {
    // Remove leading zeros for labels greater than 0
    const label = String(index);
    return { value: index, label: label === "0" ? "00" : label };
  }
});
export const shopFloorOptions = Array.from({ length: 205 }, (_, index) => {
  if (index === 0) {
    return { value: index, label: "Basement 1" };
  } else if (index === 1) {
    return { value: index, label: "Basement 2" };
  } else if (index === 2) {
    return { value: index, label: "Lower Ground Floor" };
  } else if (index === 3) {
    return { value: index, label: "Ground Floor" };
  } else if (index === 4) {
    return { value: index, label: "Ground+Mezzanine" };
  } else if (index === 5) {
    return { value: index, label: "Mezzanine Floor" };
  } else {
    // Remove leading zeros for labels greater than 5
    const label = String(index - 5);
    return { value: index, label: label === "0" ? "00" : label };
  }
});

export const washroomount = Array.from({ length: 11 }, (_, index) => ({
  value: index,
  label:
    index === 0 ? "None" : String(index).padStart(2, "0").replace(/^0+/, ""),
}));

export const rooms = Array.from({ length: 990 }, (_, index) =>
  (index + 11).toString()
);

import React, { useEffect } from "react";
import Header from "../generic/Header";
import { Box, Grid, Typography } from "@mui/material";
import Footer from "../generic/Footer";

export default function PrivacyAndPolicy() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <Grid
        container
        sx={{
          width: "90%",
          boxShadow: "5px 15px 15px 15px  rgb(62 65 159 / 10%)",
          margin: "auto",
          p: { md: 5, xs: 1 },
          mb: 5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            margin: "auto",
          }}
        >
          <span
            style={{
              fontSize: "1.75rem",
              fontWeight: 600,
              fontFamily: "Jost, sans-serif",
            }}
          >
            Privacy & Policy
          </span>
        </Box>
        <Box>
          <Box sx={{ mt: { md: 6, xs: 5 }, mb: 0.5 }}>
            <Typography
              style={{
                fontSize: "24px",
                fontWeight: 500,
                color: "#262525",
                fontFamily: "Jost, sans-serif",
              }}
            >
              1. Introduction
            </Typography>
          </Box>
          <Box>
            <Typography
              style={{
                color: "#343434",
                fontFamily: "Jost, sans-serif",
                lineHeight: "25px",
                fontSize: "16px",
              }}
            >
              This privacy policy explains our policy regarding the collection,
              use, disclosure and transfer of your information by property
              broker, which operates various websites and other services
              including but not limited to delivery of information and content
              via any mobile or internet connected device or otherwise
              (collectively the "Services"). This privacy policy forms part and
              parcel of the Terms of Use for the Services. Capitalized terms
              which have been used here but are undefined shall have the same
              meaning as are attributed to them in the Terms of Use.
            </Typography>
          </Box>
          <Box sx={{ mt: 1.5 }}>
            <Typography
              style={{
                color: "#343434",
                fontFamily: "Jost, sans-serif",
                lineHeight: "25px",
                fontSize: "16px",
              }}
            >
              As we update, improve and expand the Services, this policy may
              change, so please refer back to it periodically. By accessing the
              website(s) including but not limited to this Application or
              otherwise using the Services, users consent to collection,
              storage, and use of the personal information you provide
              (including any changes thereto as provided by you) for any of the
              services that we offer.
            </Typography>
          </Box>
          <Box sx={{ mt: 2, mb: 0.5 }}>
            <Typography
              style={{
                fontSize: "23px",
                fontWeight: 500,
                color: "#262525",
                fontFamily: "Jost, sans-serif",
              }}
            >
              2. Information We Collect
            </Typography>
          </Box>
          <Box mb={1} mt={1}>
            <Typography
              style={{
                fontSize: "16px",
                fontWeight: 600,
                color: "#262525",
                fontFamily: "Jost, sans-serif",
              }}
            >
              (a) Personal Information:
            </Typography>
          </Box>
          <Box>
            <Typography
              style={{
                color: "#343434",
                fontFamily: "Jost, sans-serif",
                lineHeight: "25px",
                fontSize: "16px",
              }}
            >
              We may collect personal information that you provide to us
              voluntarily, such as your name, email address, phone number, and
              location.
            </Typography>
          </Box>
          <Box mb={1} mt={3}>
            <Typography
              style={{
                fontSize: "16px",
                fontWeight: 600,
                color: "#262525",
                fontFamily: "Jost, sans-serif",
              }}
            >
              (b) Usage Data:
            </Typography>
          </Box>
          <Box>
            <Typography
              style={{
                color: "#343434",
                fontFamily: "Jost, sans-serif",
                lineHeight: "25px",
                fontSize: "16px",
              }}
            >
              We automatically collect information about how you interact with
              our website, including your IP address, browser type, and pages
              visited.
            </Typography>
          </Box>
          <Box mb={1} mt={3}>
            <Typography
              style={{
                fontSize: "16px",
                fontWeight: 600,
                color: "#262525",
                fontFamily: "Jost, sans-serif",
              }}
            >
              (c) Cookies:
            </Typography>
          </Box>
          <Box>
            <Typography
              style={{
                color: "#343434",
                fontFamily: "Jost, sans-serif",
                lineHeight: "25px",
                fontSize: "16px",
              }}
            >
              We use cookies and similar tracking technologies to enhance your
              experience and collect data for analysis. You can manage your
              cookie preferences through your browser settings.
            </Typography>
          </Box>
          <Box sx={{ mt: 2, mb: 0.5 }}>
            <Typography
              style={{
                fontSize: "23px",
                fontWeight: 500,
                color: "#262525",
                fontFamily: "Jost, sans-serif",
              }}
            >
              3. How We Use Your Information
            </Typography>
          </Box>
          <Box>
            <Typography
              style={{
                color: "#343434",
                fontFamily: "Jost, sans-serif",
                lineHeight: "25px",
                fontSize: "16px",
              }}
            >
              We use your information for the following purposes:
            </Typography>
          </Box>
          <Box mb={1} mt={1}>
            <Typography
              style={{
                fontSize: "16px",
                fontWeight: 600,
                color: "#262525",
                fontFamily: "Jost, sans-serif",
              }}
            >
              (a) To Provide Services:
            </Typography>
          </Box>
          <Box>
            <Typography
              style={{
                color: "#343434",
                fontFamily: "Jost, sans-serif",
                lineHeight: "25px",
                fontSize: "16px",
              }}
            >
              To deliver the services you request, such as property listings,
              real estate information, and related services.{" "}
            </Typography>
          </Box>
          <Box mb={1} mt={3}>
            <Typography
              style={{
                fontSize: "16px",
                fontWeight: 600,
                color: "#262525",
                fontFamily: "Jost, sans-serif",
              }}
            >
              (b) Communication:{" "}
            </Typography>
          </Box>
          <Box>
            <Typography
              style={{
                color: "#343434",
                fontFamily: "Jost, sans-serif",
                lineHeight: "25px",
                fontSize: "16px",
              }}
            >
              To respond to your inquiries, send updates, and notify you of
              changes to our services or policies.
            </Typography>
          </Box>
          <Box mb={1} mt={3}>
            <Typography
              style={{
                fontSize: "16px",
                fontWeight: 600,
                color: "#262525",
                fontFamily: "Jost, sans-serif",
              }}
            >
              (c) Analytics:{" "}
            </Typography>
          </Box>
          <Box>
            <Typography
              style={{
                color: "#343434",
                fontFamily: "Jost, sans-serif",
                lineHeight: "25px",
                fontSize: "16px",
              }}
            >
              To analyze user behavior, improve our website, and customize your
              experience.
            </Typography>
          </Box>
          <Box sx={{ mt: 2, mb: 0.5 }}>
            <Typography
              style={{
                fontSize: "23px",
                fontWeight: 500,
                color: "#262525",
                fontFamily: "Jost, sans-serif",
              }}
            >
              4. Information Sharing
            </Typography>
          </Box>
          <Box>
            <Typography
              style={{
                color: "#343434",
                fontFamily: "Jost, sans-serif",
                lineHeight: "25px",
                fontSize: "16px",
              }}
            >
              We do not sell, trade, or otherwise transfer your personal
              information to third parties, except as described in this Privacy
              Policy or when required by law.
            </Typography>
          </Box>
          <Box sx={{ mt: 2, mb: 0.5 }}>
            <Typography
              style={{
                fontSize: "23px",
                fontWeight: 500,
                color: "#262525",
                fontFamily: "Jost, sans-serif",
              }}
            >
              5. Third-Party Links
            </Typography>
          </Box>
          <Box>
            <Typography
              style={{
                color: "#343434",
                fontFamily: "Jost, sans-serif",
                lineHeight: "25px",
                fontSize: "16px",
              }}
            >
              Our website may contain links to third-party websites. We are not
              responsible for their privacy practices and encourage you to read
              their privacy policies.
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Footer></Footer>
    </>
  );
}

export const config = {
  // apiDomains: "https://propbroker-np-api.zonalhub.com",
  // apiDomains: "http://api.propertybroker.in",
  // apiDomains: "http://192.168.104.:8080",
  // apiDomains: "http://192.168.1.5:8080",
  apiDomains: "https://api.tiesol.in",
  // apiDomains: "http://192.168.246.9:8080",

  googleMapsApiKey: "AIzaSyCp3v3ox9JyxXT_IDBnjfNFMgmPfxyQAZo",
  defaultMapLocation: {
    latitude: 12.9038631,
    longitude: 77.5855457,
  },
  cookieName: "prop_user",
  sessionCookie: "prop_sess",
  preferencesCookie: "prop_pref",
};

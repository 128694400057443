import React, { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import { useSelector } from "react-redux";
import DashboardIcon from "@mui/icons-material/Dashboard";
import HomeWorkIcon from "@mui/icons-material/HomeWork";

const openedMixin = (theme) => ({
  width: 240,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: 240,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DashBoardNavbar = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const globalState = useSelector((state) => state.user);
  const { userData, userError } = globalState;

  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleTabChange = (path) => {
    navigate(path);
  };

  const currentPath = window.location.pathname;
  const userRole = userData?.user?.roles || [];

  const getMenuItemsBasedOnRole = (role) => {
    return [
      // {
      //   text: "Dashboard",
      //   icon:
      //     currentPath === "/dashboard" ? (
      //       <img
      //         src="/media/svg/active-dashboard.svg"
      //         width="18.508px"
      //         height="18.508px"
      //         alt="active"
      //       />
      //     ) : (
      //       <DashboardIcon />
      //     ),
      //   path: "/dashboard",
      // },
      // eg
      {
        text: "Property Listing",
        icon:
          currentPath === "/post-property" ? (
            // <img
            //   src="/media/svg/active-college.svg"
            //   width="23.491px"
            //   height="23.491px"
            //   alt="active"
            // />
            <HomeWorkIcon sx={{ color: "#405189" }} />
          ) : (
            <HomeWorkIcon />
          ),
        path: "/post-property",
      },
      {
        text: "Lead Manager",
        icon:
          currentPath === "/leads-list" ? (
            <img
              src="/media/svg/active-lead-manager.svg"
              width="20.045px"
              height="18.508px"
              alt="active"
            />
          ) : (
            <img
              src="/media/svg/lead-manager.svg"
              width="20.045px"
              height="18.508px"
              alt="lead Manager"
            />
          ),
        path: "/leads-list",
      },
      {
        text: "Employee Manager",
        icon:
          currentPath === "/user-add" ? (
            <img
              src="/media/svg/active-employee-manager.svg"
              width="24.165px"
              height="24.736px"
              alt="active"
            />
          ) : (
            <img
              src="/media/svg/employee-manager.svg"
              width="24.165px"
              height="24.736px"
              alt="employee manager"
            />
          ),
        path: "/user-add",
      },
      {
        text: "Package Manager",
        icon:
          currentPath === "/add-package" ? (
            <img
              src="/media/svg/active-blog.svg"
              width="17.583px"
              height="17.583px"
              alt="active"
            />
          ) : (
            <img
              src="/media/svg/blog.svg"
              width="17.583px"
              height="17.583px"
              alt="Blog"
            />
          ),
        path: "/add-package",
      },
      {
        text: "Buy Package",
        icon:
          currentPath === "/my-packages" ? (
            <img
              src="/media/svg/active-blog.svg"
              width="17.583px"
              height="17.583px"
              alt="active"
            />
          ) : (
            <img
              src="/media/svg/blog.svg"
              width="17.583px"
              height="17.583px"
              alt="Blog"
            />
          ),
        path: "/my-packages",
      },
      {
        text: "Create Lead",
        icon:
          currentPath === "/create-lead" ? (
            <img
              src="/media/svg/active-add-circle-svgrepo-com.svg"
              width="22.583px"
              height="22.583px"
              alt="active"
            />
          ) : (
            <img
              src="/media/svg/add-circle-svgrepo-com.svg"
              width="22.583px"
              height="22.583px"
              alt="Create Lead"
            />
          ),
        path: "/create-lead",
      },
      // Add more items as needed
    ].filter((item) => {
      switch (true) {
        case userRole.includes("Admin"):
          return item.text !== "Buy Package"; // Include all menu items for admin && Exclude "Buy Package" for Admin
        case userRole.includes("Owner"):
          return [
            // "Dashboard",
            "Lead Manager",
            "Property Listing",
            "Buy Package",
            "Create Lead",
          ].includes(item.text);
        case userRole.includes("Agent"):
          return [
            // "Dashboard",
            "Lead Manager",
            "Property Listing",
            "Buy Package",
            "Create Lead",
          ].includes(item.text);
        case userRole.includes("Developer"):
          return [
            // "Dashboard",
            "Lead Manager",
            "Property Listing",
            "Buy Package",
            "Create Lead",
          ].includes(item.text);
        case userRole.includes("Associate"):
          return [
            // "Dashboard",
            "Lead Manager",
            "Property Listing",
            "Create Lead",
          ].includes(item.text);
        case userRole.includes("Telecaller"):
          return [
            // "Dashboard",
            "Lead Manager",
            "Property Listing",
            "Create Lead",
          ].includes(item.text);
        case userRole.includes("Team Leader"):
          return [
            // "Dashboard",
            "Lead Manager",
            "Property Listing",
            "Create Lead",
          ].includes(item.text);
        default:
          return false; // Default case: exclude all items for unknown userRole
      }
    });
  };

  const menuItems = getMenuItemsBasedOnRole(userRole);

  return (
    <>
      <CssBaseline />
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          {theme.direction === "rtl" ? (
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          ) : (
            <>
              {open && <Typography variant="h5">Property Broker</Typography>}
              <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen}>
                {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </>
          )}
        </DrawerHeader>
        <Divider />
        <List>
          {menuItems.map((item, index) => (
            <ListItem key={index} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={() => handleTabChange(item.path)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.text}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          ))}
          {/* {menuItems.map((item, index) => ( */}
          {/* <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => navigate("/dashboard")}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src="/media/svg/active-dashboard.svg"
                  width="18.508px"
                  height="18.508px"
                  alt="active"
                  style={{ filter: "brightness(0) grayscale(100%)" }}
                />
              </ListItemIcon>
              <ListItemText
                primary={"Dashboard"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => navigate("/post-property")}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src="/media/svg/home-icon-silhouette-svgrepo-com.svg"
                  width="18.508px"
                  height="18.508px"
                  alt="active"
                  style={{ filter: "brightness(0) grayscale(100%)" }}
                />
              </ListItemIcon>
              <ListItemText
                primary={"Property Listing"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => navigate("/leads-list")}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src="/media/svg/active-lead-manager.svg"
                  width="18.508px"
                  height="18.508px"
                  alt="active"
                  style={{ filter: "brightness(0) grayscale(100%)" }}
                />
              </ListItemIcon>
              <ListItemText
                primary={"Lead Manager"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => navigate("/user-add")}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src="/media/svg/active-employee-manager.svg"
                  width="18.508px"
                  height="18.508px"
                  alt="active"
                  style={{ filter: "brightness(0) grayscale(100%)" }}
                />
              </ListItemIcon>
              <ListItemText
                primary={"Employee Manager"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => navigate("/add-package")}
            >
              <Inventory2Icon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src="/media/svg/active-employee-manager.svg"
                  width="18.508px"
                  height="18.508px"
                  alt="active"
                  style={{ filter: "brightness(0) grayscale(100%)" }}
                />
              </Inventory2Icon>
              <ListItemText
                primary={"Property Package"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem> */}
          {/* ))} */}
        </List>
      </Drawer>
    </>
  );
};

export default DashBoardNavbar;

import React, { useState, useEffect, createRef, memo } from "react";
import {
  CircularProgress,
  Typography,
  Grid,
  Button,
  Box,
  Container,
  Card,
  Divider,
  List,
  ListItem,
  Popover,
} from "@mui/material";
import { useCookies } from "react-cookie";
import { apiList, invokeApi } from "../../apis/apiServices";
import { config } from "../../config/config";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Header from "../generic/Header";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Slider from "react-slick";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import styled from "styled-components";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import Footer from "../generic/Footer";
import { LoadingButton } from "@mui/lab";
import "./style.css";
import {
  buyBudgets,
  rentBudgets,
  propertyTypes,
  bhkList,
  amenityList,
  furnish,
  facingList,
  positionstatus,
  sizes,
} from "../../common/common";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from "@mui/icons-material/Call";
import LeadModal from "./LeadModal";
import { Link } from "react-router-dom";
import { PriceFormatter, users } from "../../common/common";
import { Bounce, toast } from "react-toastify";
import OverViewDetailsLeadForm from "./PropertyDetails/components/OverViewDetailsLeadForm";
import Breadcrumbs from "@mui/material/Breadcrumbs";

const StyledInput = styled.input`
  width: 310px;
  border: none;
  height: 30px;
  padding-left: 10px;
  font-size: 12px;
  outline: none;
  @media (max-width: 600px) {
    width: 170px;
    margin-left: 2px;
    font-size: 12px;
    height: 15px;
  }
`;

const PropertyList = memo(() => {
  const [cookies, setCookies] = useCookies();
  const [searchText, setSearchText] = useState(null);
  const [propertyId, setPropertyId] = useState(null);
  const [leadModal, setLeadModal] = useState(false);
  const [leadSource, setLeadSource] = useState("");
  const handeChangeModal = () => setLeadModal((prevState) => !prevState);

  const [fetching, setFetching] = useState(true);

  const [propertiesList, setPropertiesList] = useState([]);
  const [rentAnchor, setrentAnchor] = useState(null);
  const [bhkAnchor, setbhkAnchor] = useState(null);
  const [facingAnchor, setfacingAnchor] = useState(null);
  const [budgetAnchor, setbudgetAnchor] = useState(null);
  const [sizeAnchor, setsizeAnchor] = useState(null);
  const [propertyTypeAnchor, setpropertyTypeAnchor] = useState(null);
  const [
    furnishedAndPossessionMenuAnchor,
    setFurnishedAndPossessionMenuAnchor,
  ] = useState(null);
  const [possessionStatus, setPossessionStatus] = useState(null);
  const [postedOnAnchor, setpostedOnAnchor] = useState(null);
  const [amenitiesAnchor, setAmenitiesAnchor] = useState(null);
  const [minBudget, setminBudget] = useState();
  const [minPropertySize, setminPropertySize] = useState();
  const [postedOn, setPostedOn] = useState(
    cookies[config.preferencesCookie]?.postedOn || null
  );
  const [amenity, setAmenity] = useState(
    cookies[config.preferencesCookie]?.amenity || null
  );
  const [bhk, setBhk] = useState(cookies[config.preferencesCookie]?.bhk || "");
  const [furnishedType, setFurnishedType] = useState(
    cookies[config.preferencesCookie]?.furnishType || ""
  );
  const [facing, setFacing] = useState(
    cookies[config.preferencesCookie]?.facing || ""
  );

  const [minPrice, setMinPrice] = useState(
    cookies[config.preferencesCookie]?.minPrice
  );
  const [maxPrice, setMaxPrice] = useState(
    cookies[config.preferencesCookie]?.maxPrice
  );
  const [minSize, setMinSize] = useState(
    cookies[config.preferencesCookie]?.minSize || null
  );
  const [maxSize, setMaxSize] = useState(
    cookies[config.preferencesCookie]?.maxSize || null
  );
  const [propertyListingType, setPropertyListingType] = useState(
    cookies[config.preferencesCookie]?.propertyType || "Rent"
  );
  const [buildingType, setbuildingType] = useState(
    cookies[config.preferencesCookie]?.buildingType || null
  );
  const [activePropertyType, setActivePropertyType] = useState(
    cookies[config.preferencesCookie]?.propertyType || "Buy"
  );
  const [propertySubType, setPropertySubType] = useState(
    cookies[config.preferencesCookie]?.subPropertyType || null
  );

  const handleMenuClick = (event, anchorSetter) => {
    anchorSetter(event.currentTarget);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //Property Listings
  useEffect(() => {
    const allProperties = async () => {
      let params = {
        listingType: activePropertyType === "Buy" ? "Sale" : activePropertyType,
        buildingType: buildingType,
        propertyType: propertySubType,
        minRent: minPrice,
        maxRent: maxPrice,
        minSuperBuiltupArea: minSize,
        maxSuperBuiltupArea: maxSize,
        positionStatus: possessionStatus,
        searchText: searchText,
        city: cookies[config.preferencesCookie]?.city || "Bangalore",
        numOfBedrooms: cookies[config.preferencesCookie]?.bhk || null,
        postedOn: cookies[config.preferencesCookie]?.postedOn || null,
        furnishingType: cookies[config.preferencesCookie]?.furnishType || null,
        vastuFacing: cookies[config.preferencesCookie]?.facing || null,
        amenity: cookies[config.preferencesCookie]?.amenity || null,
      };
      const response = await invokeApi(
        config.apiDomains + apiList.propertyByFilter,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setPropertiesList(response.data.properties);
          setFetching(false);
        } else {
          toast.error(
            "An error occurred while fetching data Please Try Again!",
            {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            }
          );
        }
      } else {
        toast.error("An error occurred while fetching data Please Try Again!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
    };
    if (fetching) {
      allProperties();
    }
  }, [cookies, fetching]);

  const budgets =
    activePropertyType === "Buy"
      ? buyBudgets
      : activePropertyType === "Rent"
      ? rentBudgets
      : activePropertyType === "Lease"
      ? buyBudgets
      : [];

  function handleClearAllFilter() {
    setCookies(
      config.preferencesCookie,
      JSON.stringify({
        ...cookies[config.preferencesCookie],
        propertyType: propertyTypes[0].buildingType,
        buildingType: null,
        subPropertyType: null,
        minPrice: null,
        maxPrice: null,
        minSize: null,
        maxSize: null,
        country: "India",
        postedOn: null,
        amenity: null,
        furnishType: null,
        facing: null,
        bhk: null,
        searchText: null,
      }),
      { path: "/", maxAge: 3000000, sameSite: "strict" }
    );
    window.location.reload();
  }

  return (
    <>
      <Header />
      {fetching ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100svh",
            }}
          >
            <CircularProgress sx={{ color: "black" }} />
            <Typography>Loading the data, please wait...</Typography>
          </Box>
        </>
      ) : (
        <>
          {/* filter section start from below  */}
          <Box
            sx={{
              width: "100%",
              background: "var(--BG-Color-4, #F8F8F8)",
              boxShadow: "0px 2.4px 4.8px 0px rgba(0, 0, 0, 0.15)",
              position: "sticky",
              zIndex: 999,
              pt: 2,
              top: "64px",
            }}
          >
            <Container
              maxWidth="xl"
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                overflowX: "scroll",
                pb: 2,
              }}
              className="filter-sec-scrollbar"
            >
              <Box
                sx={{
                  display: "flex",
                  height: { md: "35px", xs: "35px" },
                  padding: "0px 8px",
                  alignItems: "center",
                  gap: { md: "5px", xs: "2px" },
                  borderRadius: "5px",
                  border: "0.2px solid var(--BG-Color-6, #7C7C7C)",
                  background: "#FFF",
                }}
              >
                <Box
                  component={"img"}
                  src="/media/images/rentIcon.svg"
                  width="15px"
                  onClick={(event) => handleMenuClick(event, setrentAnchor)}
                />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "Mulish, sans-serif",
                    fontSize: { md: "18px", xs: "13px" },
                    cursor: "pointer",
                  }}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={(event) => handleMenuClick(event, setrentAnchor)}
                >
                  <Typography sx={{ fontSize: "15px" }}>
                    {propertyListingType}
                  </Typography>
                  <KeyboardArrowDownIcon sx={{ fontSize: "15px" }} />
                </Box>
                {/* listing type dropdown below  */}
                <Popover
                  id="simple-menu"
                  anchorEl={rentAnchor}
                  open={Boolean(rentAnchor)}
                  onClose={() => setrentAnchor(null)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <MenuItem
                    sx={{ fontSize: { md: "15px", xs: "10px" } }}
                    onClick={() => {
                      setrentAnchor(null);
                      setPropertyListingType("Buy");
                      setActivePropertyType("Buy");
                      setCookies(
                        config.preferencesCookie,
                        JSON.stringify({
                          ...cookies[config.preferencesCookie],
                          propertyType: "Buy",
                        })
                      );
                      setFetching(true);
                    }}
                  >
                    Buy
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    sx={{ fontSize: { md: "15px", xs: "10px" } }}
                    onClick={() => {
                      setrentAnchor(null);
                      setActivePropertyType("Rent");
                      setPropertyListingType("Rent");
                      setCookies(
                        config.preferencesCookie,
                        JSON.stringify({
                          ...cookies[config.preferencesCookie],
                          propertyType: "Rent",
                        })
                      );
                      setFetching(true);
                    }}
                  >
                    Rent
                  </MenuItem>
                </Popover>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <SearchIcon
                    sx={{
                      color: "red",
                      fontSize: "18px",
                    }}
                  />
                  <StyledInput
                    onChange={(e) => setSearchText(e.target)}
                    placeholder="Search By City or Location or Project or Building Name"
                  />
                </Box>
              </Box>
              {/* below the filter related to property type  */}
              <Box
                sx={{
                  display: "flex",
                  height: { md: "35px", xs: "35px" },
                  padding: "0px 8px",
                  alignItems: "center",
                  gap: "8px",
                  borderRadius: "5px",
                  border: "0.2px solid var(--BG-Color-6, #7C7C7C)",
                  background: "#FFF",
                  maxWidth: "192px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "Mulish, sans-serif",
                    fontSize: { md: "18px", xs: "13px" },
                    whiteSpace: "nowrap",
                  }}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={(event) =>
                    setpropertyTypeAnchor(event.currentTarget)
                  }
                >
                  <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                    Property Type
                  </Typography>
                  <KeyboardArrowDownIcon sx={{ fontSize: "15px" }} />
                </Box>
                <Menu
                  id="simple-menu"
                  anchorEl={propertyTypeAnchor}
                  keepMounted
                  open={Boolean(propertyTypeAnchor)}
                  onClose={() => setpropertyTypeAnchor(null)}
                  sx={{ maxHeight: "400px" }}
                >
                  <Box
                    sx={{
                      padding: "5px 14px",
                    }}
                  >
                    {propertyTypes
                      ?.find((item) => item.buildingType === activePropertyType)
                      .propertyType?.map((propertyTypeN) => (
                        <label
                          htmlFor={`radio-${propertyTypeN.propertyTypeName}`}
                          className="radio-listingType"
                        >
                          <input
                            id={`radio-${propertyTypeN.propertyTypeName}`}
                            type="radio"
                            name="radio1"
                            value={propertyTypeN.propertyTypeName || ""}
                            checked={
                              buildingType === propertyTypeN.propertyTypeName
                            }
                            onChange={() => {
                              setCookies(
                                config.preferencesCookie,
                                JSON.stringify({
                                  ...cookies[config.preferencesCookie],
                                  buildingType: propertyTypeN.propertyTypeName,
                                }),
                                {
                                  path: "/",
                                  maxAge: 3000000,
                                  sameSite: "strict",
                                }
                              );
                              setbuildingType(propertyTypeN.propertyTypeName);
                              setFetching(true);
                              setpropertyTypeAnchor(null);
                            }}
                            style={{ marginRight: "10px", accentColor: "red" }}
                          />
                          {propertyTypeN.propertyTypeName}
                        </label>
                      ))}
                  </Box>
                  <Divider />
                  <Box
                    sx={{
                      py: 1,
                      px: "7px",
                    }}
                  >
                    {buildingType && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column", // Align items horizontally
                          alignItems: "flex-start", // Center items vertically
                        }}
                      >
                        {propertyTypes
                          .find(
                            (item) => item.buildingType === activePropertyType
                          )
                          ?.propertyType?.find(
                            (propertyTypeN) =>
                              propertyTypeN.propertyTypeName === buildingType
                          )
                          ?.subPropertyType?.map((item, index, array) => (
                            <React.Fragment key={index}>
                              <label
                                htmlFor={`radio-${index}`}
                                className="sub-property-type"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  id={`radio-${index}`}
                                  type="checkbox"
                                  name="radio7"
                                  value={item || ""}
                                  onChange={(event) => {
                                    setCookies(
                                      config.preferencesCookie,
                                      JSON.stringify({
                                        ...cookies[config.preferencesCookie],
                                        subPropertyType: event.target.value,
                                      }),
                                      {
                                        path: "/",
                                        maxAge: 3000000,
                                        sameSite: "strict",
                                      }
                                    );
                                    setPropertySubType(event.target.value);
                                    setFetching(true);
                                    setpropertyTypeAnchor(null);
                                  }}
                                  checked={propertySubType === item}
                                  readOnly
                                  className="filter-sub-property-type-checkbox"
                                />
                                {item}
                              </label>
                              {index !== array?.length - 1 && (
                                <Divider
                                  sx={{ height: "1px", width: "100%" }}
                                />
                              )}
                            </React.Fragment>
                          ))}
                      </Box>
                    )}
                  </Box>
                </Menu>
              </Box>
              {/* below the filter related to bhk  */}
              <Box
                sx={{
                  display: "flex",
                  height: { md: "35px", xs: "35px" },
                  padding: "0px 8px",
                  alignItems: "center",
                  gap: "8px",
                  borderRadius: "5px",
                  border: "0.2px solid var(--BG-Color-6, #7C7C7C)",
                  background: "#FFF",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "Mulish, sans-serif",
                    fontSize: { md: "18px", xs: "13px" },
                  }}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={(event) => setbhkAnchor(event.currentTarget)}
                >
                  <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                    BHK
                  </Typography>
                  <KeyboardArrowDownIcon sx={{ fontSize: "15px" }} />
                </Box>
                <Menu
                  id="simple-menu"
                  anchorEl={bhkAnchor}
                  keepMounted
                  open={Boolean(bhkAnchor)}
                  onClose={() => setbhkAnchor(null)}
                  style={{
                    fontSize: { xs: "10px" },
                    maxHeight: "400px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column", // Align items horizontally
                      alignItems: "flex-start", // Center items vertically
                      padding: "7px 5px",
                    }}
                  >
                    {bhkList?.map((item, index, array) => (
                      <React.Fragment key={index}>
                        <label
                          htmlFor={`radio1-${index}`}
                          className="sub-property-type"
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <input
                            id={`radio1-${index}`}
                            type="checkbox"
                            name="radio7"
                            value={item || ""}
                            onChange={(event) => {
                              setCookies(
                                config.preferencesCookie,
                                JSON.stringify({
                                  ...cookies[config.preferencesCookie],
                                  bhk: event.target.value,
                                }),
                                {
                                  path: "/",
                                  maxAge: 3000000,
                                  sameSite: "strict",
                                }
                              );
                              setBhk(
                                event.target.checked ? event.target.value : ""
                              );
                              setFetching(true);
                              setbhkAnchor(null);
                            }}
                            checked={bhk === item}
                            className="filter-sub-property-type-checkbox"
                          />
                          {item}
                        </label>
                        {index !== array?.length - 1 && (
                          <Divider sx={{ height: "1px", width: "100%" }} />
                        )}
                      </React.Fragment>
                    ))}
                  </Box>
                </Menu>
              </Box>
              {/* below the filter related to budget  */}
              <Box
                sx={{
                  display: "flex",
                  height: { md: "35px", xs: "35px" },
                  padding: "0px 8px",
                  alignItems: "center",
                  gap: "8px",
                  borderRadius: "5px",
                  border: "0.2px solid var(--BG-Color-6, #7C7C7C)",
                  background: "#FFF",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "Mulish, sans-serif",
                    fontSize: { md: "18px", xs: "13px" },
                  }}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={(event) => setbudgetAnchor(event.currentTarget)}
                >
                  <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                    Budget
                  </Typography>
                  <KeyboardArrowDownIcon sx={{ fontSize: "15px" }} />
                </Box>
                <Menu
                  id="simple-menu"
                  anchorEl={budgetAnchor}
                  keepMounted
                  open={Boolean(budgetAnchor)}
                  onClose={() => setbudgetAnchor(null)}
                  sx={{
                    fontSize: { xs: "10px" },
                    maxHeight: "400px",
                  }}
                >
                  <Box style={{ fontSize: { xs: "10px" }, width: "100%" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ width: "50%", textAlign: "center", m: 2 }}>
                        <input
                          placeholder="Min"
                          value={minPrice || ""}
                          style={{
                            width: "100px",
                            padding: "10px",
                            borderRadius: 6,
                            backgroundColor: "#f1f1ff",
                            border: "none",
                          }}
                          onClick={(e) => {
                            setCookies(
                              config.preferencesCookie,
                              JSON.stringify({
                                ...cookies[config.preferencesCookie],
                                minPrice: e.target.value,
                              }),
                              {
                                path: "/",
                                maxAge: 3000000,
                                sameSite: "strict",
                              }
                            );
                            setMinPrice(e.target.value);
                            setminBudget(0);
                          }}
                        />
                      </Box>
                      -
                      <Box sx={{ width: "50%", textAlign: "center", m: 2 }}>
                        <input
                          placeholder="Max"
                          value={maxPrice || ""}
                          style={{
                            width: "100px",
                            padding: "10px",
                            borderRadius: 6,
                            backgroundColor: "#f1f1ff",
                            border: "none",
                          }}
                          onClick={(e) => {
                            setCookies(
                              config.preferencesCookie,
                              JSON.stringify({
                                ...cookies[config.preferencesCookie],
                                maxPrice: e.target.value,
                              }),
                              {
                                path: "/",
                                maxAge: 3000000,
                                sameSite: "strict",
                              }
                            );
                            setMaxPrice(e.target.value);
                            setminBudget(1);
                            // setFetching(true);
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <List
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      cursor: "pointer",
                    }}
                  >
                    {budgets
                      .filter((item, index) => {
                        if (minPrice && minBudget === 1) {
                          return parseFloat(item.value) > parseFloat(minPrice);
                        } else if (maxPrice && minBudget === 0) {
                          return parseFloat(item.value) < parseFloat(maxPrice);
                        } else {
                          return item.value;
                        }
                      })
                      ?.map((item, index) => (
                        <ListItem
                          key={index}
                          sx={{
                            fontSize: { xs: "10px", md: "15px" },
                            justifyContent:
                              minBudget === 1 ? "flex-end" : "flex-start",
                          }}
                          onClick={() => {
                            if (minBudget === 1) {
                              setCookies(
                                config.preferencesCookie,
                                JSON.stringify({
                                  ...cookies[config.preferencesCookie],
                                  maxPrice: item.value,
                                }),
                                {
                                  path: "/",
                                  maxAge: 3000000,
                                  sameSite: "strict",
                                }
                              );
                              setMaxPrice(item.value);
                            } else {
                              setCookies(
                                config.preferencesCookie,
                                JSON.stringify({
                                  ...cookies[config.preferencesCookie],
                                  minPrice: item.value,
                                }),
                                {
                                  path: "/",
                                  maxAge: 3000000,
                                  sameSite: "strict",
                                }
                              );
                              setMinPrice(item.value);
                            }
                            if (minPrice) {
                              setbudgetAnchor(null);
                              setFetching(true);
                            }
                          }}
                        >
                          ₹ {item.label}
                        </ListItem>
                      ))}
                  </List>
                </Menu>
              </Box>
              {/* below the filter related to Size  */}
              <Box
                sx={{
                  display: "flex",
                  height: { md: "35px", xs: "35px" },
                  padding: "0px 8px",
                  alignItems: "center",
                  gap: "8px",
                  borderRadius: "5px",
                  border: "0.2px solid var(--BG-Color-6, #7C7C7C)",
                  background: "#FFF",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "Mulish, sans-serif",
                    fontSize: { md: "18px", xs: "13px" },
                  }}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={(event) => setsizeAnchor(event.currentTarget)}
                >
                  <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                    Size
                  </Typography>
                  <KeyboardArrowDownIcon sx={{ fontSize: "15px" }} />
                </Box>
                <Menu
                  id="simple-menu"
                  anchorEl={sizeAnchor}
                  keepMounted
                  open={Boolean(sizeAnchor)}
                  onClose={() => setsizeAnchor(null)}
                  sx={{
                    fontSize: { xs: "10px" },
                    maxHeight: "400px",
                  }}
                >
                  <Box style={{ fontSize: { xs: "10px" }, width: "100%" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ width: "50%", textAlign: "center", m: 2 }}>
                        <input
                          placeholder="Min"
                          value={minSize || ""}
                          style={{
                            width: "100px",
                            padding: "10px",
                            borderRadius: 6,
                            backgroundColor: "#f1f1ff",
                            border: "none",
                          }}
                          onClick={(e) => {
                            setCookies(
                              config.preferencesCookie,
                              JSON.stringify({
                                ...cookies[config.preferencesCookie],
                                minSize: e.target.value,
                              }),
                              {
                                path: "/",
                                maxAge: 3000000,
                                sameSite: "strict",
                              }
                            );
                            setMinSize(e.target.value);
                            setminPropertySize(0);
                          }}
                        />
                      </Box>
                      -
                      <Box sx={{ width: "50%", textAlign: "center", m: 2 }}>
                        <input
                          placeholder="Max"
                          value={maxSize || ""}
                          style={{
                            width: "100px",
                            padding: "10px",
                            borderRadius: 6,
                            backgroundColor: "#f1f1ff",
                            border: "none",
                          }}
                          onClick={(e) => {
                            setCookies(
                              config.preferencesCookie,
                              JSON.stringify({
                                ...cookies[config.preferencesCookie],
                                maxSize: e.target.value,
                              }),
                              {
                                path: "/",
                                maxAge: 3000000,
                                sameSite: "strict",
                              }
                            );
                            setMaxSize(e.target.value);
                            setminPropertySize(1);
                            // setFetching(true);
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <List
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      cursor: "pointer",
                    }}
                  >
                    {sizes
                      .filter((item, index) => {
                        if (minSize && minPropertySize === 1) {
                          return parseFloat(item.value) > parseFloat(minSize);
                        } else if (maxSize && minPropertySize === 0) {
                          return parseFloat(item.value) < parseFloat(maxSize);
                        } else {
                          return item.value;
                        }
                      })
                      ?.map((item, index) => (
                        <ListItem
                          key={index}
                          sx={{
                            fontSize: { xs: "15px", md: "15px" },
                            justifyContent:
                              minPropertySize === 1 ? "flex-end" : "flex-start",
                          }}
                          onClick={() => {
                            if (minPropertySize === 1) {
                              setCookies(
                                config.preferencesCookie,
                                JSON.stringify({
                                  ...cookies[config.preferencesCookie],
                                  maxSize: item.value,
                                }),
                                {
                                  path: "/",
                                  maxAge: 3000000,
                                  sameSite: "strict",
                                }
                              );
                              setMaxSize(item.value);
                            } else {
                              setCookies(
                                config.preferencesCookie,
                                JSON.stringify({
                                  ...cookies[config.preferencesCookie],
                                  minSize: item.value,
                                }),
                                {
                                  path: "/",
                                  maxAge: 3000000,
                                  sameSite: "strict",
                                }
                              );
                              setMinSize(item.value);
                            }
                            if (minSize) {
                              setsizeAnchor(null);
                              setFetching(true);
                            }
                          }}
                        >
                          ₹ {item.label}
                        </ListItem>
                      ))}
                  </List>
                </Menu>
              </Box>
              {/* below the filter related on posted on  */}
              <Box
                sx={{
                  display: "flex",
                  height: { md: "35px", xs: "35px" },
                  padding: "0px 8px",
                  alignItems: "center",
                  gap: "8px",
                  borderRadius: "5px",
                  border: "0.2px solid var(--BG-Color-6, #7C7C7C)",
                  background: "#FFF",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "Mulish, sans-serif",
                    fontSize: { md: "18px", xs: "13px" },
                    whiteSpace: "nowrap",
                  }}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={(event) => setpostedOnAnchor(event.currentTarget)}
                >
                  <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                    Posted on
                  </Typography>
                  <KeyboardArrowDownIcon sx={{ fontSize: "15px" }} />
                </Box>
                <Menu
                  id="simple-menu"
                  anchorEl={postedOnAnchor}
                  keepMounted
                  open={Boolean(postedOnAnchor)}
                  onClose={() => setpostedOnAnchor(null)}
                  style={{ fontSize: { xs: "10px" }, maxHeight: "400px" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column", // Align items horizontally
                      alignItems: "flex-start", // Center items vertically
                      padding: "7px 5px",
                    }}
                  >
                    {users?.map((item, index, array) => (
                      <React.Fragment key={index}>
                        <label
                          htmlFor={`radio2-${index}`}
                          className="sub-property-type"
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <input
                            id={`radio2-${index}`}
                            type="checkbox"
                            name="radio7"
                            value={item || ""}
                            onChange={(event) => {
                              setCookies(
                                config.preferencesCookie,
                                JSON.stringify({
                                  ...cookies[config.preferencesCookie],
                                  postedOn: event.target.value,
                                }),
                                {
                                  path: "/",
                                  maxAge: 3000000,
                                  sameSite: "strict",
                                }
                              );
                              setPostedOn(
                                event.target.checked ? event.target.value : ""
                              );
                              setpostedOnAnchor(null);
                              setFetching(true);
                            }}
                            checked={postedOn === item}
                            className="filter-sub-property-type-checkbox"
                          />
                          {item}
                        </label>
                        {index !== array?.length - 1 && (
                          <Divider sx={{ height: "1px", width: "100%" }} />
                        )}
                      </React.Fragment>
                    ))}
                  </Box>
                </Menu>
              </Box>

              {/* below the filter related on vastu facing  */}
              <Box
                sx={{
                  display: "flex",
                  height: { md: "35px", xs: "35px" },
                  padding: "0px 8px",
                  alignItems: "center",
                  gap: "8px",
                  borderRadius: "5px",
                  border: "0.2px solid var(--BG-Color-6, #7C7C7C)",
                  background: "#FFF",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "Mulish, sans-serif",
                    fontSize: { md: "18px", xs: "13px" },
                    whiteSpace: "nowrap",
                  }}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={(event) => setfacingAnchor(event.currentTarget)}
                >
                  <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                    Vastu Facing
                  </Typography>
                  <KeyboardArrowDownIcon sx={{ fontSize: "15px" }} />
                </Box>
                <Menu
                  id="simple-menu"
                  anchorEl={facingAnchor}
                  keepMounted
                  open={Boolean(facingAnchor)}
                  onClose={() => setfacingAnchor(null)}
                  style={{ fontSize: { xs: "10px" }, maxHeight: "400px" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column", // Align items horizontally
                      alignItems: "flex-start", // Center items vertically
                      padding: "7px 5px",
                    }}
                  >
                    {facingList?.map((item, index, array) => (
                      <React.Fragment key={index}>
                        <label
                          htmlFor={`radio4-${index}`}
                          className="sub-property-type"
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <input
                            id={`radio4-${index}`}
                            type="checkbox"
                            name="radio7"
                            value={item || ""}
                            onChange={(event) => {
                              setCookies(
                                config.preferencesCookie,
                                JSON.stringify({
                                  ...cookies[config.preferencesCookie],
                                  facing: event.target.value,
                                }),
                                {
                                  path: "/",
                                  maxAge: 3000000,
                                  sameSite: "strict",
                                }
                              );
                              setFacing(
                                event.target.checked ? event.target.value : ""
                              );
                              setFetching(true);
                              setfacingAnchor(null);
                            }}
                            checked={facing === item}
                            className="filter-sub-property-type-checkbox"
                          />
                          {item}
                        </label>
                        {index !== array?.length - 1 && (
                          <Divider sx={{ height: "1px", width: "100%" }} />
                        )}
                      </React.Fragment>
                    ))}
                  </Box>
                </Menu>
              </Box>
              {/* below the filter related on amenities  */}
              <Box
                sx={{
                  display: "flex",
                  height: { md: "35px", xs: "35px" },
                  padding: "0px 8px",
                  alignItems: "center",
                  gap: "8px",
                  borderRadius: "5px",
                  border: "0.2px solid var(--BG-Color-6, #7C7C7C)",
                  background: "#FFF",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "Mulish, sans-serif",
                    fontSize: { md: "18px", xs: "13px" },
                    whiteSpace: "nowrap",
                  }}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={(event) => setAmenitiesAnchor(event.currentTarget)}
                >
                  <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                    Amenities
                  </Typography>
                  <KeyboardArrowDownIcon sx={{ fontSize: "15px" }} />
                </Box>
                <Menu
                  id="simple-menu"
                  anchorEl={amenitiesAnchor}
                  keepMounted
                  open={Boolean(amenitiesAnchor)}
                  onClose={() => setAmenitiesAnchor(null)}
                  style={{ fontSize: { xs: "10px" }, maxHeight: "400px" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column", // Align items horizontally
                      alignItems: "flex-start", // Center items vertically
                      padding: "7px 5px",
                    }}
                  >
                    {amenityList?.map((item, index, array) => (
                      <React.Fragment key={index}>
                        <label
                          htmlFor={`radio5-${index}`}
                          className="sub-property-type"
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <input
                            id={`radio5-${index}`}
                            type="checkbox"
                            name="radio7"
                            value={item || ""}
                            onChange={(event) => {
                              setCookies(
                                config.preferencesCookie,
                                JSON.stringify({
                                  ...cookies[config.preferencesCookie],
                                  amenity: event.target.value,
                                }),
                                {
                                  path: "/",
                                  maxAge: 3000000,
                                  sameSite: "strict",
                                }
                              );
                              setAmenity(
                                event.target.checked ? event.target.value : ""
                              );
                              setFetching(true);
                              setAmenitiesAnchor(null);
                            }}
                            checked={amenity === item}
                            className="filter-sub-property-type-checkbox"
                          />
                          {item}
                        </label>
                        {index !== array?.length - 1 && (
                          <Divider sx={{ height: "1px", width: "100%" }} />
                        )}
                      </React.Fragment>
                    ))}
                  </Box>
                </Menu>
              </Box>
              {/* below the filter related on furnished type and Possission Status  */}
              <Box
                sx={{
                  display: "flex",
                  height: { md: "35px", xs: "35px" },
                  padding: "0px 8px",
                  alignItems: "center",
                  gap: "8px",
                  borderRadius: "5px",
                  border: "0.2px solid var(--BG-Color-6, #7C7C7C)",
                  background: "#FFF",
                  maxWidth: "192px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontFamily: "Mulish, sans-serif",
                      fontSize: { md: "18px", xs: "13px" },
                      whiteSpace: "nowrap",
                    }}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={(event) =>
                      setFurnishedAndPossessionMenuAnchor(event.currentTarget)
                    }
                  >
                    <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                      More Filters
                    </Typography>
                    <KeyboardArrowDownIcon sx={{ fontSize: "15px" }} />
                  </Box>
                  <Menu
                    id="simple-menu"
                    anchorEl={furnishedAndPossessionMenuAnchor}
                    keepMounted
                    open={Boolean(furnishedAndPossessionMenuAnchor)}
                    onClose={() => setFurnishedAndPossessionMenuAnchor(null)}
                    sx={{ maxHeight: "400px" }}
                  >
                    <Box
                      sx={{
                        py: 1,
                        px: "7px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column", // Align items horizontally
                          alignItems: "flex-start", // Center items vertically
                        }}
                      >
                        <Typography
                          sx={{ fontSize: "16px", fontWeight: "bold" }}
                        >
                          Furnished Type:
                        </Typography>
                        <Divider sx={{ height: "1px", width: "100%" }} />

                        {/* Content for Furnished Type */}
                        {furnish?.map((item, index, array) => (
                          <React.Fragment key={index}>
                            <label
                              htmlFor={`radio3-${index}`}
                              className="sub-property-type"
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <input
                                id={`radio3-${index}`}
                                type="checkbox"
                                name="radio7"
                                value={item || ""}
                                onChange={(event) => {
                                  setCookies(
                                    config.preferencesCookie,
                                    JSON.stringify({
                                      ...cookies[config.preferencesCookie],
                                      furnishType: event.target.value,
                                    }),
                                    {
                                      path: "/",
                                      maxAge: 3000000,
                                      sameSite: "strict",
                                    }
                                  );
                                  setFurnishedType(
                                    event.target.checked
                                      ? event.target.value
                                      : ""
                                  );
                                  setFetching(true);
                                  setFurnishedAndPossessionMenuAnchor(null);
                                }}
                                checked={furnishedType === item}
                                className="filter-sub-property-type-checkbox"
                              />
                              {item}
                            </label>
                          </React.Fragment>
                        ))}
                        {/* Conditionally render Possession Status */}
                        {cookies[config.preferencesCookie]?.propertyType ===
                          "Buy" && (
                          <React.Fragment>
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                pt: 2,
                              }}
                            >
                              Possession Status:
                            </Typography>
                            <Divider sx={{ height: "1px", width: "100%" }} />

                            {positionstatus?.map((item, index, array) => (
                              <React.Fragment key={index}>
                                <label
                                  htmlFor={`radio-${index}`}
                                  className="sub-property-type"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <input
                                    id={`radio-${index}`}
                                    type="checkbox"
                                    name="radio8"
                                    value={item || ""}
                                    onChange={(event) => {
                                      setCookies(
                                        config.preferencesCookie,
                                        JSON.stringify({
                                          ...cookies[config.preferencesCookie],
                                          possessionStatus: event.target.value,
                                        }),
                                        {
                                          path: "/",
                                          maxAge: 3000000,
                                          sameSite: "strict",
                                        }
                                      );
                                      setPossessionStatus(event.target.value);
                                      setFetching(true);
                                      setFurnishedAndPossessionMenuAnchor(null);
                                    }}
                                    checked={possessionStatus === item}
                                    readOnly
                                    className="filter-sub-property-type-checkbox"
                                  />
                                  {item}
                                </label>
                              </React.Fragment>
                            ))}
                          </React.Fragment>
                        )}
                      </Box>
                    </Box>
                  </Menu>
                </Box>
              </Box>

              {/* below the clear all filtered data button  */}
              <Box
                sx={{
                  color: "#DB282F",
                  fontFamily: "Mulish, sans-serif",
                  fontSize: { md: "15px", xs: "15px" },
                  fontStyle: "normal",
                  fontWeight: 400,
                  display: "flex",
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                  alignItems: "center",
                }}
                onClick={() => {
                  handleClearAllFilter();
                  setFetching(true);
                }}
              >
                <RefreshIcon
                  sx={{ color: "#DB282F", mr: 1, fontSize: "17px" }}
                />{" "}
                Clear all
              </Box>
            </Container>
          </Box>

          {/* breadcrumbs */}
          <Grid sx={{ p: 2 }}>
            <div role="presentation">
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" component={Link} to="/">
                  home
                </Link>
                <Link underline="hover" color="inherit">
                  property-list
                </Link>
              </Breadcrumbs>
            </div>
          </Grid>

          {/* filter section end from end  */}
          <Grid container spacing={2}>
            {propertiesList?.length <= 0 ? (
              <Grid
                item
                xs={12}
                sx={{
                  height: "50vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  src="/media/svg/result-not-found.svg"
                  alt="result not found"
                  height="80px"
                  style={{ color: "#b2bec3" }}
                />
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: "900",
                    color: "#b2bec3",
                    fontSize: { xs: "2rem", md: "3rem" },
                  }}
                >
                  No Property Available
                </Typography>

                <Grid xs={12} item sx={{ textAlign: "center", mt: 3 }}>
                  <LoadingButton
                    size="medium"
                    loadingPosition="end"
                    variant="contained"
                    onClick={() => {
                      handleClearAllFilter();
                      setFetching(true);
                    }}
                  >
                    <RefreshIcon
                      sx={{ color: "ffffff", mr: 0.5, fontSize: "17px" }}
                    />
                    <span>Set Default</span>
                  </LoadingButton>
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid item md={8} xs={12}>
                  {propertiesList?.map((listings, index) => (
                    <Grid
                      container
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: { md: "row", xs: "column" },
                        flexWrap: "wrap",
                        boxShadow: "0px 2.4px 4.8px 0px rgba(0, 0, 0, 0.15)",
                        width: "100%",
                        mb: 4,
                        position: "relative",
                      }}
                    >
                      <Grid item xs={12} md={4}>
                        <Box sx={{ maxWidth: { md: "356px", xs: "100%" } }}>
                          {/* below the code is for proeprty image sliding start  */}
                          <Container
                            maxWidth={"xl"}
                            sx={{ position: "relative" }}
                          >
                            <Link
                              to={`/property-details/${listings.propertyId}`}
                            >
                              <Card sx={{ borderRadius: "10px" }}>
                                <Box
                                  component="img"
                                  src={listings.imageUrl}
                                  onError={(e) => {
                                    e.target.src =
                                      "/media/images/default-image.jpg"; // fallback to default image on error
                                  }}
                                  style={{
                                    width: "100%",
                                    height: "195px",
                                  }}
                                />
                              </Card>
                            </Link>
                          </Container>
                          {/* below the code is for proeprty image sliding end  */}
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{ ml: 2, mt: { md: 1, xs: 2 } }}
                      >
                        <Link
                          to={`/property-details/${listings.propertyId}`}
                          style={{ textDecoration: "none" }}
                        >
                          {/* below the typography have the title of the property like listingtype, property type */}
                          <Typography
                            sx={{
                              color: "var(--Text-Color-Grey-Bold, #333)",
                              fontFamily: "Mulish, sans-serif",
                              fontSize: { md: "18px", xs: "16px" },
                              fontStyle: "normal",
                              fontWeight: 600,
                              lineHeight: "normal",
                            }}
                          >
                            {listings?.furnishingType}{" "}
                            {listings?.numOfBedrooms !== "Studio"
                              ? listings?.numOfBedrooms + " BHK"
                              : listings?.numOfBedrooms === "Studio"
                              ? listings?.numOfBedrooms
                              : null}{" "}
                            {listings?.propertyType} for {listings?.listingType}{" "}
                            in{" "}
                            {
                              listings?.locality
                              //  + "," || " "
                            }
                            {/* {" " + listings?.city} */}
                          </Typography>
                          {/* below the box have the property or building name  */}
                          <Typography
                            sx={{
                              fontFamily: "Mulish, sans-serif",
                              color: "var(--BG-Color-5, #DB282F)",
                              fontSize: { md: "14px", xs: "13px" },
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "normal",
                              mt: "10px",
                            }}
                          >
                            {listings.propertyName}
                          </Typography>
                          <Box
                            sx={{
                              fontFamily: "Mulish, sans-serif",
                              color: "var(--Text-Color-Grey-Bold, #333)",
                              fontSize: { md: "14px", xs: "13.5px" },
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "normal",
                              mt: 0.5,
                            }}
                          >
                            {listings.propertyAddress
                              ? listings.propertyAddress + ", "
                              : null}
                            {listings.landmark
                              ? listings.landmark + ", "
                              : null}
                            {listings.city}
                            {listings.pincode ? " - " + listings.pincode : null}
                          </Box>
                          {/* below the Grid have the furnishing status, position status and area size and unit  */}
                          <Grid
                            container
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "nowrap",
                              mt: 1.5,
                            }}
                          >
                            {listings?.positionStatus && (
                              <Grid item xs={4} md={3}>
                                <Box
                                  sx={{
                                    fontFamily: "Mulish, sans-serif",
                                    color: "var(--Text-Color-Grey-Bold, #333)",
                                    fontSize: { md: "12px", xs: "10px" },
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    lineHeight: "normal",
                                    textWrap: "nowrap",
                                    paddingBottom: "3px",
                                  }}
                                >
                                  {listings?.positionStatus}
                                </Box>
                                <Box
                                  sx={{
                                    fontFamily: "Mulish, sans-serif",
                                    color: "#333333",
                                    fontSize: { md: "12px", xs: "10px" },
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "normal",
                                  }}
                                >
                                  Position Status
                                </Box>
                              </Grid>
                            )}
                            <Divider
                              orientation="vertical"
                              variant="middle"
                              flexItem
                              sx={{ mx: 2 }}
                            />

                            {listings?.furnishingType && (
                              <Grid item xs={4} md={3}>
                                <Box
                                  sx={{
                                    fontFamily: "Mulish, sans-serif",
                                    color: "var(--Text-Color-Grey-Bold, #333)",
                                    fontSize: { md: "12px", xs: "10px" },
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    lineHeight: "normal",
                                    paddingBottom: "3px",
                                  }}
                                >
                                  {listings?.furnishingType}
                                </Box>
                                <Box
                                  sx={{
                                    fontFamily: "Mulish, sans-serif",
                                    color: "#333333",
                                    fontSize: { md: "12px", xs: "10px" },
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "normal",
                                  }}
                                >
                                  Furnishing Status
                                </Box>
                              </Grid>
                            )}
                            <Divider
                              orientation="vertical"
                              variant="middle"
                              flexItem
                              sx={{ mx: 2 }}
                            />
                            <Grid item xs={4} md={3}>
                              {listings?.builtupPlotArea && (
                                <Box
                                  sx={{
                                    fontFamily: "Mulish, sans-serif",
                                    color: "var(--Text-Color-Grey-Bold, #333)",
                                    fontSize: { md: "12px", xs: "10px" },
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    lineHeight: "normal",
                                    paddingBottom: "3px",
                                  }}
                                >
                                  {listings.builtupPlotArea} {listings.areaUnit}
                                  <Box
                                    sx={{
                                      fontFamily: "Mulish, sans-serif",
                                      color: "#333",
                                      fontSize: { md: "12px", xs: "10px" },
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      lineHeight: "normal",
                                    }}
                                  >
                                    Built Up Area
                                  </Box>
                                </Box>
                              )}

                              {listings?.carpetArea &&
                                !listings?.builtupPlotArea && (
                                  <Box
                                    sx={{
                                      fontFamily: "Mulish, sans-serif",
                                      color:
                                        "var(--Text-Color-Grey-Bold, #333)",
                                      fontSize: { md: "12px", xs: "10px" },
                                      fontStyle: "normal",
                                      fontWeight: 600,
                                      lineHeight: "normal",
                                      paddingBottom: "3px",
                                    }}
                                  >
                                    {listings.carpetArea} {listings.areaUnit}
                                    <Box
                                      sx={{
                                        fontFamily: "Mulish, sans-serif",
                                        color: "#333",
                                        fontSize: { md: "12px", xs: "10px" },
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "normal",
                                      }}
                                    >
                                      Carpet Area
                                    </Box>
                                  </Box>
                                )}

                              {listings?.salebleArea &&
                                !listings?.builtupPlotArea &&
                                !listings?.carpetArea && (
                                  <Box
                                    sx={{
                                      fontFamily: "Mulish, sans-serif",
                                      color:
                                        "var(--Text-Color-Grey-Bold, #333)",
                                      fontSize: { md: "12px", xs: "10px" },
                                      fontStyle: "normal",
                                      fontWeight: 600,
                                      lineHeight: "normal",
                                      paddingBottom: "3px",
                                    }}
                                  >
                                    {listings.salebleArea} {listings.areaUnit}
                                    <Box
                                      sx={{
                                        fontFamily: "Mulish, sans-serif",
                                        color: "#333",
                                        fontSize: { md: "12px", xs: "10px" },
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "normal",
                                      }}
                                    >
                                      Saleable Area
                                    </Box>
                                  </Box>
                                )}

                              {listings?.superBuiltupArea &&
                                !listings?.builtupPlotArea &&
                                !listings?.carpetArea &&
                                !listings?.salebleArea && (
                                  <Box
                                    sx={{
                                      fontFamily: "Mulish, sans-serif",
                                      color:
                                        "var(--Text-Color-Grey-Bold, #333)",
                                      fontSize: { md: "12px", xs: "10px" },
                                      fontStyle: "normal",
                                      fontWeight: 600,
                                      lineHeight: "normal",
                                      paddingBottom: "3px",
                                    }}
                                  >
                                    {listings.superBuiltupArea}{" "}
                                    {listings.areaUnit}
                                    <Box
                                      sx={{
                                        fontFamily: "Mulish, sans-serif",
                                        color: "#333",
                                        fontSize: { md: "12px", xs: "10px" },
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "normal",
                                      }}
                                    >
                                      Super Built Up Area
                                    </Box>
                                  </Box>
                                )}

                              {listings?.plotArea &&
                                !listings?.builtupPlotArea &&
                                !listings?.carpetArea &&
                                !listings?.salebleArea &&
                                !listings?.superBuiltupArea && (
                                  <Box
                                    sx={{
                                      fontFamily: "Mulish, sans-serif",
                                      color:
                                        "var(--Text-Color-Grey-Bold, #333)",
                                      fontSize: { md: "12px", xs: "10px" },
                                      fontStyle: "normal",
                                      fontWeight: 600,
                                      lineHeight: "normal",
                                      paddingBottom: "3px",
                                    }}
                                  >
                                    {listings.plotArea} {listings.areaUnit}
                                    <Box
                                      sx={{
                                        fontFamily: "Mulish, sans-serif",
                                        color: "#333",
                                        fontSize: { md: "12px", xs: "10px" },
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "normal",
                                      }}
                                    >
                                      Plot Area
                                    </Box>
                                  </Box>
                                )}
                            </Grid>
                          </Grid>
                        </Link>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={1.5}
                        sx={{ mt: { md: 1, xs: 2 }, ml: 2 }}
                      >
                        <Box
                          sx={{
                            color: "var(--Text-Color-Grey-Bold, #333)",
                            fontFamily: "Mulish, sans-serif",
                            fontSize: { md: "18px", xs: "18px" },
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "normal",
                            display: "flex",
                          }}
                        >
                          {PriceFormatter(listings.rent)}
                        </Box>
                      </Grid>
                      <Grid container>
                        <Grid
                          item
                          md={12}
                          xs={12}
                          sx={{ ml: 1, mb: 1, mt: 0.8, py: 1 }}
                        >
                          <Box sx={{ display: "flex", flexDirection: "row" }}>
                            {/* <Box
                              component="img"
                              src="/media/images/Ellipse 11.png"
                              sx={{
                                width: { md: "52.294px", xs: "40px" },
                                height: { md: "52.294px", xs: "40px" },
                                borderRadius: "50%",
                                mr: 1.5,
                              }}
                            ></Box> */}
                            <Box sx={{ flexGrow: 1, m: "auto" }}>
                              <Box
                                sx={{
                                  color: "var(--Text-Color-Grey-Bold, #333)",
                                  fontFamily: "Mulish, sans-serif",
                                  fontSize: { md: "18px", xs: "15px" },
                                  fontStyle: "normal",
                                  fontWeight: 500,
                                  lineHeight: "normal",
                                }}
                              >
                                {listings?.username}
                              </Box>
                              <Box
                                sx={{
                                  color: "var(--Text-Color-Grey-Bold, #333)",
                                  fontFamily: "Mulish, sans-serif",
                                  fontSize: { md: "10px", xs: "9px" },
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "normal",
                                  mt: 0.3,
                                }}
                              >
                                {listings?.userRole}
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                marginLeft: "auto",
                                mr: 1,
                                alignContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  width: { md: "30px", xs: "20px" },
                                  height: { md: "30px", xs: "20px" },
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "4px",
                                  borderRadius: "50%",
                                  mr: 1,
                                  border:
                                    "1px solid var(--Text-Color-Grey-Light, #555656)",
                                  background: "var(--BG-Color-3, #EAEAEA)",
                                }}
                              >
                                <Box
                                  component="img"
                                  src="/media/images/Vector.svg"
                                  sx={{
                                    width: { md: "auto", xs: "12px" },
                                    height: { md: "auto", xs: "12px" },
                                  }}
                                />
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  width: { md: "30px", xs: "20px" },
                                  height: { md: "30px", xs: "20px" },
                                  justifyContent: "center",
                                  alignItems: "center",
                                  mr: 1,
                                  gap: "4px",
                                  borderRadius: "50%",
                                  border:
                                    "1px solid var(--Text-Color-Grey-Light, #555656)",
                                  background: "var(--BG-Color-3, #EAEAEA)",
                                  zIndex: 1,
                                }}
                              >
                                <Box
                                  component="img"
                                  src="/media/images/share.svg"
                                  sx={{
                                    width: { md: "auto", xs: "12px" },
                                    height: { md: "auto", xs: "12px" },
                                  }}
                                />
                              </Box>
                              <Box
                                onClick={() => {
                                  setPropertyId(listings?.propertyId);
                                  setLeadModal(true);
                                  setLeadSource(listings?.propertyType);
                                }}
                                sx={{
                                  width: { md: "150px", xs: "90px" },
                                  height: "30px",
                                  display: "flex",
                                  padding: "8px 0px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "5px",
                                  background: "var(--BG-Color-5, #DB282F)",
                                  fontFamily: "Mulish, sans-serif",
                                  color: "white",
                                  fontSize: { md: "18px", xs: "10px" },
                                  fontWeight: 500,
                                  p: { md: "8px", xs: "4px" },
                                  cursor: "pointer",
                                  mr: 1,
                                }}
                              >
                                <CallIcon
                                  sx={{
                                    color: "white",
                                    fontSize: "19px",
                                    marginRight: "3px",
                                  }}
                                />
                                Contact
                              </Box>
                              <Box
                                sx={{
                                  width: { md: "150px", xs: "90px" },
                                  height: "30px",
                                  display: "flex",
                                  padding: "8px 0px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "5px",
                                  background: "var(--BG-Color-5, #DB282F)",
                                  fontFamily: "Mulish, sans-serif",
                                  color: "white",
                                  fontSize: { md: "18px", xs: "10px" },
                                  fontWeight: 500,
                                  p: { md: "8px", xs: "4px" },
                                  cursor: "pointer",
                                  gap: "10px",
                                }}
                              >
                                <Link
                                  to={"https://wa.me/917259732934"}
                                  // to={`https://wa.me/917259732934?text=New%20%F0%9F%92%B8Lead%20Alert%0APriyanka%20N%20is%20%F0%9F%92%95interested%20in%20your%20Listing%20%F0%9F%8F%A0%20%E2%80%93%203%20BHK%20%20Apartment%20For%20Rent%20in%20Prestige%20Woodland%20Park%0A%0AContact%20%F0%9F%93%9E%20Now%20before%20another%20Realtor%20calls%20client.%20Response%20time%20is%20Limited.%20Client%20Contact%20%F0%9F%93%AA%20Details%20are%20here%20%E2%80%93%20https%3A%2F%2Fxaw98.app.goo.gl%2FWkDrXYvEM552DkLH8`}
                                  target="_blank"
                                  style={{
                                    textDecoration: "none",
                                    color: "white",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <WhatsAppIcon
                                    sx={{
                                      color: "white",
                                      fontSize: "19px",
                                      marginRight: "3px",
                                    }}
                                  />
                                  Whatsapp
                                </Link>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
                {/* <Grid item md={4}>
                  <Box sx={{ position: "sticky", top: "160px" }}>
                    <OverViewDetailsLeadForm />
                  </Box>
                </Grid> */}
              </>
            )}
          </Grid>
        </>
      )}
      <Footer />
      <LeadModal
        initialValue={leadModal}
        handleModal={handeChangeModal}
        leadSource={leadSource}
        propertyId={propertyId}
        productUrl={
          "http://" + window.location.host + "/property-details/" + propertyId
        }
      />
    </>
  );
});

export default PropertyList;

import {
  Autocomplete,
  GoogleMap,
  LoadScript,
  Marker,
} from "@react-google-maps/api";
import {
  Box,
  TextField,
  Button,
  Grid,
  Autocomplete as MuiAutocomplete,
  Stack,
  CircularProgress,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { apiList, invokeApi } from "../../../../../apis/apiServices";
import { config } from "../../../../../config/config";
import { useDispatch, useSelector } from "react-redux";
import { getLocation } from "../../../../../global/redux/action";

const libraries = ["places"];

const LocationDetails = ({
  formData,
  updateFormData,
  next,
  back,
  detailsId,
}) => {
  // error and helper text state
  const [cookies] = useCookies();
  const mapRef = useRef(null);
  const dispatch = useDispatch();
  const { locationData, locationError, isLocationFetching } = useSelector(
    (state) => state.location
  );

  useEffect(() => {
    if (!locationData?.countries && !isLocationFetching && !locationError) {
      dispatch(
        getLocation({ id: cookies[config.cookieName]?.loginUserId, cookies })
      );
    }
  }, [dispatch, cookies, locationError, locationData, isLocationFetching]);

  const zoneMappingData = locationData?.countries;
  // Start
  // below the state for error validation for project name
  const [projectorBuildingNameError, setProjectorBuildingNameError] =
    useState(false);

  const [projectorBuildingNameHelperText, setProjectorBuildingNameHelperText] =
    useState("");
  // End
  // belowt he state for set country name
  const [selectedCountry, setSelectedCountry] = useState(
    formData.selectedCountry
  );
  const [selectedState, setSelectedState] = useState(formData.selectedState);
  const [selectedStateError, setSelectedStateError] = useState(false);
  const [selectedStateHelperText, setSelectedStateHelperText] = useState("");

  // below the state for city and error
  const [selectedDistrict, setSelectedDistrict] = useState(
    formData.selectedDistrict
  );
  const [selectedDistrictError, setSelectedDistrictError] = useState(false);
  const [selectedDistrictHelperText, setSelectedDistrictHelperText] =
    useState("");

  const [selectedTaluk, setSelectedTaluk] = useState(formData.selectedTaluk);
  const [locality, setLocality] = useState(formData.locality);
  const [localityError, setLocalityError] = useState(false);
  const [localityHelperText, setLocalityHelperText] = useState("");
  const [subLocality, setsubLocality] = useState(formData.subLocality);

  const [pinCode, setPinCode] = useState(formData.pinCode);
  const [propertyAdress, setPropertyAdress] = useState(formData.propertyAdress);
  const [landMark, setLandMark] = useState(formData.landMark);
  const [buildingName, setBuildingName] = useState(formData.buildingName);
  const [userPackageId, setuserPackageId] = useState(null);

  const [markerPosition, setMarkerPosition] = useState({
    lat: 12.9038631,
    lng: 77.5855457,
  });
  const [autocomplete, setAutocomplete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [runLocation, setrunLocation] = useState(formData.runLocation);
  const [verifyPackageByLocation, setverifyPackageByLocation] = useState(
    formData.verifyPackageByLocation
  );

  const [longtitude, setLongtitude] = useState("");
  const [latitude, setLatitude] = useState("");

  const send = () => updateFormData(data);

  const data = {
    selectedCountry,
    selectedState,
    selectedDistrict,
    selectedTaluk,
    locality,
    subLocality,
    pinCode,
    propertyAdress,
    landMark,
    buildingName,
    userPackageId,
    runLocation,
    verifyPackageByLocation,
  };

  useEffect(() => {
    const getCurrentLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLongtitude(position.coords.longitude);
            setLatitude(position.coords.latitude);
          },
          // failure call back
          (error) => {
            setLongtitude(config.defaultMapLocation.longitude);
            setLatitude(config.defaultMapLocation.latitude);
            console.log(error);
          }
        );
      } else {
        setLongtitude(config.defaultMapLocation.longitude);
        setLatitude(config.defaultMapLocation.latitude);
      }
    };

    getCurrentLocation();
  }, [cookies]);

  const verifyPackageByCity = async () => {
    setLoading(true);
    let validate = true;
    if (!buildingName) {
      setProjectorBuildingNameError(true);
      setProjectorBuildingNameHelperText(
        "Please Enter Project or Building Name"
      );
      setLoading(false);
      validate = false;
    }
    if (!selectedState) {
      setSelectedStateError(true);
      setSelectedStateHelperText("Please Select the State");
      setLoading(false);
      validate = false;
    }
    if (!selectedDistrict) {
      setSelectedDistrictError(true);
      setSelectedDistrictHelperText("Please Select the City");
      setLoading(false);
      validate = false;
    }
    if (!locality) {
      setLocalityError(true);
      setLocalityHelperText("Please Enter Locality");
      setLoading(false);
      validate = false;
    }
    if (validate) {
      let params = {
        district: selectedDistrict,
        listingType: formData.propertyListingType,
      };
      try {
        const response = await invokeApi(
          config.apiDomains + apiList.verifyUserPackageByCity,
          params,
          cookies
        );
        if (response.status >= 200 && response.status < 300) {
          if (response.data.responseMessage === "Successful") {
            setverifyPackageByLocation(true);
            setrunLocation(false);
            setLoading(false);
            setuserPackageId(response.data.userPackageId);
            next();
            send();
          } else {
            window.alert("No packages are found for this user ID");
            setLoading(false);
          }
        } else {
          window.alert(response.data.responseMessage);
          back();
          setLoading(false);
        }
      } catch (error) {
        console.error("An error occurred while verifying packages:", error);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (!detailsId) {
      setverifyPackageByLocation(false);
      send();
    }
  }, [selectedDistrict]);

  const onMarkerDragEnd = (coords) => {
    setrunLocation(true);
    setMarkerPosition({
      lat: coords.latLng.lat(),
      lng: coords.latLng.lng(),
    });
  };

  const showPosition = (position) => {
    if (position.coords.latitude && position.coords.longitude && runLocation) {
      setMarkerPosition({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    }
  };

  const mapCenterMoved = () => {
    if (runLocation) {
      setMarkerPosition({
        lat: mapRef.current.getCenter().lat(),
        lng: mapRef.current.getCenter().lng(),
      });
    }
  };

  const onAutoCompleteLoad = (ac) => {
    setAutocomplete(ac);
  };

  const onPlaceChanged = () => {
    if (
      runLocation &&
      autocomplete !== null &&
      !!autocomplete.getPlace().geometry?.location
    ) {
      setMarkerPosition({
        lat: autocomplete.getPlace().geometry.location.lat(),
        lng: autocomplete.getPlace().geometry.location.lng(),
      });
    }
  };

  useEffect(() => {
    let url =
      "https://maps.googleapis.com/maps/api/geocode/json?address=" +
      markerPosition.lat +
      "," +
      markerPosition.lng +
      "&key=" +
      config.googleMapsApiKey;

    function updateState(type, state, setter, longName) {
      if (type) {
        setter(longName);
      }
    }

    if (markerPosition.lat && markerPosition.lng && runLocation) {
      fetch(url, {
        method: "get",
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.results.length > 0) {
            const data = res.results[0].address_components;
            for (let i = 0; i < data.length; i++) {
              const object = data[i];
              const types = object.types;

              (!selectedCountry || markerPosition) &&
                updateState(
                  types.includes("country"),
                  selectedCountry,
                  setSelectedCountry,
                  object.long_name
                );
              (!selectedState || markerPosition) &&
                updateState(
                  types.includes("administrative_area_level_1"),
                  selectedState,
                  setSelectedState,
                  object.long_name
                );
              (!selectedDistrict || markerPosition) &&
                updateState(
                  types.includes("administrative_area_level_3"),
                  selectedDistrict,
                  setSelectedDistrict,
                  object.long_name
                );
              !pinCode &&
                updateState(
                  types.includes("postal_code"),
                  pinCode,
                  setPinCode,
                  object.long_name
                );
              (!locality || locality === "" || locality === null) &&
                updateState(
                  types.includes("sublocality"),
                  locality,
                  setLocality,
                  object.long_name
                );
              // !propertyAdress &&
              //   updateState(
              //     types.includes("route"),
              //     propertyAdress,
              //     setPropertyAdress,
              //     object.long_name
              //   );
              !subLocality &&
                updateState(
                  types.includes("sublocality_level_3"),
                  propertyAdress,
                  setsubLocality,
                  object.long_name
                );
              !propertyAdress &&
                updateState(
                  types.includes("sublocality_level_3"),
                  propertyAdress,
                  setPropertyAdress,
                  object.long_name
                );
              !landMark &&
                updateState(
                  types.includes("landmark"),
                  landMark,
                  setLandMark,
                  object.long_name
                );
            }
          }
        });
    }
  }, [markerPosition]);

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <Box component="form" noValidate autoComplete="off">
          <Box sx={{ width: "100%", mb: 5 }}>
            <LoadScript
              googleMapsApiKey={config.googleMapsApiKey}
              libraries={libraries}
            >
              <GoogleMap
                ref={mapRef}
                onLoad={(map) => {
                  mapRef.current = map;
                  if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(showPosition);
                  }
                }}
                mapContainerStyle={{ height: "400px", width: "100%" }}
                center={markerPosition}
                zoom={17}
                onDragEnd={mapCenterMoved}
              >
                <Marker
                  position={markerPosition}
                  draggable
                  onDragEnd={onMarkerDragEnd}
                />
                <Autocomplete
                  onLoad={onAutoCompleteLoad}
                  onPlaceChanged={onPlaceChanged}
                >
                  <input
                    type="text"
                    id="gmap-autocomplete"
                    placeholder="Search for your location"
                    style={{
                      boxSizing: "border-box",
                      border: "1px solid transparent",
                      width: "240px",
                      height: "32px",
                      padding: "0 12px",
                      borderRadius: "3px",
                      boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
                      fontSize: "14px",
                      outline: "none",
                      textOverflow: "ellipses",
                      position: "absolute",
                      left: "50%",
                      marginLeft: "-120px",
                    }}
                    onKeyDown={(ev) => {
                      ev.key === "Enter" && ev.preventDefault();
                    }}
                  />
                </Autocomplete>
              </GoogleMap>
            </LoadScript>
          </Box>

          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <MuiAutocomplete
                id="outlined-select-country"
                options={zoneMappingData.map((item) => item.countryName)}
                value={selectedCountry}
                disabled={detailsId}
                freeSolo
                onChange={(event, newValue) => {
                  setSelectedCountry(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Please select your country"
                    variant="outlined"
                    fullWidth
                    onChange={(event) => {
                      if (!event.target.value) {
                        setSelectedCountry(event.target.value);
                      }
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <MuiAutocomplete
                id="outlined-select-state"
                options={(
                  zoneMappingData.find(
                    (item) => item.countryName === selectedCountry
                  )?.states || []
                ).map((state) => state.stateName)}
                value={selectedState}
                freeSolo
                disabled={!selectedCountry || detailsId}
                onChange={(event, newValue) => {
                  setSelectedState(newValue);
                  setSelectedStateError(false);
                  setSelectedStateHelperText("");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Please select your state"
                    variant="outlined"
                    error={selectedStateError}
                    helperText={selectedStateHelperText}
                    fullWidth
                    onChange={(event) => {
                      if (!event.target.value) {
                        setSelectedState(event.target.value);
                      }
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <MuiAutocomplete
                id="outlined-select-district"
                options={(
                  zoneMappingData
                    .find((item) => item.countryName === selectedCountry)
                    ?.states.find((state) => state.stateName === selectedState)
                    ?.districts || []
                ).map((district) => district.districtName)}
                value={selectedDistrict}
                freeSolo
                disabled={!selectedState || detailsId}
                onChange={(event, newValue) => {
                  setSelectedDistrict(newValue);
                  setSelectedDistrictError(false);
                  setSelectedDistrictHelperText("");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Please select your city / district"
                    variant="outlined"
                    error={selectedDistrictError}
                    helperText={selectedDistrictHelperText}
                    fullWidth
                    onChange={(event) => {
                      if (!event.target.value) {
                        setSelectedDistrict(event.target.value);
                      }
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <TextField
                label="Locality"
                variant="outlined"
                fullWidth
                value={locality}
                error={localityError}
                helperText={localityHelperText}
                onChange={(event) => {
                  setLocality(event.target.value);
                }}
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <TextField
                label="Sub-Locality"
                variant="outlined"
                fullWidth
                value={subLocality}
                onChange={(event) => {
                  setsubLocality(event.target.value);
                  setPropertyAdress(event.target.value);
                }}
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <TextField
                label="Pincode"
                variant="outlined"
                fullWidth
                value={pinCode}
                onChange={(event) => {
                  setPinCode(event.target.value);
                }}
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <TextField
                label="Land Mark"
                variant="outlined"
                fullWidth
                value={landMark}
                onChange={(event) => {
                  setLandMark(event.target.value);
                }}
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <TextField
                label="Project or Building Name"
                name="propertyName"
                variant="outlined"
                fullWidth
                value={buildingName}
                error={projectorBuildingNameError}
                helperText={projectorBuildingNameHelperText}
                onChange={(event) => {
                  setBuildingName(event.target.value);
                }}
              />
            </Grid>
          </Grid>

          {loading && (
            <Stack
              sx={{ alignItems: "center", justifyContent: "center", mt: 5 }}
            >
              <CircularProgress sx={{ color: "black" }} />
            </Stack>
          )}
        </Box>
      </Box>
      {!detailsId && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            pt: 2,
          }}
        >
          <Button
            color="inherit"
            onClick={() => {
              back();
              send();
            }}
          >
            Back
          </Button>
          <Button
            color="inherit"
            onClick={() => {
              verifyPackageByCity();
            }}
          >
            Next
          </Button>
        </Box>
      )}
      {detailsId && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            pt: 2,
          }}
        >
          <Button
            color="inherit"
            onClick={() => {
              back();
            }}
          >
            Back
          </Button>
          <Box>
            <Button
              color="inherit"
              onClick={() => {
                send();
                next();
              }}
            >
              update
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default LocationDetails;

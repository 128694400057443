import React, { useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Stack,
  CircularProgress,
  Box,
  Tabs,
  Tab,
  TextField,
  Typography,
  Grid,
  styled,
} from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { apiList, invokeApi } from "../../../apis/apiServices";
import { config } from "../../../config/config";
import { TabContext, TabList, TabPanel } from "@mui/lab";

function RentalPropertyView() {
  const stepsList = [
    { label: "Property Listing", value: 0, isvisible: true },
    { label: "Location", value: 1, isvisible: true },
    { label: "Area Details", value: 2, isvisible: true },
    { label: "Tenant Status", value: 3, isvisible: true },
    { label: "Property Details", value: 4, isvisible: true },
    { label: "Property Rent", value: 5, isvisible: true },
    { label: "Additional Details", value: 6, isvisible: true },
    { label: "Furnishing Status", value: 7, isvisible: true },
    { label: "Amenities", value: 8, isvisible: true },
    { label: "Land Mark", value: 9, isvisible: true },
    { label: "Define Your Property", value: 10, isvisible: true },
    { label: "Image Gallery", value: 11, isvisible: true },
  ];

  //for align data
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const [cookies] = useCookies();
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setsteps] = useState(stepsList);
  const [datas, setdatas] = useState([]);
  const [PropertyAreaDetails, setPropertyAreaDetails] = useState([]);
  const [TenantStatus, setTenantStatus] = useState([]);
  const [PropertyStatus, setPropertyStatus] = useState([]);
  const [PricingDetails, setPricingDetails] = useState([]);
  const [AdditionalDetails, setAdditionalDetails] = useState([]);
  const [FurnishingStatus, setFurnishingStatus] = useState([]);
  const [Amenities, setAmenities] = useState([]);
  const [Landmark, setLandmark] = useState([]);
  const [DefineProperty, setDefineProperty] = useState([]);
  const [Images, setImages] = useState([]);
  const [PgOwnerDetails, setPgOwnerDetails] = useState([]);
  const [PgRegulations, setPgRegulations] = useState([]);
  const [PgDetails, setPgDetails] = useState([]);
  const [PgRoomDetails, setPgRoomDetails] = useState([]);
  const [loading, setloading] = useState(false);
  const { id: getId } = useParams();
  const [selectedTab, setSelectedTab] = useState(0);

  const buildingType = datas.buildingType;
  const propertyType = datas.propertyType;

  useEffect(() => {
    const getProperty = async () => {
      setloading(true);
      let params = { id: getId };
      try {
        const response = await invokeApi(
          config.apiDomains + apiList.getProperty,
          params,
          cookies
        );
        if (response) {
          setdatas(response.data.Properties);
          {
            response.data.Properties.propertyAreaDetails
              ? setPropertyAreaDetails(
                  response.data.Properties.propertyAreaDetails
                )
              : setPropertyAreaDetails([]);
          }
          {
            response.data.Properties.tenantStatus
              ? setTenantStatus(response.data.Properties.tenantStatus)
              : setTenantStatus([]);
          }
          {
            response.data.Properties.propertyStatus
              ? setPropertyStatus(response.data.Properties.propertyStatus)
              : setPropertyStatus([]);
          }
          {
            response.data.Properties.pricingDetails
              ? setPricingDetails(response.data.Properties.pricingDetails)
              : setPricingDetails([]);
          }
          {
            response.data.Properties.additionalDetails
              ? setAdditionalDetails(response.data.Properties.additionalDetails)
              : setAdditionalDetails([]);
          }
          {
            response.data.Properties.furnishingStatus
              ? setFurnishingStatus(response.data.Properties.furnishingStatus)
              : setFurnishingStatus([]);
          }
          {
            response.data.Properties.amenitiesList
              ? setAmenities(response.data.Properties.amenitiesList)
              : setAmenities([]);
          }
          {
            response.data.Properties.landMark
              ? setLandmark(response.data.Properties.landMark)
              : setLandmark([]);
          }
          {
            response.data.Properties.defineProperty
              ? setDefineProperty(response.data.Properties.defineProperty)
              : setDefineProperty([]);
          }
          {
            response.data.Properties.propertyGalleryImages
              ? setImages(response.data.Properties.propertyGalleryImages)
              : setImages([]);
          }
          {
            response.data.Properties.pgOwnerDetails
              ? setPgOwnerDetails(response.data.Properties.pgOwnerDetails)
              : setPgOwnerDetails([]);
          }
          {
            response.data.Properties.pgRegulations
              ? setPgRegulations(response.data.Properties.pgRegulations)
              : setPgRegulations([]);
          }
          {
            response.data.Properties.pgDetails
              ? setPgDetails(response.data.Properties.pgDetails)
              : setPgDetails([]);
          }
          {
            response.data.Properties.pgRoomDetails
              ? setPgRoomDetails(response.data.Properties.pgRoomDetails)
              : setPgRoomDetails([]);
          }
        } else {
          console.error("No countries data in the response");
        }
      } catch (error) {
        console.error("An error occurred while fetching location:", error);
      }
      setloading(false);
    };
    getProperty();
  }, []);

  useEffect(() => {
    // Create a copy of the original steps array
    const updatedStepsCopy = steps.map((step) => ({ ...step }));

    // Condition 1: Hide "Tenant Status" step
    if (buildingType !== "Commercial" && buildingType !== "Industrial") {
      const indexOfTenantStatus = updatedStepsCopy.findIndex(
        (step) => step.label === "Tenant Status"
      );
      if (indexOfTenantStatus !== -1) {
        updatedStepsCopy[indexOfTenantStatus].isvisible = true;
      }
    }

    // Condition 2: Hide "Furnishing Status" step
    if (
      propertyType !== "Rooftop" &&
      propertyType !== "Commercial Plot/Land" &&
      propertyType !== "Shed/Factory" &&
      propertyType !== "Warehouse/Godown"
    ) {
      const indexOfFurnishingStatus = updatedStepsCopy.findIndex(
        (step) => step.label === "Furnishing Status"
      );
      if (indexOfFurnishingStatus !== -1) {
        updatedStepsCopy[indexOfFurnishingStatus].isvisible = true;
      }
    }

    // Update the state only once after checking both conditions
    setsteps(updatedStepsCopy);
  }, [buildingType, propertyType]);

  const navigate = useNavigate();

  return (
    <div>
      {loading && (
        <Stack sx={{ display: "flex", alignItems: "center", mb: 5 }}>
          <CircularProgress sx={{ color: "black" }} />
        </Stack>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          margin: "auto",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          width: { md: "80%" },
        }}
      >
        {steps.map((item) => {
          if (item.isvisible) {
            return (
              <Box
                key={item.label}
                className="mydict"
                style={{ marginRight: "5px", marginBottom: "5px" }}
              >
                <label
                  className={`mydict1 border1 ${
                    activeStep === item.value ? "active-step" : ""
                  }`}
                  onClick={() => setActiveStep(item.value)}
                  style={{
                    marginRight: "5px",
                    marginBottom: "5px",
                    boxShadow: "none",
                  }}
                >
                  <input
                    type="radio"
                    name="20maage2"
                    value={item.label}
                    readOnly
                  />
                  <span
                    className="border1"
                    style={{
                      marginRight: "15px",
                      marginBottom: "-15px",
                      boxShadow: "none",
                    }}
                  >
                    {item.label}
                  </span>
                </label>
              </Box>
            );
          }
          return null; // You can omit this line, as `return null` by default
        })}
      </Box>

      {activeStep === 0 && (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <b>
                Property Type :{" "}
                <span style={{ fontWeight: "normal" }}>
                  {datas.propertyType}
                </span>
              </b>
            </Grid>
            <Grid item xs={3}>
              <b>
                Listing Type :{" "}
                <span style={{ fontWeight: "normal" }}>
                  {datas.listingType}
                </span>
              </b>
            </Grid>
            <Grid item xs={3}>
              <b>
                Building Type :{" "}
                <span style={{ fontWeight: "normal" }}>
                  {datas.buildingType}
                </span>
              </b>
            </Grid>
          </Grid>
        </Box>
      )}
      {activeStep === 1 && (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            {datas.country !== "" && "country" in datas && (
              <Grid item xs={3}>
                <b>
                  Country :{" "}
                  <span style={{ fontWeight: "normal" }}>{datas.country}</span>
                </b>
              </Grid>
            )}
            {datas.state !== "" && "state" in datas && (
              <Grid item xs={3}>
                <b>
                  State :{" "}
                  <span style={{ fontWeight: "normal" }}>{datas.state}</span>
                </b>
              </Grid>
            )}
            {datas.city !== "" && "city" in datas && (
              <Grid item xs={3}>
                <b>
                  City :{" "}
                  <span style={{ fontWeight: "normal" }}>{datas.city}</span>
                </b>
              </Grid>
            )}
            {datas.zone !== "" && "zone" in datas && (
              <Grid item xs={3}>
                <b>
                  Zone :{" "}
                  <span style={{ fontWeight: "normal" }}>{datas.zone}</span>
                </b>
              </Grid>
            )}
            {datas.locality !== "" && "locality" in datas && (
              <Grid item xs={3}>
                <b>
                  Locality :{" "}
                  <span style={{ fontWeight: "normal" }}>{datas.locality}</span>
                </b>
              </Grid>
            )}
            {datas.pincode !== "" && "pincode" in datas && (
              <Grid item xs={3}>
                <b>
                  Pincode :{" "}
                  <span style={{ fontWeight: "normal" }}>{datas.pincode}</span>
                </b>
              </Grid>
            )}
            {datas.propertyAddress !== "" && "propertyAddress" in datas && (
              <Grid item xs={3}>
                <b>
                  Property Address :{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {datas.propertyAddress}
                  </span>
                </b>
              </Grid>
            )}
            {datas.landmark !== "" && "landmark" in datas && (
              <Grid item xs={3}>
                <b>
                  Land Mark :{" "}
                  <span style={{ fontWeight: "normal" }}>{datas.landmark}</span>
                </b>
              </Grid>
            )}
            {datas.propertyName !== "" && "propertyName" in datas && (
              <Grid item xs={3}>
                <b>
                  Property Name :{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {datas.propertyName}
                  </span>
                </b>
              </Grid>
            )}
          </Grid>
        </Box>
      )}
      {activeStep === 2 && (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            {PropertyAreaDetails.builtupPlotArea !== "" &&
              "builtupPlotArea" in PropertyAreaDetails && (
                <Grid item xs={3}>
                  <b>
                    Builtup Plot Area :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PropertyAreaDetails.builtupPlotArea}
                    </span>
                  </b>
                </Grid>
              )}
            {PropertyAreaDetails.carpetArea !== "" &&
              "carpetArea" in PropertyAreaDetails && (
                <Grid item xs={3}>
                  <b>
                    Carpet Area :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PropertyAreaDetails.carpetArea}
                    </span>
                  </b>
                </Grid>
              )}
            {PropertyAreaDetails.superBuiltupArea !== "" &&
              "superBuiltupArea" in PropertyAreaDetails && (
                <Grid item xs={3}>
                  <b>
                    Super Builtup Area :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PropertyAreaDetails.superBuiltupArea}
                    </span>
                  </b>
                </Grid>
              )}
            {PropertyAreaDetails.areaUnit !== "" &&
              "areaUnit" in PropertyAreaDetails && (
                <Grid item xs={3}>
                  <b>
                    Area Unit :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PropertyAreaDetails.areaUnit}
                    </span>
                  </b>
                </Grid>
              )}
            {PropertyAreaDetails.privatePoolAvailability !== "" &&
              "privatePoolAvailability" in PropertyAreaDetails && (
                <Grid item xs={3}>
                  <b>
                    Private Pool Availability :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PropertyAreaDetails.privatePoolAvailability}
                    </span>
                  </b>
                </Grid>
              )}
            {PropertyAreaDetails.privateGardenAvailability !== "" &&
              "privateGardenAvailability" in PropertyAreaDetails && (
                <Grid item xs={3}>
                  <b>
                    Private Garden Availability :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PropertyAreaDetails.privateGardenAvailability}
                    </span>
                  </b>
                </Grid>
              )}
            {PropertyAreaDetails.privateGardenArea !== "" &&
              "privateGardenArea" in PropertyAreaDetails && (
                <Grid item xs={3}>
                  <b>
                    Private Garden Area :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PropertyAreaDetails.privateGardenArea}
                    </span>
                  </b>
                </Grid>
              )}
            {PropertyAreaDetails.noOfBedrooms !== "" &&
              "noOfBedrooms" in PropertyAreaDetails && (
                <Grid item xs={3}>
                  <b>
                    No Of Bedrooms :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PropertyAreaDetails.noOfBedrooms}
                    </span>
                  </b>
                </Grid>
              )}
            {PropertyAreaDetails.noOfBathrooms !== "" &&
              "noOfBathrooms" in PropertyAreaDetails && (
                <Grid item xs={3}>
                  <b>
                    No Of Bathrooms :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PropertyAreaDetails.noOfBathrooms}
                    </span>
                  </b>
                </Grid>
              )}
            {PropertyAreaDetails.noOfBalconies !== "" &&
              "noOfBalconies" in PropertyAreaDetails && (
                <Grid item xs={3}>
                  <b>
                    No Of Balconies :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PropertyAreaDetails.noOfBalconies}
                    </span>
                  </b>
                </Grid>
              )}
            {PropertyAreaDetails.additionalRooms !== "" &&
              "additionalRooms" in PropertyAreaDetails && (
                <Grid item xs={3}>
                  <b>
                    Additional Rooms :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PropertyAreaDetails.additionalRooms}
                    </span>
                  </b>
                </Grid>
              )}
            {PropertyAreaDetails.floorNo !== "" &&
              "floorNo" in PropertyAreaDetails && (
                <Grid item xs={3}>
                  <b>
                    Floor No :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PropertyAreaDetails.floorNo}
                    </span>
                  </b>
                </Grid>
              )}
            {PropertyAreaDetails.towerBlockNo !== "" &&
              "towerBlockNo" in PropertyAreaDetails && (
                <Grid item xs={3}>
                  <b>
                    Tower/Block No :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PropertyAreaDetails.towerBlockNo}
                    </span>
                  </b>
                </Grid>
              )}
            {PgDetails.totalBeds !== "" && "totalBeds" in PgDetails && (
              <Grid item xs={3}>
                <b>
                  Total Beds :{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {PgDetails.totalBeds}
                  </span>
                </b>
              </Grid>
            )}
            {PgDetails.pgFor !== "" && "pgFor" in PgDetails && (
              <Grid item xs={3}>
                <b>
                  PG For :{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {PgDetails.pgFor}
                  </span>
                </b>
              </Grid>
            )}
            {PgDetails.bestSuitedFor !== "" && "bestSuitedFor" in PgDetails && (
              <Grid item xs={3}>
                <b>
                  Best Suited For :{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {PgDetails.bestSuitedFor}
                  </span>
                </b>
              </Grid>
            )}
            {PgDetails.mealsAvailable !== "" &&
              "mealsAvailable" in PgDetails && (
                <Grid item xs={3}>
                  <b>
                    Meals Available :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PgDetails.mealsAvailable}
                    </span>
                  </b>
                </Grid>
              )}
            {PgDetails.noticePeriod !== "" && "noticePeriod" in PgDetails && (
              <Grid item xs={3}>
                <b>
                  Notice Period :{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {PgDetails.noticePeriod}
                  </span>
                </b>
              </Grid>
            )}
            {PgDetails.lockInPeriod !== "" && "lockInPeriod" in PgDetails && (
              <Grid item xs={3}>
                <b>
                  Lock In Period :{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {PgDetails.lockInPeriod}
                  </span>
                </b>
              </Grid>
            )}
            {PgDetails.commonAreas !== "" && "commonAreas" in PgDetails && (
              <Grid item xs={3}>
                <b>
                  Common Areas :{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {PgDetails.commonAreas}
                  </span>
                </b>
              </Grid>
            )}
          </Grid>
        </Box>
      )}
      {activeStep === 3 && (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            {TenantStatus.tenantType !== "" && "tenantType" in TenantStatus && (
              <Grid item xs={3}>
                <b>
                  Tenant Type :{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {TenantStatus.tenantType}
                  </span>
                </b>
              </Grid>
            )}
            {TenantStatus.religiousType !== "" &&
              "religiousType" in TenantStatus && (
                <Grid item xs={3}>
                  <b>
                    Religious Type :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {TenantStatus.religiousType}
                    </span>
                  </b>
                </Grid>
              )}
            {TenantStatus.workPreference !== "" &&
              "workPreference" in TenantStatus && (
                <Grid item xs={3}>
                  <b>
                    Work Preference :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {TenantStatus.workPreference}
                    </span>
                  </b>
                </Grid>
              )}
            {TenantStatus.petsAllowed !== "" &&
              "petsAllowed" in TenantStatus && (
                <Grid item xs={3}>
                  <b>
                    Pets Allowed :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {TenantStatus.petsAllowed}
                    </span>
                  </b>
                </Grid>
              )}
            {TenantStatus.foodPreference !== "" &&
              "petsAllowed" in TenantStatus && (
                <Grid item xs={3}>
                  <b>
                    Food Preference :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {TenantStatus.foodPreference}
                    </span>
                  </b>
                </Grid>
              )}
            {TenantStatus.foodPreference !== "" &&
              "petsAllowed" in TenantStatus && (
                <Grid item xs={3}>
                  <b>
                    Food Preference :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {TenantStatus.foodPreference}
                    </span>
                  </b>
                </Grid>
              )}
            {TenantStatus.bachelorsAllowed !== "" &&
              "bachelorsAllowed" in TenantStatus && (
                <Grid item xs={3}>
                  <b>
                    Bachelors Allowed :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {TenantStatus.bachelorsAllowed}
                    </span>
                  </b>
                </Grid>
              )}
            {TenantStatus.sprinstersAllowed !== "" &&
              "sprinstersAllowed" in TenantStatus && (
                <Grid item xs={3}>
                  <b>
                    Sprinsters Allowed :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {TenantStatus.sprinstersAllowed}
                    </span>
                  </b>
                </Grid>
              )}
          </Grid>
        </Box>
      )}
      {activeStep === 4 && (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            {PropertyStatus.availableFor !== "" &&
              "availableFor" in PropertyStatus && (
                <Grid item xs={3}>
                  <b>
                    Available For :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PropertyStatus.availableFor}
                    </span>
                  </b>
                </Grid>
              )}
            {PropertyStatus.postionStatus !== "" &&
              "postionStatus" in PropertyStatus && (
                <Grid item xs={3}>
                  <b>
                    Position Status :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PropertyStatus.postionStatus}
                    </span>
                  </b>
                </Grid>
              )}
            {PropertyStatus.postionStatusType !== "" &&
              "postionStatusType" in PropertyStatus && (
                <Grid item xs={3}>
                  <b>
                    Position Status Type :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PropertyStatus.postionStatusType}
                    </span>
                  </b>
                </Grid>
              )}
            {PropertyStatus.availableFrom !== "" &&
              "availableFrom" in PropertyStatus && (
                <Grid item xs={3}>
                  <b>
                    Available From :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PropertyStatus.availableFrom}
                    </span>
                  </b>
                </Grid>
              )}
            {PropertyStatus.occupancyDays !== "" &&
              "occupancyDays" in PropertyStatus && (
                <Grid item xs={3}>
                  <b>
                    Occupancy Days :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PropertyStatus.occupancyDays}
                    </span>
                  </b>
                </Grid>
              )}
            {PropertyStatus.ageOfProperty !== "" &&
              "ageOfProperty" in PropertyStatus && (
                <Grid item xs={3}>
                  <b>
                    Age Of Property :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PropertyStatus.ageOfProperty}
                    </span>
                  </b>
                </Grid>
              )}
            {PropertyStatus.ownershipType !== "" &&
              "ownershipType" in PropertyStatus && (
                <Grid item xs={3}>
                  <b>
                    Ownership Type :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PropertyStatus.ownershipType}
                    </span>
                  </b>
                </Grid>
              )}
            {PropertyStatus.aboutPropertySuitableFor !== "" &&
              "aboutPropertySuitableFor" in PropertyStatus && (
                <Grid item xs={3}>
                  <b>
                    About Property Suitable For :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PropertyStatus.aboutPropertySuitableFor}
                    </span>
                  </b>
                </Grid>
              )}
            {PropertyStatus.locationHub !== "" &&
              "locationHub" in PropertyStatus && (
                <Grid item xs={3}>
                  <b>
                    Location Hub :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PropertyStatus.locationHub}
                    </span>
                  </b>
                </Grid>
              )}
            {PropertyStatus.entranceWidth !== "" &&
              "entranceWidth" in PropertyStatus && (
                <Grid item xs={3}>
                  <b>
                    Entrance Width :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PropertyStatus.entranceWidth}
                    </span>
                  </b>
                </Grid>
              )}
            {PropertyStatus.heightSealing !== "" &&
              "heightSealing" in PropertyStatus && (
                <Grid item xs={3}>
                  <b>
                    Height Sealing :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PropertyStatus.heightSealing}
                    </span>
                  </b>
                </Grid>
              )}
            {PropertyStatus.locatedNear !== "" &&
              "locatedNear" in PropertyStatus && (
                <Grid item xs={3}>
                  <b>
                    Located Near :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PropertyStatus.locatedNear}
                    </span>
                  </b>
                </Grid>
              )}
            {PropertyStatus.govtApproved !== "" &&
              "govtApproved" in PropertyStatus && (
                <Grid item xs={3}>
                  <b>
                    Govt Approved :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PropertyStatus.govtApproved}
                    </span>
                  </b>
                </Grid>
              )}
            {PgOwnerDetails.propertyManagedBy !== "" &&
              "propertyManagedBy" in PgOwnerDetails && (
                <Grid item xs={3}>
                  <b>
                    Property Managed By :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PgOwnerDetails.propertyManagedBy}
                    </span>
                  </b>
                </Grid>
              )}
            {PgOwnerDetails.propertyManagerStay !== "" &&
              "propertyManagerStay" in PgOwnerDetails && (
                <Grid item xs={3}>
                  <b>
                    Property Manager Stays at Property :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PgOwnerDetails.propertyManagerStay}
                    </span>
                  </b>
                </Grid>
              )}
          </Grid>
        </Box>
      )}
      {activeStep === 5 && (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            {PricingDetails.rent !== "" && "rent" in PricingDetails && (
              <Grid item xs={3}>
                <b>
                  Rent :{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {PricingDetails.rent}
                  </span>
                </b>
              </Grid>
            )}
            {PricingDetails.rentType !== "" && "rentType" in PricingDetails && (
              <Grid item xs={3}>
                <b>
                  Rent Type :{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {PricingDetails.rentType}
                  </span>
                </b>
              </Grid>
            )}
            {PricingDetails.maintainanceCost !== "" &&
              "maintainanceCost" in PricingDetails && (
                <Grid item xs={3}>
                  <b>
                    Maintainance Cost :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PricingDetails.maintainanceCost}
                    </span>
                  </b>
                </Grid>
              )}
            {PricingDetails.maintainanceCostType !== "" &&
              "maintainanceCost" in PricingDetails && (
                <Grid item xs={3}>
                  <b>
                    Maintainance Cost Type :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PricingDetails.maintainanceCostType}
                    </span>
                  </b>
                </Grid>
              )}
            {PricingDetails.securityDeposit !== "" &&
              "securityDeposit" in PricingDetails && (
                <Grid item xs={3}>
                  <b>
                    Security Deposit :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PricingDetails.securityDeposit}
                    </span>
                  </b>
                </Grid>
              )}
            {PricingDetails.depositAmount !== "" &&
              "depositAmount" in PricingDetails && (
                <Grid item xs={3}>
                  <b>
                    Deposit Amount :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PricingDetails.depositAmount}
                    </span>
                  </b>
                </Grid>
              )}
            {PricingDetails.bookingAmount !== "" &&
              "bookingAmount" in PricingDetails && (
                <Grid item xs={3}>
                  <b>
                    Booking Amount :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PricingDetails.bookingAmount}
                    </span>
                  </b>
                </Grid>
              )}
            {PricingDetails.lockInPeriod !== "" &&
              "lockInPeriod" in PricingDetails && (
                <Grid item xs={3}>
                  <b>
                    Lock In Period :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PricingDetails.lockInPeriod}
                    </span>
                  </b>
                </Grid>
              )}
            {PricingDetails.lockInPeriodType !== "" &&
              "lockInPeriodType" in PricingDetails && (
                <Grid item xs={3}>
                  <b>
                    Lock In Period Type :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PricingDetails.lockInPeriodType}
                    </span>
                  </b>
                </Grid>
              )}
            {PricingDetails.rentIncrement !== "" &&
              "rentIncrement" in PricingDetails && (
                <Grid item xs={3}>
                  <b>
                    Rent Increment :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PricingDetails.rentIncrement}
                    </span>
                  </b>
                </Grid>
              )}
            {PgRegulations.nonVegAllowed !== "" &&
              "nonVegAllowed" in PgRegulations && (
                <Grid item xs={3}>
                  <b>
                    Non-Veg Allowed :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PgRegulations.nonVegAllowed}
                    </span>
                  </b>
                </Grid>
              )}
            {PgRegulations.oppositeSex !== "" &&
              "oppositeSex" in PgRegulations && (
                <Grid item xs={3}>
                  <b>
                    Opposite Sex :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PgRegulations.oppositeSex}
                    </span>
                  </b>
                </Grid>
              )}
            {PgRegulations.anyTimeAllowed !== "" &&
              "anyTimeAllowed" in PgRegulations && (
                <Grid item xs={3}>
                  <b>
                    Any-Time Allowed :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PgRegulations.anyTimeAllowed}
                    </span>
                  </b>
                </Grid>
              )}
            {PgRegulations.visitorAllowed !== "" &&
              "visitorAllowed" in PgRegulations && (
                <Grid item xs={3}>
                  <b>
                    Visitor Allowed :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PgRegulations.visitorAllowed}
                    </span>
                  </b>
                </Grid>
              )}
            {PgRegulations.guardianAllowed !== "" &&
              "guardianAllowed" in PgRegulations && (
                <Grid item xs={3}>
                  <b>
                    Guardian Allowed :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PgRegulations.guardianAllowed}
                    </span>
                  </b>
                </Grid>
              )}
            {PgRegulations.drinkingAllowed !== "" &&
              "drinkingAllowed" in PgRegulations && (
                <Grid item xs={3}>
                  <b>
                    Drinking Allowed :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PgRegulations.drinkingAllowed}
                    </span>
                  </b>
                </Grid>
              )}
            {PgRegulations.smokingAllowed !== "" &&
              "smokingAllowed" in PgRegulations && (
                <Grid item xs={3}>
                  <b>
                    Smoking Allowed :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PgRegulations.smokingAllowed}
                    </span>
                  </b>
                </Grid>
              )}
          </Grid>
        </Box>
      )}
      {activeStep === 6 && (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            {AdditionalDetails.currentlyLeasedOut !== "" &&
              "currentlyLeasedOut" in AdditionalDetails && (
                <Grid item xs={3}>
                  <b>
                    Currently Leased Out :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {AdditionalDetails.currentlyLeasedOut}
                    </span>
                  </b>
                </Grid>
              )}
            {AdditionalDetails.modifyInterior !== "" &&
              "modifyInterior" in AdditionalDetails && (
                <Grid item xs={3}>
                  <b>
                    Modify Interior :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {AdditionalDetails.modifyInterior}
                    </span>
                  </b>
                </Grid>
              )}
            {AdditionalDetails.brandNewInterior !== "" &&
              "brandNewInterior" in AdditionalDetails && (
                <Grid item xs={3}>
                  <b>
                    Brand New Interior :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {AdditionalDetails.brandNewInterior}
                    </span>
                  </b>
                </Grid>
              )}
            {AdditionalDetails.interestedInCoWorking !== "" &&
              "interestedInCoWorking" in AdditionalDetails && (
                <Grid item xs={3}>
                  <b>
                    Interested In Co-Working :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {AdditionalDetails.interestedInCoWorking}
                    </span>
                  </b>
                </Grid>
              )}
            {AdditionalDetails.buildingGrade !== "" &&
              "buildingGrade" in AdditionalDetails && (
                <Grid item xs={3}>
                  <b>
                    Building Grade :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {AdditionalDetails.buildingGrade}
                    </span>
                  </b>
                </Grid>
              )}
            {AdditionalDetails.cafeteria !== "" &&
              "cafeteria" in AdditionalDetails && (
                <Grid item xs={3}>
                  <b>
                    Cafeteria :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {AdditionalDetails.cafeteria}
                    </span>
                  </b>
                </Grid>
              )}
            {AdditionalDetails.taxGovtCharges !== "" &&
              "taxGovtCharges" in AdditionalDetails && (
                <Grid item xs={3}>
                  <b>
                    Tax & Govt Charges :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {AdditionalDetails.taxGovtCharges}
                    </span>
                  </b>
                </Grid>
              )}
            {AdditionalDetails.electricityCharges !== "" &&
              "electricityCharges" in AdditionalDetails && (
                <Grid item xs={3}>
                  <b>
                    Electricity Charges :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {AdditionalDetails.electricityCharges}
                    </span>
                  </b>
                </Grid>
              )}
            {AdditionalDetails.powerInKv !== "" &&
              "powerInKv" in AdditionalDetails && (
                <Grid item xs={3}>
                  <b>
                    Power In KV :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {AdditionalDetails.powerInKv}
                    </span>
                  </b>
                </Grid>
              )}
            {AdditionalDetails.liftAvailable !== "" &&
              "liftAvailable" in AdditionalDetails && (
                <Grid item xs={3}>
                  <b>
                    Lift Available :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {AdditionalDetails.liftAvailable}
                    </span>
                  </b>
                </Grid>
              )}
            {AdditionalDetails.liftCount !== "" &&
              "liftCount" in AdditionalDetails && (
                <Grid item xs={3}>
                  <b>
                    Lift Count :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {AdditionalDetails.liftCount}
                    </span>
                  </b>
                </Grid>
              )}
            {AdditionalDetails.serviceLiftAvailability !== "" &&
              "serviceLiftAvailability" in AdditionalDetails && (
                <Grid item xs={3}>
                  <b>
                    Service Lift Available :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {AdditionalDetails.serviceLiftAvailability}
                    </span>
                  </b>
                </Grid>
              )}
            {AdditionalDetails.serviceLiftAvailabilityCount !== "" &&
              "serviceLiftAvailabilityCount" in AdditionalDetails && (
                <Grid item xs={3}>
                  <b>
                    Service Lift Count :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {AdditionalDetails.serviceLiftAvailabilityCount}
                    </span>
                  </b>
                </Grid>
              )}
            {AdditionalDetails.flooringType !== "" &&
              "flooringType" in AdditionalDetails && (
                <Grid item xs={3}>
                  <b>
                    Living/Dining :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {AdditionalDetails.flooringType}
                    </span>
                  </b>
                </Grid>
              )}
            {AdditionalDetails.flooringKitchen !== "" &&
              "flooringKitchen" in AdditionalDetails && (
                <Grid item xs={3}>
                  <b>
                    Kitchen :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {AdditionalDetails.flooringKitchen}
                    </span>
                  </b>
                </Grid>
              )}
            {AdditionalDetails.flooringBedroom !== "" &&
              "flooringBedroom" in AdditionalDetails && (
                <Grid item xs={3}>
                  <b>
                    Bedroom :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {AdditionalDetails.flooringBedroom}
                    </span>
                  </b>
                </Grid>
              )}
            {AdditionalDetails.flooringMasterBedroom !== "" &&
              "flooringMasterBedroom" in AdditionalDetails && (
                <Grid item xs={3}>
                  <b>
                    Master Bedroom :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {AdditionalDetails.flooringMasterBedroom}
                    </span>
                  </b>
                </Grid>
              )}
            {AdditionalDetails.flooringBalcony !== "" &&
              "flooringBalcony" in AdditionalDetails && (
                <Grid item xs={3}>
                  <b>
                    Balcony :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {AdditionalDetails.flooringBalcony}
                    </span>
                  </b>
                </Grid>
              )}
            {AdditionalDetails.flooringOther !== "" &&
              "flooringOther" in AdditionalDetails && (
                <Grid item xs={3}>
                  <b>
                    Other :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {AdditionalDetails.flooringOther}
                    </span>
                  </b>
                </Grid>
              )}
            {AdditionalDetails.parking2Wheeler !== "" &&
              "parking2Wheeler" in AdditionalDetails && (
                <Grid item xs={3}>
                  <b>
                    Parking Type 2 Wheeler :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {AdditionalDetails.parking2Wheeler}
                    </span>
                  </b>
                </Grid>
              )}
            {AdditionalDetails.parking2OpenCount !== "" &&
              "parking2OpenCount" in AdditionalDetails && (
                <Grid item xs={3}>
                  <b>
                    Parking 2 Wheeler Open Count :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {AdditionalDetails.parking2OpenCount}
                    </span>
                  </b>
                </Grid>
              )}
            {AdditionalDetails.parking2CoverCount !== "" &&
              "parking2CoverCount" in AdditionalDetails && (
                <Grid item xs={3}>
                  <b>
                    Parking 2 Wheeler Cover Count :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {AdditionalDetails.parking2CoverCount}
                    </span>
                  </b>
                </Grid>
              )}
            {AdditionalDetails.parking4Wheeler !== "" &&
              "parking4Wheeler" in AdditionalDetails && (
                <Grid item xs={3}>
                  <b>
                    Parking Type 4 Wheeler :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {AdditionalDetails.parking4Wheeler}
                    </span>
                  </b>
                </Grid>
              )}
            {AdditionalDetails.parking4OpenCount !== "" &&
              "parking4OpenCount" in AdditionalDetails && (
                <Grid item xs={3}>
                  <b>
                    Parking 4 Wheeler Open Count :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {AdditionalDetails.parking4OpenCount}
                    </span>
                  </b>
                </Grid>
              )}
            {AdditionalDetails.parking4CoverCount !== "" &&
              "parking4CoverCount" in AdditionalDetails && (
                <Grid item xs={3}>
                  <b>
                    Parking 4 Wheeler Cover Count :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {AdditionalDetails.parking4CoverCount}
                    </span>
                  </b>
                </Grid>
              )}
            {AdditionalDetails.powerBackup !== "" &&
              "powerBackup" in AdditionalDetails && (
                <Grid item xs={3}>
                  <b>
                    Power Backup :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {AdditionalDetails.powerBackup}
                    </span>
                  </b>
                </Grid>
              )}
            {AdditionalDetails.waterSource !== "" &&
              "waterSource" in AdditionalDetails && (
                <Grid item xs={3}>
                  <b>
                    Water Source :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {AdditionalDetails.waterSource}
                    </span>
                  </b>
                </Grid>
              )}
            {AdditionalDetails.overLookingView !== "" &&
              "overLookingView" in AdditionalDetails && (
                <Grid item xs={3}>
                  <b>
                    Over Looking View :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {AdditionalDetails.overLookingView}
                    </span>
                  </b>
                </Grid>
              )}
            {AdditionalDetails.frontRoadWidth !== "" &&
              "frontRoadWidth" in AdditionalDetails && (
                <Grid item xs={3}>
                  <b>
                    Front Road Width :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {AdditionalDetails.frontRoadWidth}
                    </span>
                  </b>
                </Grid>
              )}
            {AdditionalDetails.frontRoadWidthType !== "" &&
              "frontRoadWidthType" in AdditionalDetails && (
                <Grid item xs={3}>
                  <b>
                    Front Road Width Type :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {AdditionalDetails.frontRoadWidthType}
                    </span>
                  </b>
                </Grid>
              )}
            {PgRoomDetails.roomType !== "" && "roomType" in PgRoomDetails && (
              <Grid item xs={3}>
                <b>
                  Room Type :{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {PgRoomDetails.roomType}
                  </span>
                </b>
              </Grid>
            )}
            {PgRoomDetails.totalBedsInRoom !== "" &&
              "totalBedsInRoom" in PgRoomDetails && (
                <Grid item xs={3}>
                  <b>
                    Total Beds In Room :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PgRoomDetails.totalBedsInRoom}
                    </span>
                  </b>
                </Grid>
              )}
            {PgRoomDetails.rent !== "" && "rent" in PgRoomDetails && (
              <Grid item xs={3}>
                <b>
                  Rent :{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {PgRoomDetails.rent}
                  </span>
                </b>
              </Grid>
            )}
            {PgRoomDetails.rentType !== "" && "rentType" in PgRoomDetails && (
              <Grid item xs={3}>
                <b>
                  Rent Type :{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {PgRoomDetails.rentType}
                  </span>
                </b>
              </Grid>
            )}
            {PgRoomDetails.securedDeposit !== "" &&
              "securedDeposit" in PgRoomDetails && (
                <Grid item xs={3}>
                  <b>
                    Secured Deposit :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PgRoomDetails.securedDeposit}
                    </span>
                  </b>
                </Grid>
              )}
            {PgRoomDetails.securedDeposit !== "" &&
              "securedDeposit" in PgRoomDetails && (
                <Grid item xs={3}>
                  <b>
                    Secured Deposit :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PgRoomDetails.securedDeposit}
                    </span>
                  </b>
                </Grid>
              )}
            {PgRoomDetails.securedDepositAmount !== "" &&
              "securedDepositAmount" in PgRoomDetails && (
                <Grid item xs={3}>
                  <b>
                    Secured Deposit Amount :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PgRoomDetails.securedDepositAmount}
                    </span>
                  </b>
                </Grid>
              )}
            {PgRoomDetails.parking2Wheeler !== "" &&
              "parking2Wheeler" in PgRoomDetails && (
                <Grid item xs={3}>
                  <b>
                    Parking 2 Wheeler :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PgRoomDetails.parking2Wheeler}
                    </span>
                  </b>
                </Grid>
              )}
            {PgRoomDetails.parking2OpenType !== "" &&
              "parking2OpenType" in PgRoomDetails && (
                <Grid item xs={3}>
                  <b>
                    Parking 2 Wheeler Open Type :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PgRoomDetails.parking2OpenType}
                    </span>
                  </b>
                </Grid>
              )}
            {PgRoomDetails.parking2CoverType !== "" &&
              "parking2CoverType" in PgRoomDetails && (
                <Grid item xs={3}>
                  <b>
                    Parking 2 Wheeler Cover Type :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PgRoomDetails.parking2CoverType}
                    </span>
                  </b>
                </Grid>
              )}
            {PgRoomDetails.parking4Wheeler !== "" &&
              "parking4Wheeler" in PgRoomDetails && (
                <Grid item xs={3}>
                  <b>
                    Parking 4 Wheeler :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PgRoomDetails.parking4Wheeler}
                    </span>
                  </b>
                </Grid>
              )}
            {PgRoomDetails.parking4OpenType !== "" &&
              "parking4OpenType" in PgRoomDetails && (
                <Grid item xs={3}>
                  <b>
                    Parking 4 Wheeler Open Type :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PgRoomDetails.parking4OpenType}
                    </span>
                  </b>
                </Grid>
              )}
            {PgRoomDetails.parking4CoverType !== "" &&
              "parking4CoverType" in PgRoomDetails && (
                <Grid item xs={3}>
                  <b>
                    Parking 4 Wheeler Cover Type :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PgRoomDetails.parking4CoverType}
                    </span>
                  </b>
                </Grid>
              )}
            {PgRoomDetails.facilityOffered !== "" &&
              "facilityOffered" in PgRoomDetails && (
                <Grid item xs={3}>
                  <b>
                    facility Offered :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {PgRoomDetails.facilityOffered}
                    </span>
                  </b>
                </Grid>
              )}
          </Grid>
        </Box>
      )}
      {activeStep === 7 && (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            {FurnishingStatus.furnishingType !== "" &&
              "furnishingType" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Furnishing Type :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.furnishingType}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.wardrobeAvailable !== "" &&
              "wardrobeAvailable" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Wardrobe Available :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.wardrobeAvailable}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.wardrobeCount !== "" &&
              "wardrobeCount" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Wardrobe Count :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.wardrobeCount}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.airConditionAvailable !== "" &&
              "airConditionAvailable" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Air-Condition Available :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.airConditionAvailable}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.airConditionCount !== "" &&
              "airConditionCount" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Air-Condition Count :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.airConditionCount}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.bedsAvailable !== "" &&
              "bedsAvailable" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Beds Available :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.bedsAvailable}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.bedsCount !== "" &&
              "bedsCount" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Beds Count :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.bedsCount}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.ledLightsAvailable !== "" &&
              "ledLightsAvailable" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    LED Lights Available :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.ledLightsAvailable}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.ledLightsCount !== "" &&
              "ledLightsCount" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    LED Lights Count :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.ledLightsCount}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.sofaAvailable !== "" &&
              "sofaAvailable" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Sofa Available :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.sofaAvailable}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.sofaCount !== "" &&
              "sofaCount" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Sofa Count :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.sofaCount}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.refrigaratorAvailable !== "" &&
              "refrigaratorAvailable" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Refrigarator Available :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.refrigaratorAvailable}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.refrigaratorCount !== "" &&
              "refrigaratorCount" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Refrigarator Count :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.refrigaratorCount}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.gasConnectionAvailable !== "" &&
              "gasConnectionAvailable" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Gas Connection Available :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.gasConnectionAvailable}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.gasConnectionCount !== "" &&
              "gasConnectionCount" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Gas Connection Count :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.gasConnectionCount}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.washingMachineAvailable !== "" &&
              "washingMachineAvailable" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Washing Machine Available :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.washingMachineAvailable}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.washingMachineCount !== "" &&
              "washingMachineCount" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Washing Machine Count :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.washingMachineCount}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.tvAvailable !== "" &&
              "tvAvailable" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    TV Available :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.tvAvailable}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.tvCount !== "" &&
              "tvCount" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    TV Count :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.tvCount}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.officeTables !== "" &&
              "officeTables" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Office Tables :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.officeTables}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.officeTablesCount !== "" &&
              "officeTablesCount" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Office Tables Count :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.officeTablesCount}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.waterPurifier !== "" &&
              "waterPurifier" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Water Purifier :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.waterPurifier}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.waterPurifierCount !== "" &&
              "waterPurifierCount" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Water Purifier Count :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.waterPurifierCount}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.fan !== "" && "fan" in FurnishingStatus && (
              <Grid item xs={3}>
                <b>
                  Fan :{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {FurnishingStatus.fan}
                  </span>
                </b>
              </Grid>
            )}
            {FurnishingStatus.fanCount !== "" &&
              "fanCount" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Fan Count :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.fanCount}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.exhaust !== "" &&
              "exhaust" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Exhaust :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.exhaust}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.exhaustCount !== "" &&
              "exhaustCount" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Exhaust Count :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.exhaustCount}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.stove !== "" && "stove" in FurnishingStatus && (
              <Grid item xs={3}>
                <b>
                  Stove :{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {FurnishingStatus.stove}
                  </span>
                </b>
              </Grid>
            )}
            {FurnishingStatus.stoveCount !== "" &&
              "stoveCount" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Stove Count :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.stoveCount}
                    </span>
                  </b>
                </Grid>
              )}
              


              






            {FurnishingStatus.curtains !== "" &&
              "curtains" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Curtains :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.curtains}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.curtainsCount !== "" &&
              "curtainsCount" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Curtains Count :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.curtainsCount}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.chimney !== "" &&
              "chimney" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Chimney :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.chimney}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.chimneyCount !== "" &&
              "chimneyCount" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Chimney Count :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.chimneyCount}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.microwave !== "" &&
              "microwave" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Microwave :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.microwave}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.microwaveCount !== "" &&
              "microwaveCount" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Microwave Count :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.microwaveCount}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.chairs !== "" && "chairs" in FurnishingStatus && (
              <Grid item xs={3}>
                <b>
                  Chairs :{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {FurnishingStatus.chairs}
                  </span>
                </b>
              </Grid>
            )}
            {FurnishingStatus.chairsCount !== "" &&
              "chairsCount" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Chairs Count :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.chairsCount}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.meetingRooms !== "" &&
              "meetingRooms" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Meeting Rooms :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.meetingRooms}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.meetingRoomsCount !== "" &&
              "meetingRoomsCount" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Meeting Rooms Count :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.meetingRoomsCount}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.conferenceRooms !== "" &&
              "conferenceRooms" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Conference Rooms :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.conferenceRooms}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.conferenceRoomsCount !== "" &&
              "conferenceRoomsCount" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Conference Rooms Count :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.conferenceRoomsCount}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.medicalKits !== "" &&
              "medicalKits" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Medical Kits :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.medicalKits}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.medicalKitsCount !== "" &&
              "medicalKitsCount" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Medical Kits Count :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.medicalKitsCount}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.recreationalFacility !== "" &&
              "recreationalFacility" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Recreational Facility :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.recreationalFacility}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.recreationalFacilityCount !== "" &&
              "recreationalFacilityCount" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Recreational Facility Count :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.recreationalFacilityCount}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.printingMachines !== "" &&
              "printingMachines" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Printing Machines :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.printingMachines}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.printingMachinesCount !== "" &&
              "printingMachinesCount" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Printing Machines Count :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.printingMachinesCount}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.coffeeMachines !== "" &&
              "coffeeMachines" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Coffee Machines :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.coffeeMachines}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.coffeeMachinesCount !== "" &&
              "coffeeMachinesCount" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Coffee Machines Count :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.coffeeMachinesCount}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.smartBoard !== "" &&
              "smartBoard" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Smart Board :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.smartBoard}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.smartBoardCount !== "" &&
              "smartBoardCount" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Smart Board Count :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.smartBoardCount}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.projectors !== "" &&
              "projectors" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Projectors :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.projectors}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.projectorsCount !== "" &&
              "projectorsCount" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Projectors Count :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.projectorsCount}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.diningTables !== "" &&
              "diningTables" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Dining Tables :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.diningTables}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.diningTablesCount !== "" &&
              "diningTablesCount" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Dining Tables Count :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.diningTablesCount}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.modularKitchen !== "" &&
              "modularKitchen" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Modular Kitchen :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.modularKitchen}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.modularKitchenCount !== "" &&
              "modularKitchenCount" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Modular Kitchen Count :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.modularKitchenCount}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.workStationType !== "" &&
              "workStationType" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Work-Station Type :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.workStationType}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.seatType !== "" &&
              "seatType" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Seat Type :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.seatType}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.noOfSeatsAvailable !== "" &&
              "noOfSeatsAvailable" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    No Of Seats Available :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.noOfSeatsAvailable}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.managedType !== "" &&
              "managedType" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Managed Type :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.managedType}
                    </span>
                  </b>
                </Grid>
              )}
            {FurnishingStatus.officeSpaceType !== "" &&
              "officeSpaceType" in FurnishingStatus && (
                <Grid item xs={3}>
                  <b>
                    Office Space Type :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {FurnishingStatus.officeSpaceType}
                    </span>
                  </b>
                </Grid>
              )}
          </Grid>
        </Box>
      )}
      {activeStep === 8 && (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <b>Amenities:</b>
              {Amenities.map((item) => (
                <b>
                  <span style={{ fontWeight: "normal" }}>
                    {item.amenities},{" "}
                  </span>
                </b>
              ))}
            </Grid>
          </Grid>
        </Box>
      )}
      {activeStep === 9 && (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            {Landmark.hospitalDistance !== "" &&
              "hospitalDistance" in Landmark && (
                <Grid item xs={3}>
                  <b>
                    Hospital Distance :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {Landmark.hospitalDistance}
                    </span>
                  </b>
                </Grid>
              )}
            {Landmark.hospitalDistanceType !== "" &&
              "hospitalDistanceType" in Landmark && (
                <Grid item xs={3}>
                  <b>
                    Hospital Distance Type :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {Landmark.hospitalDistanceType}
                    </span>
                  </b>
                </Grid>
              )}
            {Landmark.airportDistance !== "" &&
              "airportDistance" in Landmark && (
                <Grid item xs={3}>
                  <b>
                    Airport Distance :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {Landmark.airportDistance}
                    </span>
                  </b>
                </Grid>
              )}
            {Landmark.airportDistanceType !== "" &&
              "airportDistanceType" in Landmark && (
                <Grid item xs={3}>
                  <b>
                    Airport Distance Type :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {Landmark.airportDistanceType}
                    </span>
                  </b>
                </Grid>
              )}
            {Landmark.railwayStationDistance !== "" &&
              "railwayStationDistance" in Landmark && (
                <Grid item xs={3}>
                  <b>
                    Railway Station Distance :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {Landmark.railwayStationDistance}
                    </span>
                  </b>
                </Grid>
              )}
            {Landmark.railwayStationDistanceType !== "" &&
              "railwayStationDistance" in Landmark && (
                <Grid item xs={3}>
                  <b>
                    Railway Station Distance Type :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {Landmark.railwayStationDistanceType}
                    </span>
                  </b>
                </Grid>
              )}
            {Landmark.atmDistance !== "" && "atmDistance" in Landmark && (
              <Grid item xs={3}>
                <b>
                  ATM Distance :{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {Landmark.atmDistance}
                  </span>
                </b>
              </Grid>
            )}
            {Landmark.atmDistanceType !== "" &&
              "atmDistanceType" in Landmark && (
                <Grid item xs={3}>
                  <b>
                    ATM Distance Type :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {Landmark.atmDistanceType}
                    </span>
                  </b>
                </Grid>
              )}
            {Landmark.schoolDistance !== "" && "schoolDistance" in Landmark && (
              <Grid item xs={3}>
                <b>
                  School Distance :{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {Landmark.schoolDistance}
                  </span>
                </b>
              </Grid>
            )}
            {Landmark.schoolDistanceType !== "" &&
              "schoolDistanceType" in Landmark && (
                <Grid item xs={3}>
                  <b>
                    School Distance Type :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {Landmark.schoolDistanceType}
                    </span>
                  </b>
                </Grid>
              )}
            {Landmark.shoppingMallDistance !== "" &&
              "shoppingMallDistance" in Landmark && (
                <Grid item xs={3}>
                  <b>
                    Shopping-Mall Distance :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {Landmark.shoppingMallDistance}
                    </span>
                  </b>
                </Grid>
              )}
            {Landmark.shoppingMallDistanceType !== "" &&
              "shoppingMallDistanceType" in Landmark && (
                <Grid item xs={3}>
                  <b>
                    Shopping-Mall Distance Type :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {Landmark.shoppingMallDistanceType}
                    </span>
                  </b>
                </Grid>
              )}
            {Landmark.bankDistance !== "" && "bankDistance" in Landmark && (
              <Grid item xs={3}>
                <b>
                  Bank Distance :{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {Landmark.bankDistance}
                  </span>
                </b>
              </Grid>
            )}
            {Landmark.bankDistanceType !== "" &&
              "bankDistanceType" in Landmark && (
                <Grid item xs={3}>
                  <b>
                    Bank Distance Type :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {Landmark.bankDistanceType}
                    </span>
                  </b>
                </Grid>
              )}
            {Landmark.busStopDistance !== "" &&
              "busStopDistance" in Landmark && (
                <Grid item xs={3}>
                  <b>
                    Bus-Stop Distance :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {Landmark.busStopDistance}
                    </span>
                  </b>
                </Grid>
              )}
            {Landmark.busStopDistanceType !== "" &&
              "busStopDistance" in Landmark && (
                <Grid item xs={3}>
                  <b>
                    Bus-Stop Distance Type :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {Landmark.busStopDistanceType}
                    </span>
                  </b>
                </Grid>
              )}
            {Landmark.metroStationDistance !== "" &&
              "metroStationDistance" in Landmark && (
                <Grid item xs={3}>
                  <b>
                    Metro-Station Distance :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {Landmark.metroStationDistance}
                    </span>
                  </b>
                </Grid>
              )}
            {Landmark.metroStationDistanceType !== "" &&
              "metroStationDistanceType" in Landmark && (
                <Grid item xs={3}>
                  <b>
                    Metro-Station Distance Type :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {Landmark.metroStationDistanceType}
                    </span>
                  </b>
                </Grid>
              )}
          </Grid>
        </Box>
      )}
      {activeStep === 10 && (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            {DefineProperty.defineLocation !== "" &&
              "defineLocation" in DefineProperty && (
                <Grid item xs={3}>
                  <b>
                    Define Location :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {DefineProperty.defineLocation}
                    </span>
                  </b>
                </Grid>
              )}
            {DefineProperty.explainingPrice !== "" &&
              "explainingPrice" in DefineProperty && (
                <Grid item xs={3}>
                  <b>
                    Explaining Price :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {DefineProperty.explainingPrice}
                    </span>
                  </b>
                </Grid>
              )}
            {DefineProperty.explainingProperty !== "" &&
              "explainingProperty" in DefineProperty && (
                <Grid item xs={3}>
                  <b>
                    Explaining Property :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {DefineProperty.explainingProperty}
                    </span>
                  </b>
                </Grid>
              )}
            {DefineProperty.defineSizeAndStructure !== "" &&
              "defineSizeAndStructure" in DefineProperty && (
                <Grid item xs={3}>
                  <b>
                    Define Size and Structure :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {DefineProperty.defineSizeAndStructure}
                    </span>
                  </b>
                </Grid>
              )}
          </Grid>
        </Box>
      )}
      {activeStep === 11 && (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            {Images.map((items) => (
              <Grid item xs={3} key={items.id}>
                <b>
                  Image Type :{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {items.imageType}
                  </span>
                </b>
                <b>
                  <br></br>
                  Image:<br></br>{" "}
                  <span style={{ fontWeight: "normal" }}>
                    <img
                      style={{ width: 200 }}
                      src={items.imagePath}
                      alt={items.imageType}
                    />
                  </span>
                </b>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </div>
  );
}

export default RentalPropertyView;
